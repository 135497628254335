import ShareIcon from '@mui/icons-material/Share';
import React, { useContext } from 'react';
import UiContext from '../../state/UiContext';
import EditIcon from '@mui/icons-material/Edit';
import { reportShare, useMe } from '../../services/users';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useTheorySubscription } from '../../services/subscription';
import SignInModal from '../SignIn/SignInModal';

const TheoryButton = ({ id, Icon, onClick, children }) => (
  <div
    id={id}
    className='text-sm md:text-base border rounded bg-black bg-opacity-60 border-white py-1 px-2 md:px-3 md:py-2 flex flex-row items-center cursor-pointer'
    onClick={onClick}
  >
    <Icon color='white' />
    <p className='text-white font-bold ml-2'>{children}</p>
  </div>
);

const TheoryButtonPanel = ({ theory, onClickEdit }) => {
  const me = useMe().data?.data;
  const { toastSuccess } = useContext(UiContext);
  const { subscribed, toggleSubscribe, promptForCredentials, setPromptForCredentials } =
    useTheorySubscription({ theory });

  function onClickShare() {
    reportShare({ theory: theory.id }).then(() => {});
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      const path = '/inquiry/' + theory.slug + (me?.username ? '?sharedBy=@' + me.username : '');
      navigator.clipboard.writeText(window.location.origin + path);
      toastSuccess('URL copied to clipboard');
    }
  }

  return (
    <div className='absolute bottom-0 right-0 m-2 md:m-3 flex flex-row space-x-2'>
      {onClickEdit && (
        <TheoryButton Icon={EditIcon} onClick={onClickEdit}>
          Edit
        </TheoryButton>
      )}
      <TheoryButton Icon={ShareIcon} onClick={onClickShare}>
        Share
      </TheoryButton>
      <TheoryButton id='subscribe-button' Icon={NotificationsNoneIcon} onClick={toggleSubscribe}>
        {subscribed ? 'Unsubscribe' : 'Subscribe'}
      </TheoryButton>
      <SignInModal
        open={promptForCredentials}
        title={'Enter your email to get updates on this Inquiry'}
        onClose={() => setPromptForCredentials(false)}
        context='theory page'
      />
    </div>
  );
};

export default TheoryButtonPanel;
