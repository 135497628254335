import {
  BookIcon,
  BookshelfIcon,
  CameraIcon,
  CandleIcon,
  CdIcon,
  FilmIcon,
  FireIcon,
  GlobeIcon,
  KeyboardIcon,
  MagGlassIcon,
  MapIcon,
  MicIcon,
  PaperIcon,
  PcIcon,
  PencilIcon,
  QuillIcon,
  ScrollIcon,
  SmartphoneIcon,
  TabletIcon,
  TotemIcon,
  VhsIcon,
} from '../icons';
import SvgContainer from '../wrappers/SvgContainer';

const Item = ({ user }) => {
  if (!user) return null;

  const items = {
    0: FireIcon,
    1: TotemIcon,
    2: CandleIcon,
    3: TabletIcon,
    4: QuillIcon,
    5: ScrollIcon,
    6: PaperIcon,
    7: PencilIcon,
    8: BookIcon,
    9: BookshelfIcon,
    10: MagGlassIcon,
    11: MapIcon,
    12: GlobeIcon,
    13: CameraIcon,
    14: MicIcon,
    15: FilmIcon,
    16: VhsIcon,
    17: CdIcon,
    18: KeyboardIcon,
    19: PcIcon,
    20: SmartphoneIcon,
  };

  const item = items[user.trustLevel] || items[0];

  return (
    <div className='w-3 h-3 sm:w-4 sm:h-4 text-yellow-500'>
      <SvgContainer src={item} viewBox='0 0 512 512' />
    </div>
  );
};

export default Item;
