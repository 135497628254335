import EvidenceNode from './EvidenceNode';
import { useTheoryEvidence } from '../../services/evidence';
import { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import classnames from 'classnames';
import { flattenPages } from '../../util/query';

const Evidences = ({
  isFor,
  theory,
  parentEvidence,
  onExpand,
  level,
  collapseEvents,
  hideShowMore,
  perPage,
}) => {
  const query = {
    theoryId: theory?.id,
    isFor,
    parentId: parentEvidence?.id || null,
    perPage,
  };
  const evidenceQuery = useTheoryEvidence(query);
  const evidences = useMemo(() => flattenPages(evidenceQuery.data?.pages), [evidenceQuery]);

  if (!evidenceQuery.isLoading && !evidences.length)
    if (!parentEvidence)
      return (
        <p className='text-unselected text-sm md:text-base border rounded p-3 md:p-5'>
          Nobody has added any {isFor ? '"Yes"' : '"No"'} Evidence yet
        </p>
      );
    else return null;

  return (
    <div
      className={classnames('relative flex flex-col space-y-4', {
        'md:space-y-6': level === 1,
      })}
    >
      {evidences?.map(e => (
        <div key={e.id}>
          <EvidenceNode
            theory={theory}
            evidence={e}
            parentEvidence={parentEvidence}
            onExpand={onExpand}
            level={level}
            collapseEvents={collapseEvents}
          />
        </div>
      ))}

      {((!parentEvidence && evidenceQuery.isLoading) ||
        (evidenceQuery.isFetching && !evidenceQuery.isLoading)) &&
        [1, 2, 3, 4, 5].map(i => <Skeleton key={i} height={160} />)}

      {evidenceQuery.hasNextPage && !hideShowMore && !evidenceQuery.isFetching && (
        <>
          <div className='h-8' />
          <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-white flex justify-center px-6 pt-20 pointer-events-none'>
            <button
              className='flex rounded-lg py-5 px-9 bg-white border border-teal-600 text-teal-600 text-sm font-bold pointer-events-auto'
              onClick={() => !evidenceQuery.isFetching && evidenceQuery.fetchNextPage()}
            >
              <span>Show More</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Evidences;
