import React from 'react';

import Recipient from 'components/Conversations/Recipient';

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
  root: {
    '& .list': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },

    '& .item': {
      '&:nth-child(odd)': {
        backgroundColor: '#FBFBFB',
      },
      margin: 0,
      padding: 10,
      cursor: 'pointer',
      borderTop: '1px solid rgba(0, 0, 0, 0.05)',
      '&:hover': {
        backgroundColor: '#F2F9FF',
      },
    },
  },
});
const List = props => {
  const classes = useStyles();
  const {
    conversationPages,
    onConversationSelect,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = props;
  return (
    <div className={classes.root}>
      <ul className='list'>
        {conversationPages?.map(page =>
          page?.data?.map(conversation => (
            <li
              className='item'
              key={conversation.id}
              onClick={() => onConversationSelect(conversation)}
            >
              <Recipient
                user={conversation.recipient}
                date={conversation.lastUpdated}
                dms={conversation.dms}
              />
            </li>
          )),
        )}
        {hasNextPage && (
          <Box textAlign='center'>
            <Button onClick={() => fetchNextPage()} variant='contained' margin='auto'>
              {isFetchingNextPage ? 'Loading more...' : 'Load More'}
            </Button>
          </Box>
        )}
      </ul>
    </div>
  );
};

export default List;
