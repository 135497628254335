const minute = 60000;
const hour = minute * 60;

export const staleTimes = {
  blip: 0.5 * minute,
  short: 5 * minute,
  medium: hour,
  long: 12 * hour,
};

export class AuthenticationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export const querySettings = {
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof AuthenticationError) return false;
        else return failureCount < 3;
      },
    },
  },
};
