import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useMe } from '../services/users';
import { useEnroll } from '../services/ab-tests';
import { useQueryClient } from 'react-query';

const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [signingIn, setSigningIn] = useState(false);
  const [tokenSentTo, setTokenSentTo] = useState(false);
  const [naming, setNaming] = useState(false);
  const meQuery = useMe();
  const me = meQuery.data?.data;
  const prevMe = useRef(me);
  const [isGuest, setIsGuest] = useState(true); // no valid jwt (a temporary condition unless signed out)
  const [isAuthenticated, setIsAuthenticated] = useState(false); // do we have a valid jwt?
  const [isSignedIn, setIsSignedIn] = useState(false); // do we have a valid jwt and verified email?
  const [isNamed, setIsNamed] = useState(false); // do we have a valid jwt, email, and a username?
  const [isAdmin, setIsAdmin] = useState(false); // are we an admin?
  const [abTestEnrollments, setAbTestEnrollments] = useState([]);
  const enroll = useEnroll();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (me?.id && prevMe.current?.id && me.id !== prevMe.current.id) {
      queryClient.invalidateQueries({ refetchType: 'none' }); // none prevents losing body editors
    }
    prevMe.current = me;
  }, [me, prevMe, queryClient]);

  const abEnroll = useCallback(
    slug =>
      setAbTestEnrollments(enrollments =>
        !enrollments.some(s => s === slug) ? [...enrollments, slug] : enrollments,
      ),
    [setAbTestEnrollments],
  );

  useEffect(() => {
    if (me)
      abTestEnrollments.forEach(
        slug =>
          !me.abTests?.includes(slug) && !me.abControls?.includes(slug) && enroll.mutate(slug),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, abTestEnrollments]);

  useEffect(() => {
    if (meQuery.isFetching) return;

    setIsGuest(!me?.id);
    setIsNamed(!!me?.username);
    setIsAuthenticated(!!me?.id);
    setIsSignedIn(!!me?.completedSignup);
    setIsAdmin(me?.roles?.includes('admin'));
    setAbTestEnrollments([...(me?.abTests || []), ...(me?.abControls || [])]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    // prettier-ignore
    <AuthContext.Provider
      value={{
        signingIn, setSigningIn,
        tokenSentTo, setTokenSentTo,
        naming, setNaming,
        me, isGuest, isAuthenticated, isSignedIn, isNamed, isAdmin,
        abEnroll,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
