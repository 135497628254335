import { useMutation } from 'react-query';
import { fetchClient } from './api';

const apiClient = fetchClient();

const createImage = async ({ image, purpose }) => {
  const formData = new FormData();
  formData.append('purpose', purpose);
  formData.append('image', image);

  return await apiClient
    .post('/image', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => res.data);
};

export const useCreateImage = function () {
  return useMutation(imageData => createImage(imageData));
};
