const Reddit = {
  init: function (userId) {
    if (!process.env.REACT_APP_REDDIT_PIXEL_ID) return;

    // eslint-disable-next-line no-unused-expressions
    !(function (w, d) {
      if (!w.rdt) {
        var p = (w.rdt = function () {
          p.sendEvent ? p.sendEvent.apply(p, arguments) : p.callQueue.push(arguments);
        });
        p.callQueue = [];
        var t = d.createElement('script');
        // eslint-disable-next-line no-unused-expressions, no-sequences
        (t.src = 'https://www.redditstatic.com/ads/pixel.js'), (t.async = !0);
        var s = d.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(t, s);
      }
    })(window, document);

    window.rdt('init', process.env.REACT_APP_REDDIT_PIXEL_ID, {
      optOut: false,
      useDecimalCurrencyValues: true,
      externalId: userId,
    });
  },

  track: function (action) {
    if (process.env.REACT_APP_REDDIT_PIXEL_ID && window.rdt) window.rdt('track', action);
  },
};

export default Reddit;
