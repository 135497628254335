import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';
import { useEvidence } from '../../services/evidence';
import { useGetTheory } from '../../services/theories';

const SearchBar = ({ onClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [searchString, setSearchString] = useState(params.get('searchString') || '');

  const isSearchPage = useMemo(() => location.pathname.split('/')[1] === 'search', [location]);

  const parts = location.pathname.split('/');
  const evidenceSlugOrId = ['evidence', 'rebuttal'].includes(parts[1]) && parts[2];
  const evidenceTheoryId = useEvidence(evidenceSlugOrId).data?.data?.theory?.id;

  const theorySlugOrId = parts[1] === 'inquiry' && parts[2];
  const theory = useGetTheory(theorySlugOrId).data?.data;

  const context = useMemo(() => {
    if (parts.length < 2) return;
    const c = {};

    if (evidenceSlugOrId) {
      c.contentType = 'evidence';
      c.what = `this Inquiry for Evidence`;
      c.theoryId = evidenceTheoryId;
    } else if (
      parts[1] === 'evidence' ||
      (parts[1] === 'search' && params.get('contentType') === 'evidence' && !params.get('theoryId'))
    ) {
      c.contentType = 'evidence';
      c.what = 'for Evidence';
    } else if (
      parts[1] === 'inquiries' ||
      (parts[1] === 'search' && params.get('contentType') === 'theory')
    ) {
      c.contentType = 'theory';
      c.what = 'for Inquiry';
    } else if (
      parts[1] === 'inquiry' ||
      (parts[1] === 'search' && params.get('contentType') === 'evidence' && params.get('theoryId'))
    ) {
      c.contentType = 'evidence';
      c.theoryId = params.get('theoryId') || evidenceTheoryId || theory?.id;
      c.what = 'this inquiry';
    }
    return c;
  }, [params, parts, evidenceSlugOrId, evidenceTheoryId, theory?.id]);

  const what = useMemo(() => context?.what || 'all content', [context]);

  const search = useCallback(() => {
    const params = new URLSearchParams({
      ...(searchString !== undefined ? { searchString } : undefined),
      ..._.pickBy(_.pick(context, ['contentType', 'theoryId']), v => v),
    }).toString();

    navigate('/search?' + params, { replace: isSearchPage });
  }, [context, searchString, isSearchPage, navigate]);

  const close = () => {
    if (isSearchPage) navigate(-1);
    onClose && onClose();
  };

  return (
    <div className='relative'>
      <input
        autoFocus
        value={searchString}
        onChange={e => setSearchString(e.target.value)}
        className='text-sm md:text-lg p-2 w-full bg-slate-200 rounded'
        placeholder={'Search ' + what}
        enterKeyHint='search'
        onKeyDown={e => (e.key === 'Enter' && search()) || (e.key === 'Escape' && close())}
      />
      <button className='absolute right-1 inset-y-0' onClick={close}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default SearchBar;
