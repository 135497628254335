import {
  calculateSignificance,
  useAbTestStats,
  useAbTests,
  useCreateAbTest,
  useStartAbTest,
  useStopAbTest,
  useResetAbTest,
  useTryAbTest,
} from '../../services/ab-tests';
import { useCallback, useMemo, useState } from 'react';
import ta from 'time-ago';
import classnames from 'classnames';
import { useMe } from '../../services/users';

const AbTestPanel = ({ abTest, selectiveEnrollment }) => {
  const [showResults, setShowResults] = useState(false);
  const [denominator, setDenominator] = useState('users');
  const abTestStats = useAbTestStats(
    showResults ? abTest.slug : null,
    denominator,
    selectiveEnrollment,
  ).data?.data;
  const startAbTest = useStartAbTest();
  const stopAbTest = useStopAbTest();
  const resetAbTest = useResetAbTest();
  const tryAbTest = useTryAbTest();
  const me = useMe().data?.data;

  const start = () => window.confirm('start test?') && startAbTest.mutate(abTest.slug);
  const stop = () => window.confirm('stop test?') && stopAbTest.mutate(abTest.slug);
  const reset = () =>
    window.confirm('erase data and reset test?') && resetAbTest.mutate(abTest.slug);

  const onClickDenominator = useCallback(
    denominator => {
      setDenominator(denominator);
    },
    [setDenominator],
  );

  const processed = useMemo(
    () =>
      abTestStats?.results?.map(result => {
        const controlRatio = abTestStats.population.control
          ? result.control.score / abTestStats.population.control
          : 0;
        const testRatio = abTestStats.population.test
          ? result.test.score / abTestStats.population.test
          : 0;
        return {
          ...result,
          controlRatio,
          testRatio,
          significance:
            calculateSignificance(result, abTestStats.population) *
            Math.sign(testRatio - controlRatio),
        };
      }),
    [abTestStats],
  );

  const getColor = p =>
    p <= -0.98
      ? 'text-blue-400'
      : p <= -0.9
      ? 'text-violet-400'
      : p < 0.9
      ? 'text-fuchsia-400'
      : p < 0.98
      ? 'text-rose-300'
      : 'text-orange-400';

  return (
    <div>
      <div className={classnames('mt-8 grid', 'grid-cols-4')}>
        <p className='cursor-pointer font-bold' onClick={() => setShowResults(!showResults)}>
          {abTest.slug}
        </p>
        <p>{abTest.started ? ta.ago(abTest.started) : <button onClick={start}>start</button>}</p>
        <p>{abTest.stopped ? ta.ago(abTest.stopped) : <button onClick={stop}>stop</button>}</p>

        {(showResults && (
          <p>
            <button onClick={reset}>reset</button>
          </p>
        )) || (
          <p>
            <button
              className={me?.abTests?.includes(abTest.slug) ? 'text-green-700' : 'text-unselected'}
              onClick={() => tryAbTest(abTest.slug)}
            >
              {me?.abTests?.includes(abTest.slug) ? 'trying' : 'try'}
            </button>
          </p>
        )}
      </div>
      {showResults && processed && (
        <div className={'m-2'}>
          <p>{abTest.description}</p>
          <div className='flex flex-row justify-around w-full'>
            {['users', 'delivered', 'opened'].map(d => (
              <button
                key={d}
                className={classnames('border p-1', {
                  'text-regular font-bold': denominator === d,
                })}
                onClick={() => onClickDenominator(d)}
              >
                /{d}
              </button>
            ))}
          </div>
          <div className='grid grid-cols-4 font-bold'>
            <p>name</p>
            <p>control</p>
            <p>test</p>
            <p>p</p>
          </div>
          {processed.map(p => (
            <div key={p.stat} className={classnames('grid grid-cols-4', getColor(p.significance))}>
              <p>{p.stat}</p>
              <p>
                {p.control.score}/{abTestStats.population.control}=
                {Math.round(p.controlRatio * 100)}%
              </p>
              <p>
                {p.test.score}/{abTestStats.population.test}={Math.round(p.testRatio * 100)}%
              </p>
              <p className='font-bold'>{p.significance}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const AdminAbTests = () => {
  const [includeStopped, setIncludeStopped] = useState(false);
  const [selectiveEnrollment, setSelectiveEnrollment] = useState(false);
  const abTests = useAbTests(includeStopped).data?.data;
  const createAbTest = useCreateAbTest();
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');

  const add = () => createAbTest.mutate({ slug, description }, { onSuccess: () => setSlug('') });

  return (
    <div className='mx-10 text-[10px] sm:text-sm'>
      <div className='flex flex-row space-x-2 place-items-center'>
        <p>Add test:</p>
        <input
          className='p-2'
          placeholder='slug'
          value={slug}
          onChange={e => setSlug(e.target.value)}
        />
        <input
          className='p-2'
          placeholder='description'
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <button onClick={add}>Add</button>
      </div>

      <div className='mt-3 flex justify-between'>
        <p>Existing Tests:</p>
        <div>
          <button
            className={includeStopped ? 'text-green-500' : 'text-unselected'}
            onClick={() => setIncludeStopped(!includeStopped)}
          >
            Include stopped
          </button>
          <button
            className={'ml-2 ' + (selectiveEnrollment ? 'text-green-500' : 'text-unselected')}
            onClick={() => setSelectiveEnrollment(!selectiveEnrollment)}
          >
            Selectively enrolled
          </button>
        </div>
      </div>
      {abTests?.map(t => (
        <AbTestPanel abTest={t} key={t.slug} selectiveEnrollment={selectiveEnrollment} />
      ))}
    </div>
  );
};

export default AdminAbTests;
