import Username from './Username';
import CreatedAt from './CreatedAt';
import classnames from 'classnames';

const UsernameCreatedAt = ({ username, createdAt, compact }) => (
  <div
    className={classnames(
      'flex space-x-2 items-baseline',
      compact ? 'flex-start' : username ? 'justify-between' : 'justify-end',
    )}
  >
    {username && (
      <div className='mr-1 flex space-x-1/2'>
        <Username username={username} />
      </div>
    )}
    <CreatedAt createdAt={createdAt} />
  </div>
);
export default UsernameCreatedAt;
