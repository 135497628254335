let ConvId;

const Twitter = {
  init: function (convId) {
    if (!convId) return;
    ConvId = convId;

    // eslint-disable-next-line no-unused-expressions
    !(function (e, t, n, s, u, a) {
      // eslint-disable-next-line no-unused-expressions
      e.twq ||
        ((s = e.twq =
          function () {
            s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
          }),
        (s.version = '1.1'),
        (s.queue = []),
        (u = t.createElement(n)),
        (u.async = !0),
        (u.src = 'https://static.ads-twitter.com/uwt.js'),
        (a = t.getElementsByTagName(n)[0]),
        a.parentNode.insertBefore(u, a));
    })(window, document, 'script');

    window.twq('config', ConvId);
  },

  track: function (action) {
    if (ConvId) window.twq('event', action);
  },
};

export default Twitter;
