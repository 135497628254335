import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import SectionTitle from './SectionTitle';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CloudUpload from '@mui/icons-material/CloudUpload';
import { useContext } from 'react';
import UiContext from '../../state/UiContext';

const ButtonSection = () => {
  const { toastError, toastInfo, toastSuccess } = useContext(UiContext);
  const theme = useTheme();

  const DivideLine = styled(Box)(({ theme }) => ({
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.line.main,
    marginBottom: 10,
    marginTop: 10,
  }));
  const ButtonBox = styled(Box)(() => ({
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    marginRight: 5,
  }));
  const ButtonSet = ({ title, buttonType, disabledFlag }) => {
    return (
      <Box>
        <DivideLine />
        <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={4}>
          <Box gridColumn='span 2'>
            <TypographyItem text={title} typoStyle={theme.typography.b2Button} />
          </Box>
          <Box gridColumn='span 10' display='flex'>
            <ButtonBox gridColumn='span 3'>
              <Button
                variant={buttonType}
                size='large'
                disabled={disabledFlag}
                onClick={() => toastError('Error message')}
              >
                Update
              </Button>
            </ButtonBox>
            <ButtonBox gridColumn='span 3'>
              <Button
                variant={buttonType}
                size='medium'
                disabled={disabledFlag}
                onClick={() => toastInfo('Info will be medium in length')}
              >
                Update
              </Button>
            </ButtonBox>
            <ButtonBox gridColumn='span 3'>
              <Button
                variant={buttonType}
                size='small'
                disabled={disabledFlag}
                onClick={() => toastSuccess('Success can be a longer message... yeah long as')}
              >
                Update
              </Button>
            </ButtonBox>
          </Box>
        </Box>
      </Box>
    );
  };
  const TypographyItem = ({ text, typoStyle }) => {
    return <p style={{ ...typoStyle, marginTop: 25, marginBottom: 25 }}>{text}</p>;
  };
  return (
    <Box style={{ marginTop: 20 }}>
      <SectionTitle
        title='Buttons'
        desc='Buttons are an essential element of interaction design. It plays a primary role in the conversation between a user and the system.'
      />
      <TypographyItem text='Text Only' typoStyle={theme.typography.h1} />
      <ButtonSet title='regular' buttonType='outlined' disabledFlag={false} />
      <ButtonSet title='Important Action' buttonType='contained' disabledFlag={false} />
      <ButtonSet title='Disabled' buttonType='contained' disabledFlag={true} />
      <ButtonSet title='Tertiary' buttonType='containedTertiary' disabledFlag={false} />
      <DivideLine />
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={4}>
        <Box gridColumn='span 2'>
          <TypographyItem text='Text Only' typoStyle={theme.typography.b2Button} />
        </Box>
        <Box gridColumn='span 10' display='flex'>
          <ButtonBox gridColumn='span 3'>
            <Button variant='text'>Indicate Interest</Button>
          </ButtonBox>
        </Box>
      </Box>
      <DivideLine />
      <TypographyItem text='Toggle' typoStyle={theme.typography.h1} />
      <DivideLine />
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={4}>
        <Box gridColumn='span 2'>
          <TypographyItem text='Toggle' typoStyle={theme.typography.b2Button} />
        </Box>
        <Box gridColumn='span 10' display='flex'>
          <ToggleButtonGroup
            value={'left'}
            exclusive
            // onChange={handleAlignment}
            aria-label='text alignment'
          >
            <ToggleButton value='left' aria-label='left aligned'>
              <p>Primary</p>
            </ToggleButton>
            <ToggleButton value='right' aria-label='right aligned'>
              <p>Secondary</p>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <DivideLine />
      <TypographyItem text='Existing Button Styles with new font' typoStyle={theme.typography.h1} />
      <DivideLine />
      <Box display='flex'>
        <ButtonBox gridColumn='span 3'>
          <Button variant='contained' size='large'>
            Sign
          </Button>
        </ButtonBox>
        <ButtonBox gridColumn='span 3'>
          <Button variant='contained' size='medium'>
            Save
          </Button>
        </ButtonBox>
        <ButtonBox gridColumn='span 3'>
          <Button variant='contained' size='small'>
            Save
          </Button>
        </ButtonBox>
      </Box>
      <Box display='flex' style={{ marginTop: 40 }}>
        <ButtonBox gridColumn='span 3'>
          <Button variant='contained' size='large' startIcon={<CloudUpload />}>
            Upload
          </Button>
        </ButtonBox>
        <ButtonBox gridColumn='span 3'>
          <Button variant='contained' size='medium' startIcon={<CloudUpload />}>
            Upload
          </Button>
        </ButtonBox>
        <ButtonBox gridColumn='span 3'>
          <Button variant='contained' size='small'>
            Upload
          </Button>
        </ButtonBox>
      </Box>
    </Box>
  );
};

export default ButtonSection;
