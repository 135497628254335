import { useAllEvidences } from '../services/evidence';
import EvidenceCard from '../components/Cards/EvidenceCard';
import { Skeleton } from '@mui/material';
import classnames from 'classnames';
import { track } from '../services/analytics';
import _ from 'lodash';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { flattenPages } from '../util/query';
import { useInfiniteScroll } from '../util/dom';

const TabButton = ({ id, label, active, onClick }) => {
  return (
    <button
      className={classnames(
        'rounded-full px-3 py-2 text-sm md:text-base font-semibold cursor-pointer',
        id === active ? 'bg-primary text-white' : 'bg-gray-100 text-gray-400 ',
      )}
      onClick={() => {
        track('user-evidence-tab', { name: _.capitalize(id) });
        onClick(id);
      }}
    >
      {label}
    </button>
  );
};

const UserEvidence = ({ user, sways }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(searchParams.get('tab') || 'evidence');
  const userEvidenceQuery = useAllEvidences({
    user: user.id,
    swayed: sways || undefined,
    parent:
      !sways && tab === 'rebuttals'
        ? 'not-null'
        : !sways && tab === 'evidence'
        ? 'null'
        : undefined,
    status: !sways && tab === 'drafts' ? 'not-public' : 'public',
    perPage: 20,
  });

  useInfiniteScroll(userEvidenceQuery);

  const evidence = !!userEvidenceQuery.data
    ? flattenPages(userEvidenceQuery.data.pages).filter(e => !sways || e.swayCount)
    : null;

  const _setTab = id => {
    setTab(id);
    searchParams.set('tab', id);

    setSearchParams(searchParams);
  };

  return (
    <div className='flex flex-col space-y-4 w-full mx-4'>
      {!sways && (
        <div className='flex flex-row space-x-1 justify-start mt-6'>
          <TabButton id='evidence' label='Evidence' onClick={_setTab} active={tab} />
          <TabButton id='rebuttals' label='Rebuttals' onClick={_setTab} active={tab} />
          <TabButton id='drafts' label='Drafts' onClick={_setTab} active={tab} />
        </div>
      )}
      {evidence?.map((e, i) => (
        <EvidenceCard
          key={i}
          evidence={e}
          theory={e.theory}
          date={e.publishedAt || e.activatedAt || e.createdAt}
        />
      )) || [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(i => <Skeleton height={200} key={i} />)}
    </div>
  );
};

export default UserEvidence;
