import background from '../assets/images/home.png';
import Heading from '../components/Banners/Heading';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTheories } from '../services/theories';
import { useInfiniteScroll } from '../util/dom';
import DefaultTheoryImage from '../assets/images/Theory/theory.png';
import LikelihoodPanel from '../components/LikelihoodPanel';
import SignInModal from '../components/SignIn/SignInModal';
import AuthContext from '../state/AuthContext';
import { flattenPages } from '../util/query';
import { useNavigate } from 'react-router-dom';

const AllAtOnce = ({ theoriesQuery, theories, onSubmit, me, submitted }) => {
  useInfiniteScroll(theoriesQuery);

  return (
    <div className='mx-4 lg:mx-8 my-12 flex flex-col items-center'>
      <p className='text-center max-w-md text-base'>
        This quiz will measure your tendency to either agree or disagree with the beliefs of the
        majority. Please share your opinion on at least 5 questions. If you don't have a strong
        opinion on a question, you can skip it.
      </p>

      {theories?.map(theory => (
        <div
          className='mt-12 p-3 w-full max-w-md rounded-lg border border-gray-400'
          key={theory.id}
        >
          <div className='flex '>
            <img
              className='object-cover rounded-lg size-16'
              src={theory.image?.thumbnail?.url || DefaultTheoryImage}
              alt='Inquiry'
            />
            <p className='ml-2 lg:ml-4 text-xl font-bold text-regular'>{theory.title}</p>
          </div>
          <div className='flex justify-center mt-4'>
            <div className='w-full'>
              <LikelihoodPanel theory={theory} />
            </div>
          </div>
        </div>
      ))}

      {!theoriesQuery.isFetching && !theoriesQuery.hasNextPage && (
        <>
          <button
            onClick={onSubmit}
            className='mt-16 rounded text-white self-center p-3 bg-primary'
          >
            {submitted ? 'Processing...' : 'Submit'}
          </button>

          {me?.dissent === null && (
            <p className='font-bold text-highlighted mt-4 mx-8'>
              You need to share your opinion on more Inquiries to get a dissent score
            </p>
          )}
        </>
      )}
    </div>
  );
};

const Quiz = () => {
  const theoriesQuery = useTheories({ status: 'published' });
  const theories = flattenPages(theoriesQuery.data?.pages);
  const [promptForEmail, setPromptForEmail] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { me, isSignedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = useCallback(() => {
    if (!me || me.dissent === null) return;
    setSubmitted(true);
    if (!isSignedIn) setPromptForEmail(true);
  }, [setSubmitted, isSignedIn, setPromptForEmail, me]);

  useEffect(() => {
    if (isSignedIn && submitted) navigate('/quiz-result');
  }, [isSignedIn, submitted, navigate]);

  return (
    <div>
      <Heading text='Dissent Quiz' backgroundImg={background} />

      <AllAtOnce
        theoriesQuery={theoriesQuery}
        theories={theories}
        onSubmit={onSubmit}
        submitted={submitted}
        me={me}
      />

      <SignInModal
        open={promptForEmail}
        title='Please enter your email to get your dissent score'
        onClose={() => {
          setPromptForEmail(false);
          setSubmitted(false);
        }}
        onSuccess={() => setPromptForEmail(false)}
        context='quiz'
      />
    </div>
  );
};

export default Quiz;
