/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
import { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

// react-router-dom components
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
// @mui material components
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';

// Material Kit 2 PRO React components
import { useTheme } from '@mui/material';
import colors from 'assets/theme/base/colors';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ThreeDotIcon } from '../icons';
import AuthContext from '../../state/AuthContext';
import UserAvatar from '../elements/UserAvatar';
import { useIsAbTest } from '../../services/ab-tests';

const useStyles = makeStyles({
  root: {
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: 0,
    '@media (max-width:767px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    position: 'relative',
    '& .dot': {
      position: 'absolute',
      bottom: '-10px',
      left: '50%',
      width: 5,
      height: 5,
      justifyContent: 'center',
      margin: 'auto',
      borderRadius: 100,
      '@media (max-width:767px)': {
        display: 'none',
      },
    },
    '&:hover': {
      '& span': {
        color: colors.brand.default + ' !important',
      },
      '& .dot': {
        backgroundColor: colors.brand.default,
      },
    },
  },
});

function NavbarItem({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  collapse,
  onClick,
  notifications,
  ...rest
}) {
  const theme = useTheme();
  const hasUnreadNotification = notifications?.some(notification => !notification.read);
  const testHelpIcon = useIsAbTest('help-icon');

  const { me, isAuthenticated, isSignedIn } = useContext(AuthContext);
  const routeComponent = {
    component: RouterLink,
    to: route,
  };

  const classes = useStyles();
  if (name === 'Help' && !testHelpIcon) return null;

  return (
    <>
      <Box
        {...rest}
        onClick={onClick ? onClick : undefined}
        opacity={light ? 1 : 0.6}
        alignSelf={'center'}
        style={{ cursor: 'pointer', userSelect: 'none', textDecoration: 'none', flexShrink: 0 }}
        {...((route || href) && routeComponent)}
      >
        {name === 'Account' && isAuthenticated ? (
          <div className='flex pt-4 pl-4'>
            <UserAvatar user={me} className='size-10 rounded-sm' />

            <Box ml={1}>
              <p
                style={{
                  ...theme.typography.formTitle,
                  color: theme.palette.text.regular,
                  margin: 0,
                }}
              >
                {isSignedIn ? 'Signed in as' : 'Guest User'}
              </p>
              <p
                style={{ ...theme.typography.h4, fontWeight: 500, marginTop: 8, marginBottom: 16 }}
              >
                {me?.displayName || me?.username || me?.email}
              </p>
            </Box>
            <span style={{ color: theme.palette.text.unSelected, marginLeft: 8 }}>
              {me?.dms ? (
                <MailOutlineIcon
                  sx={{
                    fontWeight: 'normal',
                    verticalAlign: 'middle',
                    fontSize: 'large',
                    color: colors.tertiary.focus,
                  }}
                ></MailOutlineIcon>
              ) : (
                <img
                  src={ThreeDotIcon}
                  alt='Icon'
                  style={{ width: '4px', marginTop: '10px', marginLeft: '10px' }}
                />
              )}
            </span>
          </div>
        ) : (
          <Box className={classes.root}>
            <Box display='flex' alignItems='baseline'>
              {icon && (
                <div className='self-center relative flex items-center justify-center p-2.5'>
                  {name === 'Notifications' && hasUnreadNotification && (
                    <span className='absolute right-2.5 top-2.5 size-2 bg-red-500 border border-white rounded-full'></span>
                  )}
                  <img src={icon} alt={name} />
                </div>
              )}
              {!icon && <span style={{ ...theme.typography.navRegular }}>{name}</span>}
              {!icon && collapse && (
                <span style={{ color: theme.palette.text.unSelected, lineHeight: 0 }}>
                  <Icon sx={{ fontWeight: 'normal', verticalAlign: 'middle' }}>
                    keyboard_arrow_down
                  </Icon>
                </span>
              )}
            </Box>
            {!icon && (
              <Box className='dot'>
                <span></span>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of NavbarItem
NavbarItem.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  href: '',
  route: '',
};

// Typechecking props for the NavbarItem
NavbarItem.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default NavbarItem;
