import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

export const GreenSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    &:hover {
      opacity: 0.9;
    }
  }

  & .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background-color: green;
  }
`;
