import { useGetMetrics } from '../../services/activity';
import { useState } from 'react';
import classnames from 'classnames';
import PaidIcon from '@mui/icons-material/Paid';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import { Tooltip } from '@mui/material';
import AdminMetricColumn from './AdminMetricColumn';

const AdminMetrics = () => {
  const defaultDays = 1;
  const oneDayQuery = useGetMetrics(defaultDays, false, false); // needed for the keys
  const oneDayMetrics = oneDayQuery.data?.data;
  const [denominator, setDemonimator] = useState('/1');
  const [days, setDays] = useState('');
  const [columns, setColumns] = useState([1]);
  const [includePaid, setIncludePaid] = useState(false);
  const [includeInvited, setIncludeInvited] = useState(false);

  const denominators = ['/1', '/users', '/evinced', '/days'];
  const onClickDenominator = () =>
    setDemonimator(d => denominators[(denominators.indexOf(d) + 1) % denominators.length]);

  const submit = () => {
    setColumns(cols =>
      !days || cols.includes(+days) ? cols : cols.concat(+days).sort((a, b) => a - b),
    );
    setDays('');
  };

  const deleteColumn = days => setColumns(cols => cols.filter(c => c !== days));

  return (
    <div className='m-4'>
      <div className='flex flex-row h-10'>
        <input
          placeholder='days'
          className='ml-2 bg-gray-100 border w-16 p-1'
          value={days}
          onChange={e => setDays(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && submit()}
        />
        <button className='ml-2 border text-primary px-2' onClick={submit}>
          Add Column
        </button>
        <button className='ml-auto border px-2' onClick={onClickDenominator}>
          {denominator}
        </button>
        <Tooltip title='include paid users'>
          <button
            onClick={() => setIncludePaid(!includePaid)}
            className={classnames('ml-2', includePaid ? 'text-green-400' : 'text-unselected')}
          >
            <PaidIcon />
          </button>
        </Tooltip>
        <Tooltip title='include invited users'>
          <button
            onClick={() => setIncludeInvited(!includeInvited)}
            className={classnames('ml-2', includeInvited ? 'text-green-400' : 'text-unselected')}
          >
            <CoPresentIcon />
          </button>
        </Tooltip>
      </div>

      <div className='flex flex-row text-xs mt-4'>
        {oneDayMetrics && (
          <div className='flex flex-col mx-3'>
            <p className='h-10'>Days</p>
            {Object.keys(oneDayMetrics).map(key => (
              <p className='h-10' key={key}>
                {key}
              </p>
            ))}
          </div>
        )}

        {columns.map((days, i) => (
          <div className='flex flex-row text-xs' key={i}>
            {i > 0 && (
              <AdminMetricColumn
                days={days}
                subtractDays={days - columns[i - 1]}
                includePaid={includePaid}
                includeInvited={includeInvited}
                denominator={denominator}
              />
            )}
            <AdminMetricColumn
              key={days}
              days={days}
              includePaid={includePaid}
              includeInvited={includeInvited}
              denominator={denominator}
              onClick={() => deleteColumn(days)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminMetrics;
