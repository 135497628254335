import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import classnames from 'classnames';

const RankButton = ({ promote, rank, onClick }) => {
  const Icon = promote ? ArrowUpwardIcon : ArrowDownwardIcon;
  const active = rank !== undefined && Math.sign(rank) !== (promote ? -1 : 1);

  return (
    <button
      className={classnames(
        'flex flex-row items-center justify-center',
        active ? 'text-primary' : 'text-unselected',
      )}
      onClick={onClick ? onClick : undefined}
    >
      <div className='relative'>
        <Icon />
        {active && Math.abs(rank) >= 2 && (
          <div className='absolute bottom-1'>
            <Icon />
          </div>
        )}
      </div>
    </button>
  );
};

export default RankButton;
