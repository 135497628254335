import Stack from '@mui/material/Stack';

const WrapStack = ({ children, direction }) => {
  return (
    <Stack direction={direction || 'row'} spacing={0} sx={{ flexWrap: 'wrap', gap: 1 }}>
      {children}
    </Stack>
  );
};

export default WrapStack;
