import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

const SlideDownModal = ({ open, title, onClose, children }) => {
  if (!open)
    return null; // todo change this to use Dialog or Popover and properly render so that we can animate closing
  // https://mui.com/material-ui/react-dialog/#AlertDialogSlide.js
  // or https://mui.com/material-ui/react-dialog/#FormDialog.js
  else
    return (
      <Modal
        open={open}
        onClose={onClose}
        sx={{ margin: 3, display: 'grid', placeItems: 'center' }}
      >
        <Slide direction='down' in={open} timeout={350}>
          <div className='relative w-auto md:w-[500px] flex flex-col rounded-xl bg-white shadow-xl'>
            <div className='flex flex-row justify-between p-4'>
              <p className='font-bold text-sm md:text-xl'>{title}</p>
              <CloseIcon fontSize='medium' sx={{ cursor: 'pointer' }} onClick={onClose} />
            </div>
            {children}
          </div>
        </Slide>
      </Modal>
    );
};

export default SlideDownModal;
