import RankButton from './RankButton';
import { useRank } from '../../state/rank-hook';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { useIsAbTest } from '../../services/ab-tests';

const RankPanel = ({ subject, subjectKey, place, wFull, tooltip }) => {
  const [userRank, onClickUp, onClickDown] = useRank({ subject, subjectKey });
  const [showTooltip, setShowTooltip] = useState(false);
  const testRankTooltips = useIsAbTest('rank-tooltips', showTooltip);

  useEffect(() => {
    if (showTooltip && userRank !== undefined) setShowTooltip(false);
    else {
      const t = window.setTimeout(() => setShowTooltip(!!tooltip && userRank === undefined), 20000);
      return () => window.clearTimeout(t);
    }
  }, [tooltip, userRank, showTooltip, setShowTooltip]);

  return (
    <Tooltip
      open={showTooltip}
      disableHoverListener
      disableFocusListener
      disableTouchListener
      placement='bottom-start'
      title={(testRankTooltips && showTooltip && tooltip) || ''}
    >
      <div
        id='rank-panel'
        className={classnames('border rounded flex flex-row h-full items-stretch justify-center')}
      >
        <div
          className={classnames(
            'my-1 flex items-stretch border-r border-gray-300 !cursor-pointer',
            wFull ? 'w-1/2 justify-center' : 'px-2 md:px-3',
          )}
          onClick={onClickUp}
        >
          <RankButton promote={true} rank={userRank} />
        </div>

        {place && <p className='flex items-center text-[10px] md:text-xs mx-1'>#{place}</p>}
        {!place && (
          <p className='flex items-center  font-bold text-[10px] md:text-xs mx-1'>
            {subject.rank >= 0 ? '+' : ''}
            {subject.rank}
          </p>
        )}

        <div
          className={classnames(
            'my-1 flex items-stretch border-l border-gray-300 !cursor-pointer',
            wFull ? 'w-1/2 justify-center' : 'px-2 md:px-3',
          )}
          onClick={onClickDown}
        >
          <RankButton promote={false} rank={userRank} />
        </div>
      </div>
    </Tooltip>
  );
};

export default RankPanel;
