/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// react-router components
import { Link as RouterLink } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import Collapse from '@mui/material/Collapse';
import MuiLink from '@mui/material/Link';

// Material Kit 2 PRO React components
import { useTheme } from '@mui/material';
import Icon from '@mui/material/Icon';

// Material Kit 2 PRO React examples
import NavbarItem from './NavbarItem';
import { track } from '../../services/analytics';

function NavbarMobile({ routes, open, onClickMenuItem, notifications }) {
  const [openCollapse, setOpenCollapse] = useState(false);

  const toggleCollapse = name => {
    track('toggle-mobile-dropdown', { name, opening: openCollapse !== name });
    setOpenCollapse(openCollapse === name ? false : name);
  };

  const theme = useTheme();

  useEffect(() => !open && setOpenCollapse(false), [open, setOpenCollapse]);

  return (
    <Collapse in={!!open} timeout='auto' unmountOnExit>
      <Box width='calc(100% + 1.625rem)' my={2} ml={-2}>
        {routes
          .filter(r => !r.icon)
          .map(({ name, hidden, icon, href, route, collapse, onReachBottom }, i) =>
            hidden ? null : (
              <div key={i} className='mb-5'>
                <NavbarItem
                  name={name}
                  icon={icon}
                  collapseStatus={name === openCollapse}
                  onClick={() => (collapse ? toggleCollapse(name) : onClickMenuItem(name))}
                  href={href}
                  route={route}
                  collapse={!!collapse}
                  notifications={notifications}
                >
                  <Box sx={{ maxHeight: '21rem', overflowY: 'scroll' }}>
                    {collapse &&
                      collapse.map((item, i) =>
                        item.hidden ? null : (
                          <Box key={i} px={2}>
                            <Box
                              component={item.route ? RouterLink : MuiLink}
                              onClick={() => {
                                onClickMenuItem(item.name);
                                item.onClickMenuItem && item.onClickMenuItem(item.name);
                              }}
                              to={item.route}
                              href={item.href}
                              style={{
                                textDecoration: 'none',
                                display: 'flex',
                                alignItems: 'end',
                                cursor: 'pointer',
                                transition: 'all 300ms linear',
                                padding: '8px 13px',
                              }}
                            >
                              <Box
                                lineHeight={1}
                                color={theme.palette.text.unSelected}
                                sx={{ alignSelf: 'center', '& *': { verticalAlign: 'middle' } }}
                              >
                                <Icon
                                  sx={{
                                    marginRight: 1,
                                    color: item.highlighted
                                      ? theme.palette.tertiary.focus
                                      : theme.palette.text.unSelected,
                                  }}
                                >
                                  {item.icon}
                                </Icon>
                              </Box>
                              <span style={{ ...theme.typography.navRegular }}>{item.name}</span>
                            </Box>
                          </Box>
                        ),
                      )}
                  </Box>
                </NavbarItem>
              </div>
            ),
          )}
      </Box>
    </Collapse>
  );
}

// Typechecking props for the DefaultNavbarMobile
NavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default NavbarMobile;
