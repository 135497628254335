import DOMPurify from 'dompurify';
import { marked } from 'marked';

const renderer = {
  link: (href, title, text) => {
    const newHref = href.replace(
      /^(?!https?:\/\/)(?!\/)/,
      '//', // prepend '//' if protocol is missing so that we don't create a relative link
    );
    const target = newHref.match(/:?\/\//) ? '_blank' : '';
    return `<a href="${newHref}" target="${target}">${text}</a>`;
  },
  code: code => '<code>' + code + '</code>', // no pre since it overrides break-word
};
marked.use({ renderer });

const markdown = body => DOMPurify.sanitize(marked.parse(body), { ADD_ATTR: ['target'] });

export default markdown;
