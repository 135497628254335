import React from 'react';
import ta from 'time-ago';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

import colors from 'assets/theme/base/colors';
import { avatar } from '../../util/avatar';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: colors.black.main,

    '& .avatar': {
      justifyContent: 'center',
      position: 'relative',
      '& .onlineStatus': {
        width: 10,
        height: 10,
        backgroundColor: colors.green.light,
        border: '1px solid white',
        position: 'absolute',
        bottom: 2,
        borderRadius: 100,
        right: 12,
      },
      '& img': {
        width: 50,
        height: 50,
        borderRadius: 4,
        marginRight: 10,
        objectFit: 'cover',
      },
    },
    '& .dms': {
      backgroundColor: colors.brand.default,
      minWidth: 20,
      height: 20,
      textAlign: 'center',
      fontSize: 10,
      borderRadius: 100,
      color: colors.white.main,
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    '& .details': {
      display: 'flex',
      '& .name': {
        color: colors.text.regular,
        opacity: 0.9,
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '19px',
      },
      '& .date': {
        color: colors.text.unSelected,
        opacity: 0.9,
        fontWeight: 400,
        fontSize: 10,
        lineHeight: '12px',
        alignSelf: 'center',
        marginLeft: 10,
      },
    },
    '& .lastMessage': {
      color: colors.text.unSelected,
      opacity: 0.9,
      fontSize: 12,
      fontWeight: 400,
      marginTop: 3,
      lineHeight: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
  },
});
const Recipient = props => {
  const { user, date, dms, header } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className='avatar'>
        <img src={avatar(user)} alt='User Avatar Img' />
        {!header && <Box className='onlineStatus'></Box>}
      </Box>
      <Box>
        <Box className='details'>
          <Box className='name'>{user.username}</Box>
          {date && !header && <Box className='date'>{ta.ago(date)}</Box>}
        </Box>
        {header ? (
          <Box display={'flex'} marginTop={1}>
            <Box
              backgroundColor={colors.green.main}
              width={'8px'}
              height={'8px'}
              alignSelf={'center'}
              borderRadius={100}
            ></Box>
            <Box
              color={colors.text.unSelected}
              alignSelf={'center'}
              marginLeft={1}
              sx={{ opacity: 0.9 }}
              fontSize={14}
              fontWeight={400}
            >
              Online
            </Box>
          </Box>
        ) : (
          <>
            <Box className='lastMessage'>Click here to open conversation</Box>
          </>
        )}
      </Box>

      {!!dms && !header && <span className='dms'>({dms > 10 ? '10+' : dms})</span>}
    </Box>
  );
};

export default Recipient;
