import UsernameModal from './UsernameModal';
import SignInModal from './SignIn/SignInModal';
import { useContext, useEffect } from 'react';
import AuthContext from '../state/AuthContext';

const CredentialModals = ({
  open,
  requireUsername,
  signinPrompt,
  usernamePrompt,
  onSuccess,
  onClose,
  context,
}) => {
  const { isNamed, isSignedIn } = useContext(AuthContext);

  useEffect(() => {
    if (open && isSignedIn && (!requireUsername || isNamed)) {
      onSuccess && onSuccess();
      onClose && onClose();
    }
  }, [open, isNamed, isSignedIn, requireUsername, onSuccess, onClose]);

  return (
    <>
      <SignInModal
        open={open && !isSignedIn}
        title={signinPrompt}
        onClose={onClose}
        context={context}
      />
      <UsernameModal
        open={open && isSignedIn && !isNamed && requireUsername}
        title={usernamePrompt}
        onClose={onClose}
      />
    </>
  );
};

export default CredentialModals;
