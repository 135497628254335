import { Dialog, DialogTitle } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useContext, useState } from 'react';
import Container from '@mui/material/Container';
import UiContext from '../state/UiContext';

const Confirmation = ({ children, open, prompt, onClose }) => {
  function onClickYes(e) {
    e.stopPropagation();
    onClose(true);
  }

  function onClickNo(e) {
    e.stopPropagation();
    onClose(false);
  }

  const buttonClass = 'text-base text-primary p-3 border rounded my-3';
  return (
    <Dialog open={open}>
      <DialogTitle>{prompt}</DialogTitle>
      <Container>
        {children}
        <Stack direction='row' justifyContent='space-between'>
          <button className={buttonClass} onClick={onClickYes}>
            Yes
          </button>
          <button className={buttonClass} onClick={onClickNo}>
            No
          </button>
        </Stack>
      </Container>
    </Dialog>
  );
};

export default Confirmation;

export const ConfirmationWithInput = ({ open, prompt, label, inputRequired, onClose }) => {
  const [inputValue, setInputValue] = useState('');
  const { toastError } = useContext(UiContext);

  function onCloseConfirmation(value) {
    if (value && inputValue === '' && inputRequired) toastError(label + ' required');
    else onClose(value && inputValue);
  }

  return (
    <Confirmation open={open} prompt={prompt} onClose={onCloseConfirmation}>
      <input
        autoFocus={true}
        type='text'
        placeholder={label}
        className='w-full p-2'
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
      />
    </Confirmation>
  );
};
