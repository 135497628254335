import { useContext, useState } from 'react';
import { validateDisplayName, validateUsername } from '../services/validators';
import ui from '../config/ui';
import { useUpdateMe } from '../services/users';
import UiContext from '../state/UiContext';
import AuthContext from '../state/AuthContext';
import classnames from 'classnames';
import UsernameInput from '../components/elements/UsernameInput';

const UserEditor = ({ onClose }) => {
  const { me, isNamed } = useContext(AuthContext);
  const [newUsername, setNewUsername] = useState(me?.username);
  const [newDisplayName, setNewDisplayName] = useState(me?.displayName);
  const [newBio, setNewBio] = useState(me?.bio);
  const [validationErrors, setValidationErrors] = useState({});
  const updateMe = useUpdateMe();
  const { toastSuccess, toastError } = useContext(UiContext);

  //const editingUsername = user && !user.username;

  function onChangeDisplayName(e) {
    const name = e.target.value;
    setValidationErrors({
      ...validationErrors,
      displayName: validateDisplayName(name),
    });
    setNewDisplayName(name);
  }

  function onChangeUsername(e) {
    const name = e.target.value;
    setValidationErrors({
      ...validationErrors,
      username: validateUsername(name),
    });
    setNewUsername(name);
  }

  function onChangeBio(e) {
    setNewBio(e.target.value);
  }

  function onCancelUpdate() {
    setNewUsername(undefined);
    setNewBio(undefined);
    setNewDisplayName(undefined);
    setValidationErrors({});
    onClose();
    document.documentElement.scrollTop = 0;
  }

  function onUpdateUser() {
    for (const k in validationErrors) {
      if (validationErrors[k]) {
        toastError(validationErrors[k]);
        return;
      }
    }

    updateMe.mutate(
      { username: newUsername, displayName: newDisplayName, bio: newBio },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          onClose();
          document.documentElement.scrollTop = 0;
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }

  const Label = ({ className, children }) => (
    <p className={classnames(className, 'text-xs font-thin text-gray-400')}>{children}</p>
  );

  return (
    <div className=' mx-6 mt-16 space-y-3 text-base'>
      <div>
        <Label>username</Label>
        <UsernameInput
          username={newUsername}
          onChange={onChangeUsername}
          className={classnames('w-full border rounded px-2', isNamed && 'text-gray-400')}
          disabled={isNamed}
        />
        <p>{validationErrors.username}</p>
      </div>
      <div>
        <Label>display name</Label>
        <input
          value={newDisplayName}
          onChange={onChangeDisplayName}
          maxLength={ui.displayNameLength}
          className='w-full  border rounded px-2'
        />
      </div>

      <div>
        <Label>Bio:</Label>
        <textarea
          value={newBio}
          rows='3'
          className='w-full text-sm  border rounded'
          onChange={onChangeBio}
          maxLength={ui.bioLength}
        />
      </div>

      <div className='flex space-x-3 mt-2'>
        <button className='bg-primary text-white rounded p-2' onClick={onUpdateUser}>
          Submit
        </button>
        <button className='bg-gray-400 text-unselected rounded p-2' onClick={onCancelUpdate}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UserEditor;
