import sanitizeHtml from 'sanitize-html';

export const globalReplace = ({ text, search, replace }) => {
  const regex = new RegExp(search, 'g');
  return text.replace(regex, replace);
};

export const addMarkupsToLinks = ({ text }) => {
  // Allow only a super restricted set of tags and attributes
  const cleanText = sanitizeHtml(text, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a'],
    allowedAttributes: {
      a: ['href'],
    },
  });

  return cleanText
    .split(' ')
    .map(word => {
      if (!word.startsWith('http')) {
        return word;
      }

      return `<a href="${word}" target="_blank">${word}</a>`;
    })
    .join(' ');
};
