import { fetchClient } from 'services/api';
import { useInfiniteQuery } from 'react-query';

const apiClient = fetchClient();

export const sendNewMessage = async data => {
  const res = await apiClient.post('message', data);

  return res.data;
};

export const clearConversation = async id => {
  const res = await apiClient.patch(`conversation/${id}/clear`);

  return res.data;
};

export const getOrCreateConversation = async to => {
  const res = await apiClient.post('conversation', { to });

  return res.data;
};

const getConversations = async (page = 0) => {
  const res = await apiClient.get('conversation?page=' + page + '&perPage=25');
  return res.data;
};

export const useConversations = () =>
  useInfiniteQuery('conversations', ({ pageParam }) => getConversations(pageParam), {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.meta.pageCount <= lastPage.meta.page) {
        return null;
      }
      return lastPage.meta.page + 1;
    },
  });

const getMessages = async (conversation, page = 1) =>
  await apiClient.get(`message?conversation=${conversation}&page=${page}&perPage=40`).then(res => {
    return res.data;
  });

export const useMessages = conversation =>
  useInfiniteQuery(
    ['messages', conversation],
    ({ pageParam }) => getMessages(conversation, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage.meta.nextPage) {
          return null;
        }

        return lastPage.meta.page + 1;
      },
    },
  );
