import SearchResults from '../components/Search/SearchResults';

const Search = () => {
  return (
    <div className='flex flex-col space-y-2'>
      <SearchResults />
    </div>
  );
};

export default Search;
