import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useParams } from 'react-router-dom';
import { reportShare, useGetLikelihoods, useGetUser } from '../services/users';
import _ from 'lodash';
import BannerImg from 'assets/images/collaboration.png';
import Likelihood from '../components/elements/Likelihood';
import { avatar } from '../util/avatar';
import DefaultTheoryImage from '../assets/images/Theory/theory.png';
import React, { useCallback, useContext, useState } from 'react';
import { useUpdateLikelihood } from '../services/theories';
import UiContext from '../state/UiContext';
import AuthContext from '../state/AuthContext';
import UsernameModal from '../components/UsernameModal';
import SignInModal from '../components/SignIn/SignInModal';
import Username from '../components/elements/Username';
import { getDefinition, getRoleName, getTeam, getTeamName, teams } from '../util/concurrency';
import { useGetToken } from '../services/auth';
import Heading from '../components/Banners/Heading';

const Alignment = () => {
  const params = useParams();
  const { toastSuccess, toastError } = useContext(UiContext);
  const { isSignedIn, isNamed, me, setTokenSentTo } = useContext(AuthContext);
  const theirQuery = useGetLikelihoods(params.username);
  const theirLikelihoods = theirQuery.data?.data;
  const myQuery = useGetLikelihoods();
  const myLikelihoods = myQuery.data?.data;
  const [newLikelihood, setNewLikelihood] = useState({});
  const updateLikelihood = useUpdateLikelihood();
  const [usernameModalOpen, setUsernameModalOpen] = useState(false);
  const [signinModalOpen, setSigninModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const getToken = useGetToken();

  const myMap = _.keyBy(myLikelihoods, l => l.theory.id);
  const them = useGetUser({ idOrUsername: params.username }).data?.data;

  const opinion = useCallback(
    a => {
      setNewLikelihood(nlh => ({ ...nlh, ...a }));

      const theoryId = Object.keys(a)?.[0];
      if (theoryId)
        updateLikelihood.mutate(
          {
            theoryId,
            likelihood: a[theoryId],
            notifyUsername: isNamed ? params.username : undefined,
          },
          {
            onError: res => toastError(res.message),
          },
        );
    },
    [setNewLikelihood, updateLikelihood, toastError, params.username, isNamed],
  );

  const onSubmitEmail = useCallback(() => {
    if (!getToken.isLoading)
      getToken.mutate(
        { email, context: 'compare' },
        {
          onSuccess: () => {
            setTokenSentTo(email);
            setSigninModalOpen(true);
          },
          onError: res => toastError(res.message),
        },
      );
  }, [getToken, email, setTokenSentTo, setSigninModalOpen, toastError]);

  const onClick = useCallback(
    (theory, lh) => {
      const newOpinions = Object.keys(newLikelihood).length;
      const newOpinion = { [theory.id]: lh };
      if (newOpinions >= 4 && !isNamed) setUsernameModalOpen(newOpinion);
      else opinion(newOpinion);
    },
    [newLikelihood, isNamed, setUsernameModalOpen, opinion],
  );

  const onClickCopy = () => {
    navigator?.clipboard?.writeText(compareLink).then(() => {
      reportShare({ compareLink }).then(() => {});
      toastSuccess('Copied to Clipboard');
    });
  };

  const shared = theirLikelihoods
    ?.filter(l => myMap?.[l.theory.id] && !newLikelihood[l.theory.id])
    ?.map(l => ({
      ...l,
      theirs: l.likelihood,
      ours: myMap[l.theory.id].likelihood,
    }))
    ?.map(l => ({
      ...l,
      delta: Math.abs(l.ours - l.theirs),
    }))
    ?.sort((a, b) => a.delta - b.delta);

  const agree = shared?.filter(s => s.delta <= 20);
  const disagree = shared?.filter(s => s.delta > 20);
  const unanswered = theirLikelihoods
    ?.filter(l => !myMap?.[l.theory.id] || newLikelihood[l.theory.id])
    .map(l => ({
      ...l,
      theirs: l.likelihood,
      ours: newLikelihood[l.theory.id],
    }));

  const compareLink = `${window.location.origin}/compare/${me?.username}`;

  const LikelihoodTable = ({ likelihoods }) => (
    <div>
      {likelihoods?.map(l => (
        <div key={l.id} className='mt-9 lg:mt-14'>
          <div className='flex items-center mb-2 lg:mb-4'>
            <img
              className='object-cover rounded h-10 w-10'
              src={l.theory.image?.thumbnail?.url || DefaultTheoryImage}
              alt='Inquiry'
            />
            <p className='ml-2 lg:ml-4 text-xl lg:text-2xl font-bold text-regular'>
              {l.theory.title}
            </p>
          </div>

          <div className='flex flex-row justify-around'>
            {[10, 30, 50, 70, 90].map(lh => (
              <Likelihood
                key={lh}
                lh={lh}
                selected={l.ours === lh || newLikelihood[l.theory.id] === lh}
                img={
                  l.ours &&
                  l.ours !== l.theirs &&
                  (l.theirs === lh ? avatar(them) : l.ours === lh ? avatar(me) : undefined)
                }
                text={l.ours === lh && (l.theirs === lh ? 'Both' : 'You')}
                onClick={!l.ours ? () => onClick(l.theory, lh) : undefined}
              />
            ))}
          </div>
        </div>
      ))}
      {!!likelihoods?.length && <div className='m-12 h-0 border' />}
    </div>
  );

  const UserCard = ({ user }) => (
    <div className='flex flex-col space-y-4 text-sm lg:text-base border rounded p-4 lg:p-8'>
      <div className='flex flex-row space-x-5'>
        <img
          src={avatar(user)}
          className={'flex object-top object-cover w-8 h-8 rounded-full'}
          alt='User Avatar'
        />
        <Username username={user?.username} />
      </div>
      {isSignedIn ? (
        <p>
          {getTeamName(user)} {getRoleName(user)}
        </p>
      ) : (
        <p>______ ______</p>
      )}
      <p>Certainty: {isSignedIn && user?.certainty !== null ? `${user.certainty}%` : '??'}</p>
      <p>Dissent: {(isSignedIn && Math.round(user?.dissent * 10) / 10) || '??'}</p>
    </div>
  );

  const Heading2 = ({ text }) => (
    <p className='text-lg lg:text-2xl font-bold p-3 text-center'>{text}</p>
  );
  const d = me?.dissent - them?.dissent;
  const difference = isSignedIn ? Math.abs(Math.round(d * 10) / 10) : '??';
  const direction = d < 0 ? teams['agree'] : teams['disagree'];
  const myCertainty = me?.certainty === null ? null : Math.round(me?.certainty);
  const theirCertainty = them?.certainty === null ? null : Math.round(them?.certainty);
  return (
    <div>
      <Heading
        text='Compare Opinions'
        backgroundImg={BannerImg}
        tooltip={`On this page, you can see how your opinions compare with those of @${them?.username}.`}
      />

      <div className='mx-auto mb-10 px-2 lg:px-8 max-w-xl'>
        {!!theirQuery.isSuccess && !theirLikelihoods?.length && (
          <p>This user has not answered any inquiry questions</p>
        )}
        <div className='mt-10'>
          {!!agree?.length && !!disagree?.length && (
            <Heading2 text={'Where you and @' + them?.username + ' agree:'} />
          )}
          <LikelihoodTable likelihoods={agree} />
        </div>
        <div className='mt-10'>
          {!!agree?.length && !!disagree?.length && (
            <Heading2 text={'Where you and @' + them?.username + ' disagree:'} />
          )}
          <LikelihoodTable likelihoods={disagree} />
        </div>
        {!!unanswered?.length && (
          <div className='mt-10'>
            <Heading2 text={'Share your Opinions to Reveal Theirs'} />
            <LikelihoodTable likelihoods={unanswered} />
          </div>
        )}

        {!isSignedIn && (
          <div className='flex flex-col items-center mt-12 text-sm'>
            <p className='font-bold'>Enter your email to:</p>
            <ul className='list-disc list-inside text-sm font-normal mt-2'>
              <li>See a summary below of how you two compare</li>
              <li>Get your own link to share with friends</li>
              <li>Stay updated on new evidence on these topics</li>
            </ul>
            <div className='flex flex-row space-x-3 mt-3'>
              <input
                className={'p-1 border rounded-sm'}
                placeholder='Your email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                onKeyUp={e => e.key === 'Enter' && onSubmitEmail()}
              />
              <button className='rounded bg-primary text-white px-2' onClick={onSubmitEmail}>
                {getToken.isLoading ? 'Processing...' : 'Compare'}
              </button>
            </div>
          </div>
        )}

        <div className='flex flex-col'>
          <div className={'flex flex-row justify-around mt-16'}>
            <UserCard user={{ ...me, certainty: myCertainty }} />
            <UserCard user={{ ...them, certainty: theirCertainty }} />
          </div>

          <div className='text-xs italic mt-4 self-center'>
            {isSignedIn && (
              <div>
                {!!me?.dissent && (
                  <p>
                    <span className='font-bold'>{getTeamName(me)}:</span>{' '}
                    {getDefinition(teams[getTeam(me)])}
                  </p>
                )}
                {getTeam(me) !== getTeam(them) && !!them?.dissent && (
                  <p>
                    <span className='font-bold'>{getTeamName(them)}:</span>{' '}
                    {getDefinition(teams[getTeam(them)])}
                  </p>
                )}
                {!!me?.certainty && (
                  <p>
                    <span className='font-bold'>{getRoleName(me)}:</span>{' '}
                    {getDefinition(getRoleName(me))}
                  </p>
                )}
                {getRoleName(me) !== getRoleName(them) && theirCertainty >= 0 && (
                  <p>
                    <span className='font-bold'>{getRoleName(them)}:</span>{' '}
                    {getDefinition(getRoleName(them))}
                  </p>
                )}
              </div>
            )}

            <p>
              <span className='font-bold'>Certainty:</span> how close to Yes/No your opinions are
            </p>
            <p>
              <span className='font-bold'>Dissent:</span> how much you disagree with consensus
              beliefs
            </p>

            <p className={'mt-3'}>
              You are {isSignedIn ? Math.abs(myCertainty - theirCertainty) : '??'}%{' '}
              {!isSignedIn ? 'more/less' : myCertainty - theirCertainty < 0 ? 'less' : 'more'}{' '}
              certain than @{them?.username}
            </p>
            {!!me?.dissent && !!them?.dissent && (
              <p>
                You are {difference} SDs {!isSignedIn ? 'more/less' : 'more'}{' '}
                {direction.toLowerCase()} than @{them?.username}
              </p>
            )}
          </div>
        </div>

        {isSignedIn && (
          <div className='border rounded mt-10 p-3 lg:p-5'>
            <Heading2 text='Your Turn' />
            <p className='text-sm'>
              Share this link with your friends to compare opinions. You'll be notified once they
              share their opinions.
            </p>
            <div className='border-2 p-4 border-slate-400 rounded flex flex-row space-x-4 justify-center items-center mt-2'>
              <p className='text-xs ls:text-sm'>{compareLink}</p>
              <button className='px-2 rounded border-2' onClick={onClickCopy}>
                <ContentCopyIcon />
              </button>
            </div>
          </div>
        )}

        <UsernameModal
          title={`Please choose a username so that @${them?.username} can see your opinions`}
          open={usernameModalOpen}
          onSuccess={() => {
            opinion(usernameModalOpen);
            setUsernameModalOpen(false);
          }}
          onClose={() => setUsernameModalOpen(false)}
        />
        <SignInModal
          title='Please verify your email to continue'
          context='compare'
          open={signinModalOpen}
          onSuccess={() => {
            setSigninModalOpen(false);
          }}
          onClose={() => setSigninModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default Alignment;
