/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React Base Styles
import colors from 'assets/theme/base/colors';
import typography from 'assets/theme/base/typography';

// Material Kit 2 PRO React Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem';

const { transparent, info, secondary, brand, text } = colors;
const { size } = typography;

const outlined = {
  base: {
    minHeight: pxToRem(40),
    color: text.unSelected,
    borderColor: brand.default,
    fontWeight: 500,
    padding: `${pxToRem(10)} ${pxToRem(24)}`,
    '&:hover': {
      opacity: 1,
      // backgroundColor: transparent.main,
      borderColor: brand.default,
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    minWidth: pxToRem(82),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.sm,
    fontWeight: 500,
    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(14)} !important`,
    },
  },

  medium: {
    minHeight: pxToRem(40),
    minWidth: pxToRem(125),
    padding: `${pxToRem(9)} ${pxToRem(24)}`,
    fontWeight: 500,

    fontSize: size.sm,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(14)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(50),
    minWidth: pxToRem(142),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: size.lg,
    fontWeight: 500,
    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: transparent.main,
    borderColor: info.main,

    '&:hover': {
      backgroundColor: transparent.main,
    },
  },

  secondary: {
    backgroundColor: transparent.main,
    borderColor: secondary.main,

    '&:hover': {
      backgroundColor: transparent.main,
    },
  },
};

export default outlined;
