import { useNavigate } from 'react-router-dom';
import { avatar } from '../../util/avatar';
import classnames from 'classnames';
import React from 'react';

const UserAvatar = ({ user, className }) => {
  const navigate = useNavigate();
  return (
    <img
      src={avatar(user)}
      className={classnames('flex object-top object-cover cursor-pointer shrink-0', className)}
      alt='User Avatar'
      onClick={() => navigate('/user/' + user.username)}
    />
  );
};

export default UserAvatar;
