import React, { useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import Body from './Body';
import Username from './elements/Username';
import ta from 'time-ago';
import { GreenSwitch } from './elements/GreenSwitch';
import Diff from 'react-stylable-diff';
import Citation from './Evidence/Citation';
import { useSearchParams } from 'react-router-dom';

const RevisionHistory = ({ subject, useQuery, onRestore }) => {
  const revisionQuery = useQuery(subject?.id);
  const [selectedRevisionId, setCurrentRevisionId] = useState(null);
  const [showDiff, setShowDiff] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef();

  const revisions = useMemo(
    () => [
      ...(subject
        ? [
            {
              id: 'current',
              title: subject?.title,
              body: subject?.body,
              link: subject?.link,
              citation: subject?.citation,
              editedAt: subject?.editedAt || subject?.createdAt,
              user: { username: subject?.editedByUsername || subject?.user.username },
            },
          ]
        : []),
      ...(revisionQuery.data?.data ? revisionQuery.data?.data : []),
    ],
    [revisionQuery, subject],
  );

  const selectedRevisionIndex = revisions.findIndex(r => r.id === selectedRevisionId);
  const selectedRevision = revisions[selectedRevisionIndex];
  const previousRevision =
    selectedRevisionIndex < revisions.length - 1 ? revisions[selectedRevisionIndex + 1] : null;

  function onKeyDown(e) {
    const curr = revisions.findIndex(r => r.id === selectedRevisionId);
    if (!~curr) return;

    if (e.key === 'ArrowUp' && curr > 0) {
      e.preventDefault();
      setCurrentRevisionId(revisions[curr - 1].id);
    }

    if (e.key === 'ArrowDown' && curr < revisions.length - 1) {
      e.preventDefault();
      setCurrentRevisionId(revisions[curr + 1].id);
    }
  }

  useEffect(() => {
    if (!searchParams.get('showRevisionHistory')) return;
    const interval = window.setInterval(() => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        window.clearInterval(interval);
        searchParams.delete('showRevisionHistory');
        setSearchParams(searchParams, { replace: true });
      }
    }, 100);

    return () => window.clearInterval(interval);
  }, [searchParams, setSearchParams, ref]);

  useEffect(
    () => !selectedRevisionId && revisions.length && setCurrentRevisionId(revisions[0].id),
    [selectedRevisionId, revisions, setCurrentRevisionId],
  );

  return (
    <div ref={ref} className='flex flex-row-reverse'>
      <div
        className={classnames('flex flex-col w-32 sm:w-44 shrink-0 items-center')}
        onKeyDown={onKeyDown}
        tabIndex={100}
      >
        <p className='text-base md:text-xl font-semibold mb-3'>Revision History</p>

        {revisions.map(revision => (
          <div
            key={revision.id}
            className={classnames(
              'border cursor-pointer px-2 py-4 text-xs md:text-sm w-full',
              revision?.id === selectedRevisionId && 'bg-primary bg-opacity-30',
            )}
            onClick={() => setCurrentRevisionId(revision.id)}
          >
            <p>{ta.ago(revision.editedAt)}</p>
            {revision.id === 'current' && <p className='text-xs italic'>Current Version</p>}
            <p>@{revision.user.username}</p>
          </div>
        ))}
        <div className='flex flex-row items-center'>
          <p className='text-xs md:text-sm'>Show changes</p>
          <GreenSwitch
            checked={showDiff}
            onChange={() => setShowDiff(!showDiff)}
            color='secondary'
          />
        </div>
      </div>

      <div className='flex flex-col mr-3 w-full items-start'>
        <div className='text-regular font-semibold mb-3 text-base md:text-xl'>
          {showDiff ? (
            <Diff inputA={previousRevision?.title} inputB={selectedRevision?.title} type='words' />
          ) : (
            selectedRevision?.title
          )}
        </div>
        <div className='bg-gray-100 w-full whitespace-pre-wrap text-base break-word'>
          {showDiff ? (
            <Diff inputA={previousRevision?.body} inputB={selectedRevision?.body} type='words' />
          ) : (
            <Body body={selectedRevision?.body} />
          )}
        </div>

        {showDiff ? (
          <>
            <div className='text-regular mb-3 text-sm break-word'>
              <Diff inputA={previousRevision?.citation} inputB={selectedRevision?.citation} />
            </div>
            <div className='text-regular mb-3 text-sm break-word'>
              <Diff inputA={previousRevision?.link} inputB={selectedRevision?.link} />
            </div>
          </>
        ) : (
          <Citation evidence={selectedRevision} />
        )}

        <div className='flex flex-row space-x-2 mt-5 text-xs'>
          <Username username={selectedRevision?.user.username} />
          {selectedRevision && (
            <p>
              {new Date(selectedRevision?.editedAt).toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>
          )}

          {onRestore && selectedRevision?.id !== 'current' && (
            <button className='text-primary text-sm' onClick={() => onRestore(selectedRevision)}>
              Restore this revision
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RevisionHistory;
