import React, { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from 'assets/theme';
import './App.css';
import Layout from './components/Layout';
import { healthCheck } from 'services/health';
import { UiContextProvider } from './state/UiContext';
import { PushNotificationProvider } from 'contexts/PushNotificationContext';
import { AuthContextProvider } from './state/AuthContext';
import HeadTags from './components/HeadTags';
import Twitter from 'util/twitter';
import { useLandingEffect } from './services/analytics';
import { useNavigate } from 'react-router-dom';
import interceptClicks from 'intercept-link-clicks';

Twitter.init(process.env.REACT_APP_TWITTER_PIXEL_ID);

export default function App() {
  useLandingEffect();
  const navigate = useNavigate();

  useEffect(() => {
    healthCheck();
  }, []);

  useEffect(
    () =>
      interceptClicks((e, target) => {
        // fixed regex from intercept-link-clicks PR 1.1.0
        const isInternal = new RegExp(
          '^(?:(?:http[s]?:)?//' +
            window.location.host.replace(/\./g, '\\.') +
            ')?(?:/[^/]|#|(?!(?:http[s]?:)?//).*$)',
          'i',
        );

        if (isInternal.test(target.href)) {
          e.preventDefault();
          navigate(target.pathname);
        }
      }),
    [navigate],
  );

  return (
    <ThemeProvider theme={theme}>
      <HeadTags
        title='Evincer'
        description='A platform for sharing the best empirical evidence for controversial questions without suppressing either side'
      />
      <UiContextProvider>
        <AuthContextProvider>
          <PushNotificationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <CssBaseline />
            <Layout />
          </PushNotificationProvider>
        </AuthContextProvider>
      </UiContextProvider>
    </ThemeProvider>
  );
}
