import Body from '../../Body';
import { useNavigate } from 'react-router-dom';
import DefaultTheoryImage from '../../../assets/images/Theory/theory.png';
import TheoryTitle from './TheoryTitle';
import Tag from '../../elements/Tag';
import TheoryButtonPanel from '../TheoryButtonPanel';
import { useContext, useRef, useState } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import UiContext from '../../../state/UiContext';
import classnames from 'classnames';
import Editing from '../../Editing';
import UserAvatar from '../../elements/UserAvatar';
import ta from 'time-ago';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LikelihoodPanel from '../../LikelihoodPanel';
import { useMe } from '../../../services/users';

const Contributor = ({ user }) => {
  const [showBio, setShowBio] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      key={user.id}
      className={classnames('grid gap-2 md:gap-3 grid-cols-12 mt-3 md:mt-5 items-center')}
    >
      <UserAvatar user={user} className='size-5 md:size-6 rounded col-span-1' />
      <p
        className={
          'text-ellipsis overflow-hidden col-span-3 text-xs md:text-sm font-bold text-regular cursor-pointer'
        }
        onClick={() => navigate('/user/' + user.username)}
      >
        {'@' + user.username}
      </p>
      <p
        className={classnames('text-xs md:text-sm col-span-6', {
          'cursor-pointer line-clamp-1': !showBio,
        })}
        onClick={() => setShowBio(true)}
      >
        {user.bio}
      </p>
      <Tooltip
        title={
          'Scrolls (📜) are earned by posting / editing the Inquiry, posting / editing / ranking Evidence or Rebuttals, posting Comments or Responses, and reading content'
        }
        enterDelay={500}
      >
        <p className='text-xs md:text-sm font-bold col-span-2 justify-self-end'>
          {Math.round(user.theoryTrust / 1000)}k&nbsp;📜
        </p>
      </Tooltip>
    </div>
  );
};

const TheoryTitleBody = ({ theory, onClickEdit, onClickEdited }) => {
  const { isXs, isSm, isMd, isLg, mdPlus } = useContext(UiContext);
  const [showMore, setShowMore] = useState(false);
  const parentDiv = useRef(null);
  const bodyDiv = useRef(null);
  const navigate = useNavigate();
  const me = useMe().data?.data;

  return (
    <div className={'p-4 2xl:px-0 md:p-6'}>
      {theory ? (
        <TheoryTitle theory={theory} onClick={() => setShowMore(false)} />
      ) : (
        <Skeleton height={70} />
      )}

      {mdPlus && <div className='h-0 border border-blue-400 w-1/6 mx-auto my-5' />}

      <div className={classnames('grid grid-cols-1 md:grid-cols-2 md:gap-8 xl:gap-16')}>
        <div className={'md:order-last'}>
          {theory ? (
            <div>
              {
                <div
                  className='relative bg-cover bg-center rounded-lg w-full aspect-[2/1]'
                  style={{ backgroundImage: `url('${theory?.image?.url || DefaultTheoryImage}')` }}
                >
                  {showMore ? (
                    <div
                      className={'bg-white rounded-lg bg-opacity-90 px-2 py-3 md:py-5 min-h-full'}
                    >
                      <p className='relative text-center text-base font-bold'>
                        Top contributors:
                        <button
                          className='font-normal text-unselected absolute right-1'
                          onClick={() => setShowMore(false)}
                        >
                          x
                        </button>
                      </p>
                      {theory?.contributors
                        ?.filter(u => u.username && u.theoryTrust >= 500)
                        ?.slice(0, mdPlus ? 7 : 5)
                        .map(u => (
                          <Contributor user={u} key={u.id} />
                        ))}
                    </div>
                  ) : (
                    <TheoryButtonPanel theory={theory} onClickEdit={onClickEdit} />
                  )}
                </div>
              }
            </div>
          ) : (
            <Skeleton
              variant='rounded'
              height={isXs ? 100 : isSm ? 125 : isMd ? 150 : isLg ? 175 : 200}
            />
          )}
        </div>

        <div
          ref={parentDiv}
          className={classnames('relative overflow-hidden', { 'aspect-[2/1]': !showMore })}
        >
          {theory ? (
            <div ref={bodyDiv} className='text-xs md:text-sm mt-4 md:mt-0'>
              <Body body={theory.body} />
              <div
                className={
                  'flex justify-end items-center space-x-2 text-unselected text-2xs md:text-xs'
                }
              >
                <UserAvatar user={theory.user} className='size-5 md:size-6 rounded col-span-1' />
                <p
                  className={
                    'text-ellipsis overflow-hidden col-span-3 font-bold text-regular cursor-pointer'
                  }
                  onClick={() => navigate('/user/' + theory.user.username)}
                >
                  {'@' + theory.user.username}
                </p>
                {theory.status === 'published' ? <HistoryEduIcon /> : <AccessTimeOutlinedIcon />}
                <p
                  className={classnames('', theory.editedBy ? 'cursor-pointer' : '')}
                  onClick={theory?.editedBy && onClickEdited}
                >
                  asked {ta.ago(theory.createdAt)}
                </p>
              </div>
              <Editing subject={theory} onClick={onClickEdited} />
            </div>
          ) : (
            [1, 2, 3, 4, 5, 6].map(i => <Skeleton key={i} />)
          )}
          {showMore && (
            <div className={'mt-4 flex flex-row flex-wrap gap-2'}>
              {theory?.tags.map((tag, i) => (
                <Tag tag={tag} key={i} />
              ))}
            </div>
          )}

          {!showMore && (
            <div className='absolute bottom-0 left-0 right-0 bg-gradient-to-t from-white flex justify-center px-6 pt-20'>
              <button
                className='flex rounded-lg py-2 px-5 bg-white border border-teal-600 text-teal-600 text-2xs md:text-xs font-bold pointer-events-auto'
                onClick={() => setShowMore(true)}
              >
                <span>More</span>
              </button>
            </div>
          )}
        </div>
      </div>

      {me && showMore && me.trustLevel >= 3 && (
        <div>
          <div className='mt-4 sm:mt-9 flex flex-col sm:flex-row justify-center'>
            <Tooltip
              title="Please share your current opinion regardless of how much Evidence you've read.  This helps others see how the Evidence sways your opinion if you change it later."
              enterDelay={500}
            >
              <p className='text-center sm:text-right mb-3 sm:mb-0 mr-2 font-bold text-base sm:text-lg md:text-xl'>
                Your Opinion
              </p>
            </Tooltip>
            <div className={'grow max-w-xl'}>
              <LikelihoodPanel theory={theory} />
            </div>
          </div>
          {!theory?.userLikelihood && (
            <p className='mt-2 text-unselected text-sm text-center text-balance'>
              This helps determine how the evidence sways your opinion.
            </p>
          )}
        </div>
      )}
    </div>
  );
};
export default TheoryTitleBody;
