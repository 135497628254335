import { useNavigate } from 'react-router-dom';

const NewInquiryButton = ({ onClick }) => {
  const navigate = useNavigate();
  return (
    <button
      className='text-xs md:text-sm cursor-pointer rounded border border-primary font-semibold p-3'
      onClick={() => (onClick ? onClick() : navigate('/inquiries/new'))}
    >
      Create Inquiry
    </button>
  );
};

export default NewInquiryButton;
