import { fetchClient } from 'services/api';
import { useQuery } from 'react-query';
import { staleTimes } from 'services/query-settings';

const apiClient = fetchClient();

export const subscriptionSettingsQueryKeys = {
  all: ['subscription-settings'],
};

export const enableSubscription = async ({ type, medium }) => {
  const res = await apiClient.post('subscription-settings', {
    type,
    medium,
  });

  return res.data;
};

export const updateSubscription = async ({ id, category, isActive }) => {
  const res = await apiClient.patch(`subscription-settings/${id}`, {
    category,
    isActive,
  });

  return res.data;
};

export const disableSubscription = async ({ idOrToken }) => {
  const url = `subscription-settings/${idOrToken}`;
  const res = await apiClient.delete(url);

  return res.data;
};

const getSubscriptionSettings = async () => {
  const res = await apiClient.get('subscription-settings');
  return res.data;
};

export const useSubscriptionSettings = ({ enabled } = {}) =>
  useQuery(subscriptionSettingsQueryKeys.all, () => getSubscriptionSettings(), {
    staleTime: staleTimes.blip,
    enabled,
  });
