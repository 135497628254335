export const emojis = {
  disagree: '🦆',
  neither: '🐿',
  agree: '🐑',
};

export const animals = {
  disagree: 'quacks',
  neither: 'squirrels',
  agree: 'sheep',
};

export const teams = {
  disagree: 'Dissenting',
  agree: 'Concurring',
  neither: 'Eccentric',
};

export const roles = {
  uncertain: 'Explorer',
  neither: 'Scout',
  certain: 'Soldier',
};

export const getDissentThresholds = () => [1.2, 2.2];

export const getTeam = user =>
  user?.dissent && user.completedSignup !== false
    ? user.dissent >= getDissentThresholds()[1]
      ? 'disagree'
      : user.dissent > getDissentThresholds()[0]
      ? 'neither'
      : 'agree'
    : '';

export const getTeamName = user =>
  user?.dissent && user.completedSignup !== false ? teams[getTeam(user)] : '';

export const getDefinition = term =>
  ({
    [teams.disagree]: 'tend to disagree with consensus beliefs',
    [teams.agree]: 'tend to agree with consensus beliefs',
    [teams.neither]: 'sometimes agree and sometimes disagree with consensus beliefs',
    [roles.certain]: 'high certainty; tend to answer with "Yes" or "No"',
    [roles.neither]: 'average certainty; tend to answer with "Unlikely" or "Likely"',
    [roles.uncertain]: 'low certainty; tend to answer with "Maybe"',
    Spy: 'This user has not answered any questions yet',
  }[term]);

export const getCertaintyThresholds = () => [40, 60];

export const getRoleName = user =>
  !!user && user.certainty !== null
    ? user.certainty >= getCertaintyThresholds()[1]
      ? roles.certain
      : user.certainty > getCertaintyThresholds()[0]
      ? roles.neither
      : roles.uncertain
    : 'Spy';

export const getProfile = (team, role) => {
  const teamProfile =
    team === teams.disagree
      ? [
          'Dissenters are individuals who embody the spirit of rebellion against consensus opinion. ' +
            'With a natural inclination towards skepticism, they challenge prevailing beliefs and ideologies.  ' +
            'Their refusal to conform to the status quo is a testament to their unwavering commitment to truth and principle. ' +
            'Dissenters thrive on intellectual discourse, engaging in thoughtful debates to provoke introspection and change. ' +
            'Their courage to dissent not only sparks innovation but also inspires others to question the norm. ' +
            'In a sea of conformity, the Dissenters stand as guardians of independent thought and dissenting voices.  ',
          'Dissenters tend to have biases against consensus beliefs, which can potentially blind them from seeing whether those beliefs are actually true.  ' +
            'Dissenters may benefit from exploring the evidence that supports consensus beliefs and scrutinizing the evidence that challenges them.',
        ]
      : team === teams.agree
      ? [
          'With a natural inclination towards harmony and unity, Concurrers find comfort in shared perspectives. ' +
            'Concurrers prioritize cooperation over conflict, seeking common ground to build bridges of understanding. ' +
            'Their willingness to embrace consensus facilitates smooth decision-making processes and fosters a sense of collective identity. ' +
            'In a world of diverse opinions, Concurrers serve as stabilizing forces, promoting unity and cooperation for the greater good.  ',
          'Their bias toward consensus beliefs can potentially blind Concurrers from seeing whether those beliefs are actually false.  ' +
            'Concurrers may benefit from exploring the evidence that challenges their beliefs and scrutinizing the evidence that supports them.',
        ]
      : team === teams.neither
      ? [
          'Eccentrics are a unique blend of independent thought and occasional conformity. ' +
            'With a dash of unpredictability and a sprinkle of nonconformity, they navigate the space between consensus and dissent with ease. ' +
            'Eccentrics possess a rare ability to embrace both the comfort of consensus and the thrill of dissent, depending on the situation. ' +
            'Their eclectic perspectives and unconventional ideas often challenge conventional wisdom while occasionally aligning with prevailing beliefs. ' +
            'Eccentrics bring a refreshing dynamism to discussions, infusing them with creativity and novelty. ' +
            'In a world of binaries, they carve out their own path, embracing the beauty of complexity and ambiguity.',
          "While an Eccentric's beliefs may alienate some, they can also find agreement in shared beliefs, building bridges of understanding and trust. " +
            'Their position gives them a unique opportunity to help others explore challenging evidence while still remaining relatable.',
        ]
      : '';

  switch (role) {
    case roles.certain:
      return teamProfile.concat(
        'A Soldier is firm in their beliefs, driven by conviction. Disciplined, loyal, and fiercely committed to their cause. Resolute in action, unwavering in resolve.',
      );
    case roles.neither:
      return teamProfile.concat(
        'Scouts are curious and perceptive, navigating with intuition. They embrace uncertainty, yet are guided by their truth.  Open-minded, venturing into the unknown with cautious optimism.',
      );
    case roles.uncertain:
      return teamProfile.concat(
        'Explorers embrace uncertainty, navigating the vast unknown with curiosity.  Every path a question, every discovery a revelation.',
      );
    default:
      return ['', '', ''];
  }
};
