import { Tooltip } from '@mui/material';
import { useTrustConfig } from '../../services/config';
import { useMe } from '../../services/users';
import { sameId } from '../../util/id';

const TrustLevel = ({ user }) => {
  const trustConfig = useTrustConfig();
  const me = useMe().data?.data;

  const trustLevels = trustConfig.data?.data?.trustLevels;

  if (!trustLevels || !user) return null;

  const startTrust = 0;
  const endTrust = trustLevels['' + (user.trustLevel + 1)]?.trustNeeded || user.trustEarned;
  const progress = Math.round((100 * (user.trustEarned - startTrust)) / (endTrust - startTrust));

  if (!sameId(me, user) || !user.trustEarned || endTrust <= startTrust) return null;
  else
    return (
      <Tooltip title={user.trustEarned ? `${user.trustEarned}/${endTrust} 📜` : ''}>
        <div className='border rounded-full bg-gray-200 w-full h-2'>
          <div
            className={`'border rounded-full h-2 bg-emerald-600`}
            style={{ width: progress + '%' }}
          />
        </div>
      </Tooltip>
    );
};

export default TrustLevel;
