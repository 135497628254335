import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ColorSection from './ColorSection';
import ButtonSection from './ButtonSection';
import TypographySection from './TypographySection';
import FormSection from './FormSection';

function StyleGuid() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 4 }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='Color Guide' {...a11yProps(0)} />
          <Tab label='Text Styles' {...a11yProps(1)} />
          <Tab label='Forms' {...a11yProps(2)} />
          <Tab label='Buttons' {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ColorSection />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TypographySection />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FormSection />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ButtonSection />
      </TabPanel>
    </Box>
  );
}
export default StyleGuid;
