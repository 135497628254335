import SectionTitle from './SectionTitle';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import boxShadows from 'assets/theme/base/boxShadows';
import Text from '@mui/material/DialogContentText';

const ColorSection = () => {
  const { md } = boxShadows;
  const theme = useTheme();
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    boxShadow: md,
    textAlign: 'center',
    height: 170,
    borderRadius: 10,
  }));

  const ColorBox = styled(Box)(() => ({
    height: 100,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  }));

  const ColorTitle = styled(Text)(() => ({
    fontSize: 13,
    color: theme.palette.text.regular,
    marginTop: 5,
    marginBottom: 5,
    fontFamily: theme.typography.fontFamily,
  }));
  const ColorValue = styled(Text)(() => ({
    fontSize: 13,
    color: theme.palette.text.unSelected,
    marginTop: 5,
    marginBottom: 5,
    fontFamily: theme.typography.fontFamily,
  }));

  const ColorCard = props => {
    const { color, title } = props;
    return (
      <Box gridColumn='span 4' style={{ marginTop: 20 }}>
        <Item>
          <ColorBox style={{ backgroundColor: color }} />
          <ColorTitle>{title}</ColorTitle>
          <ColorValue>{color.toUpperCase()}</ColorValue>
        </Item>
      </Box>
    );
  };

  return (
    <Box style={{ marginTop: 20 }}>
      <SectionTitle
        title='Color Guide'
        desc='Color is another essential element in styleguides. It helps establish visual hierarchies and create emotional resonance. For consistency, we use the below defined color palette throughout our interface.'
      />
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={2}>
        <ColorCard color={theme.palette.brand.default} title='brand Color' />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={2}>
        <ColorCard color={theme.palette.text.regular} title='Text-Regular' />
        <ColorCard color={theme.palette.text.unSelected} title='Text-Unselected' />
        <ColorCard color={theme.palette.text.highlighted} title='Text-Highlighted' />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={2}>
        <ColorCard color={theme.palette.background.default} title='BG-Global/Page' />
        <ColorCard color={theme.palette.backgroundCards.default} title='BG-Cards' />
      </Box>
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={2}>
        <ColorCard color={theme.palette.warning.main} title='In Progress / Warning' />
        <ColorCard color={theme.palette.success.main} title='Success' />
        <ColorCard color={theme.palette.error.main} title='Error' />
      </Box>
    </Box>
  );
};

export default ColorSection;
