import BodyEditor from 'components/BodyEditor';
import Container from '@mui/material/Container';
import Body from '../../components/Body';
import {
  useCreateNewsletter,
  useNewsletters,
  useUpdateNewsletter,
} from '../../services/newsletters';
import UiContext from '../../state/UiContext';
import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { usePrompt } from '../../hooks/react-router-dom-extension';

const AdminNewsletter = () => {
  const newsletters = useNewsletters().data?.data;
  const createNewsletter = useCreateNewsletter();
  const updateNewsletter = useUpdateNewsletter();
  const { toastSuccess, toastError } = useContext(UiContext);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const prompt = 'Discard unsaved changes?';
  usePrompt(prompt, unsavedChanges);

  function submit() {
    const options = {
      onSuccess: res => {
        toastSuccess(res.message);
        setIsUpdating(false);
        setBody('');
        setSubject('');
        setUnsavedChanges(false);
      },
      onError: res => toastError(res.message),
    };
    if (isUpdating) updateNewsletter.mutate({ body, subject }, options);
    else createNewsletter.mutate({ body, subject }, options);
  }

  function cancel() {
    if (unsavedChanges && !window.confirm(prompt)) return;

    setBody('');
    setIsUpdating(false);
    setUnsavedChanges(false);
  }

  return (
    <Container>
      <div>
        <input
          value={subject}
          placeholder={'Subject'}
          onChange={e => {
            setUnsavedChanges(true);
            setSubject(e.target.value);
          }}
        />
        <BodyEditor
          body={body}
          onChange={t => {
            setUnsavedChanges(true);
            setBody(t);
          }}
          autoFocus
        />
        <div className='flex'>
          <Button variant='outlined' onClick={submit}>
            Submit
          </Button>
          <Button variant='outlined' onClick={cancel}>
            Cancel
          </Button>
        </div>
      </div>

      {newsletters
        ?.filter((n, i) => !isUpdating || i > 0)
        .map((n, i) => (
          <Card key={i} sx={{ m: 3 }}>
            <p className='text-lg font-bold'>{n.subject || '[no subject]'}</p>
            <Body
              body={n.body}
              onClick={() => {
                setIsUpdating(true);
                setBody(n.body);
              }}
            />
            <Stack direction='row' spacing={3}>
              <Typography>Created</Typography>
              <Typography>{n.createdAt}</Typography>
              <p>Sends</p>
              <p>{n.sends}</p>
              {i === 0 && (
                <Button
                  onClick={() => {
                    setIsUpdating(true);
                    setBody(n.body);
                    setSubject(n.subject);
                  }}
                >
                  Update
                </Button>
              )}
            </Stack>
          </Card>
        ))}
    </Container>
  );
};

export default AdminNewsletter;
