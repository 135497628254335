import { useContext, useState } from 'react';
import UiContext from '../../state/UiContext';
import { Link as RouterLink } from 'react-router-dom';
import Username from '../../components/elements/Username';
import { useAdminTheories, usePublishTheory } from '../../services/theories';
import { useInfiniteScroll } from '../../util/dom';
import { flattenPages } from '../../util/query';

function AdminTheories() {
  const theoriesQuery = useAdminTheories({ status: 'active' });
  useInfiniteScroll(theoriesQuery);
  const theories = flattenPages(theoriesQuery.data?.pages);
  const publishTheory = usePublishTheory();
  const { toastSuccess, toastError } = useContext(UiContext);
  const [theoryToPublish, setTheoryToPublish] = useState(false);

  function onClickConfirm() {
    publishTheory.mutate(theoryToPublish.id, {
      onSuccess: res => toastSuccess(res.message),
      onError: res => toastError(res.message),
    });
  }

  return (
    <div>
      {theories?.map(t => (
        <div className='mt-4 text-sm' key={t.id}>
          <div className='flex space-x-2'>
            <RouterLink to={'/inquiry/' + t?.slug}>{t.title}</RouterLink>
            <Username username={t.user.username} />
          </div>
          <div className='flex space-x-2'>
            <p>Rank: {t.rank}</p>
            <p>Subs: {t.emailSubscriptions}</p>
            <p>Evdc: {t.evidenceCount}</p>
            <p>Rbts: {t.rebuttalCount}</p>
            <p>Cmts: {t.commentCount}</p>
          </div>
          {theoryToPublish === t ? (
            <div className='flex space-x-5'>
              <button onClick={() => setTheoryToPublish(false)}>cancel</button>
              <button onClick={onClickConfirm}>confirm</button>
            </div>
          ) : (
            <button className='p-2 border border-primary' onClick={() => setTheoryToPublish(t)}>
              Publish
            </button>
          )}
        </div>
      ))}
    </div>
  );
}

export default AdminTheories;
