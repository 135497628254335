import { useSearch } from '../../services/search';
import { useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import EvidenceGrid from '../Cards/EvidenceGrid';
import TheoryGrid from '../Cards/TheoryGrid';
import { useState } from 'react';
import { useGetTheory } from '../../services/theories';
import CloseIcon from '@mui/icons-material/Close';

const SearchResultSection = ({ children, hasResults }) => (
  <div className='mt-4 mb-12'>
    {children}

    {!hasResults && <p className='text-lg font-bold'>No results</p>}
  </div>
);

const tabs = [
  { contentType: 'theory', label: 'Inquiries' },
  { contentType: 'evidence', label: 'Evidence' },
  { contentType: 'evidence', label: 'Rebuttals' },
];

const SearchResults = () => {
  const [params, setSearchParams] = useSearchParams();
  const theory = useGetTheory(params.get('theoryId'));
  const [tab, setTab] = useState(
    tabs.find(ct => ct.contentType === params.get('contentType')) || tabs[0],
  );
  const searchQuery = useSearch({
    searchString: params.get('searchString') || '',
    contentType: tab.contentType || '',
    theoryId: params.get('theoryId') || '',
  });
  const searchResults = searchQuery.data?.data;

  const evidence = searchResults?.evidence?.filter(e => !e.parent);
  const rebuttals = searchResults?.evidence?.filter(e => e.parent);

  const removeTheory = () => {
    params.delete('theoryId');
    setSearchParams(params);
  };

  const shownTabs = tabs.filter(t => !params.get('theoryId') || t.contentType === 'evidence');

  if (searchQuery.isLoading) return <Typography>Searching...</Typography>;
  else
    return (
      <div className='mx-6' style={{ maxWidth: 1200 }}>
        {!!theory.data?.data && (
          <div className='flex space-x-2 items-start'>
            <p>Results from "{theory.data.data.title}" </p>
            <div className='cursor-pointer' onClick={removeTheory}>
              <CloseIcon />
            </div>
          </div>
        )}
        {
          <div className='text-xs md:text-base font-bold flex space-x-6 mt-4 border-b'>
            {shownTabs.map(t => (
              <button
                className={
                  'cursor-pointer rounded-t-xl border p-2 md:p-3 ' +
                  (t.label === tab.label ? 'bg-slate-400 text-white' : '')
                }
                onClick={() => setTab(t)}
                key={t.label}
              >
                {t.label}
              </button>
            ))}
          </div>
        }

        {tab.label === 'Inquiries' && (
          <SearchResultSection hasResults={searchResults?.theory?.length}>
            <TheoryGrid theories={searchResults?.theory} isFetching={searchQuery.isFetching} />
          </SearchResultSection>
        )}

        {tab.label === 'Evidence' && (
          <SearchResultSection hasResults={evidence?.length}>
            <EvidenceGrid evidences={evidence} isFetching={searchQuery.isFetching} />
          </SearchResultSection>
        )}

        {tab.label === 'Rebuttals' && (
          <SearchResultSection hasResults={rebuttals?.length}>
            <EvidenceGrid evidences={rebuttals} isFetching={searchQuery.isFetching} />
          </SearchResultSection>
        )}
      </div>
    );
};

export default SearchResults;
