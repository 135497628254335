import { fetchClient } from 'services/api';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { staleTimes } from './query-settings';
import { useMemo } from 'react';
import id from '../util/id';

const apiClient = fetchClient();

export const keys = {
  theory: theoryId => ['clusters', { theoryId }],
};

const getClusters = async theoryId =>
  await apiClient.get('/cluster/?theory=' + theoryId).then(res => {
    return res.data;
  });

export const useClusters = theoryId =>
  useQuery(keys.theory(theoryId), () => getClusters(theoryId), {
    enabled: !!theoryId,
    staleTime: staleTimes.long,
  });

export const useIsDuplicate = (theoryId, evidenceId) => {
  const clusters = useClusters(theoryId);
  return useMemo(
    () => clusters.data?.data?.some(c => c.members.includes(evidenceId) && c.members.length > 1),
    [clusters, evidenceId],
  );
};

const clusterEvidence = async ({ evidence1id, evidence2id }) =>
  await apiClient.post('/cluster', { evidence1id, evidence2id }).then(res => res.data);

export const useClusterEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ evidence1, evidence2 }) =>
      clusterEvidence({ evidence1id: evidence1.id, evidence2id: evidence2.id }),
    {
      onSuccess: (data, variables) =>
        queryClient.setQueryData(keys.theory(id(variables.evidence1.theory)), data),
    },
  );
};

export const unclusterEvidence = async ({ evidenceId }) =>
  await apiClient.patch('/cluster/' + evidenceId).then(res => res.data);

export const useUnclusterEvidence = () => {
  const queryClient = useQueryClient();
  return useMutation(({ evidence }) => unclusterEvidence({ evidenceId: evidence.id }), {
    onSuccess: (data, variables) =>
      queryClient.setQueryData(keys.theory(id(variables.evidence.theory)), data),
  });
};

const getClusteredEvidence = async clusterId =>
  await apiClient.get('/cluster-evidence/' + clusterId).then(res => res.data);

export const useClusteredEvidence = clusterId => {
  return useQuery(['cluster-evidence', clusterId], () => getClusteredEvidence(clusterId), {
    enabled: !!clusterId,
  });
};
