import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { globalReplace } from 'util/strings';

const domainPaths = ['https://evincer.org', 'https://www.evincer.org'];

const useLocalLinksInterceptor = ({ wrapper }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const interceptLinks = useCallback(() => {
    if (!wrapper.current) return;
    const links = [...wrapper.current.querySelectorAll('a')];

    const listeners = links.map(link => {
      const onClick = e => {
        let href = link.getAttribute('href');
        if (href.startsWith('/') || href.includes('evincer.org')) {
          e.preventDefault();
          domainPaths.forEach(path => {
            href = globalReplace({
              text: href,
              search: path,
              replace: '',
            });
          });

          navigate(href);
        }
      };
      link.addEventListener('click', onClick);
      return { link, onClick };
    });
    return () =>
      listeners.forEach(listener => listener.link.removeEventListener('click', listener.onClick));
  }, [navigate, wrapper]);

  useEffect(() => {
    let cleanup;

    const i = setInterval(() => {
      if (wrapper.current) {
        cleanup = interceptLinks();
        clearInterval(i);
      }
    }, 500);

    return cleanup;
  }, [wrapper, interceptLinks, location]);

  return {
    interceptLinks,
  };
};

export default useLocalLinksInterceptor;
