import { fetchClient } from 'services/api';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

const apiClient = fetchClient();

const keys = {
  subject: id => ['comments', { id }], // we're assuming BSONs will not collide
  user: 'user-comments',
};

const createRemap = (commentId, fn) => oldComments =>
  oldComments === undefined
    ? undefined
    : {
        pages: oldComments.pages.map(page => ({
          ...page,
          data: page.data.map(comment => (comment.id === commentId ? fn(comment) : comment)),
        })),
      };

const get = async ({ subjectType, subjectId, pageParam }) =>
  await apiClient
    .get(
      '/comment/?page=' +
        (pageParam || 1) +
        (subjectType ? '&subjectType=' + subjectType : '') +
        (subjectId ? '&subjectId=' + subjectId : ''),
    )
    .then(res => {
      return res.data;
    });

export const useGetComments = ({ subjectType, subjectId }, options = {}) => {
  return useInfiniteQuery(
    subjectId ? keys.subject(subjectId) : keys.user,
    ({ pageParam }) => get({ subjectType, subjectId, pageParam }),
    {
      getNextPageParam: lastPage => lastPage.meta.nextPage || undefined,
      ...options,
    },
  );
};

const createComment = async comment =>
  await apiClient.post('/comment', comment).then(res => res.data);

export const useCreateComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ subjectType, subjectId, body }) => createComment({ subjectType, subjectId, body }),
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(keys.subject(variables.subjectId), oldComments => ({
          pages: oldComments.pages.map((page, i) => ({
            ...page,
            data: [...(i === 0 ? [data.data] : []), ...page.data],
          })),
        }));
        queryClient.invalidateQueries(['throttle', 'comment']);
      },
    },
  );
};

const respond = async (id, body) =>
  await apiClient.post('/comment/' + id, { body }).then(res => res.data);

export const useRespond = () => {
  const queryClient = useQueryClient();
  return useMutation(({ subjectId, id, body }) => respond(id, body), {
    onSuccess: (data, variables) => {
      const responseRemap = createRemap(variables.id, comment => ({
        ...comment,
        responses: [...comment.responses, data.data],
      }));
      queryClient.setQueryData(keys.subject(variables.subjectId), responseRemap);
      queryClient.setQueryData(keys.user, responseRemap);
      queryClient.invalidateQueries(['throttle', 'comment']);
    },
  });
};

const resolve = async id =>
  await apiClient.patch('/comment/' + id + '/resolve').then(res => res.data);

export const useResolve = () => {
  const queryClient = useQueryClient();
  return useMutation(({ id }) => resolve(id), {
    onSuccess: (data, variables) => {
      const resolveRemap = createRemap(variables.id, comment => ({ ...comment, ...data.data }));
      queryClient.setQueryData(keys.user, resolveRemap);
      queryClient.setQueryData(keys.subject(variables.subjectId), resolveRemap);
    },
  });
};

const markRead = async id =>
  await apiClient.patch('/comment/' + id + '/read').then(res => res.data);

export const useMarkRead = () => {
  const queryClient = useQueryClient();
  return useMutation(({ subjectId, id }) => markRead(id), {
    onSuccess: (data, variables) => {
      const readRemap = createRemap(variables.id, comment => ({ ...comment, read: true }));
      queryClient.setQueryData(keys.user, readRemap);
      queryClient.setQueryData(keys.subject(variables.subjectId), readRemap);
    },
  });
};

const deleteComment = async id => await apiClient.delete('/comment/' + id).then(res => res.data);

export const useDeleteComment = () => {
  const queryClient = useQueryClient();
  return useMutation(({ subjectId, commentId }) => deleteComment(commentId), {
    onSuccess: (data, variables) => {
      [keys.subject(variables.subjectId), keys.user].forEach(
        key =>
          queryClient.getQueryData(key) &&
          queryClient.setQueryData(key, oldComments => ({
            pages: oldComments.pages.map(page => ({
              ...page,
              data: page.data.filter(comment => comment.id !== variables.commentId),
            })),
          })),
      );
    },
  });
};

const deleteResponse = async id =>
  await apiClient.delete('comment/response/' + id).then(res => res.data);

export const useDeleteResponse = () => {
  const queryClient = useQueryClient();
  return useMutation(({ subjectId, responseId }) => deleteResponse(responseId), {
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries(keys.subject(variables.subjectId)),
  });
};
