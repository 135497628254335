import DefaultProfileImage from 'assets/images/UserProfile/defaultProfileImage.png';

export const avatar = user => {
  if (!user?.id) return DefaultProfileImage;
  if (user.profileImage?.thumbnail?.url) return user.profileImage.thumbnail.url;

  const id = user.id;
  const hex2dec = c =>
    c.charCodeAt(0) >= 'a'.charCodeAt(0)
      ? c.charCodeAt(0) - 'a'.charCodeAt(0) + 10
      : c.charCodeAt(0) - '0'.charCodeAt(0);
  const basis = id ? hex2dec(id.charAt(id.length - 3)) * hex2dec(id.charAt(id.length - 2)) : 0;
  const n = (basis % 64) + 1;
  return require('assets/images/avatars/avatar' + n + '.png');
};
