import markdown from 'util/markdown';
import useLocalLinksInterceptor from 'hooks/use-local-links-interceptor';
import { useRef } from 'react';

const Body = ({ body, clamped }) => {
  const wrapper = useRef();
  useLocalLinksInterceptor({ wrapper });

  if (!body) return null;
  return (
    <div
      ref={wrapper}
      className={'markup-body break-word select-text ' + (clamped ? 'clamped' : '')}
      dangerouslySetInnerHTML={{
        __html: markdown(body),
      }}
    />
  );
};

export default Body;
