import { useContext, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTags, useCreateTag, useDeleteTag, useUpdateTag } from 'services/tags';
import UiContext from '../../state/UiContext';
import ui from '../../config/ui';
import TheoryImg1 from 'assets/images/Theory/1.png';

export default function AdminTags() {
  const [newTag, setNewTag] = useState('');
  const { toastSuccess, toastError } = useContext(UiContext);

  const tags = useTags().data;
  const updateTag = useUpdateTag();

  const createMutation = useCreateTag();
  const deleteMutation = useDeleteTag();

  function onSubmitTag() {
    if (newTag)
      createMutation.mutate(
        { title: newTag },
        {
          onSuccess: res => {
            toastSuccess(res.message);
            setNewTag('');
          },
          onError: res => {
            toastError(res.message);
          },
        },
      );
  }

  function onDeleteTag(id) {
    return () =>
      deleteMutation.mutate(
        { id: id },
        {
          onSuccess: () => {
            toastSuccess('Tag deleted');
          },
          onError: data => {
            toastError(data.message);
          },
        },
      );
  }

  function onClickImage(id) {
    return () => document.getElementById(`image${id}`).click();
  }

  function onChangeImage(id) {
    return e =>
      updateTag.mutate({ id, image: e.target.files[0] }, { onError: e => toastError(e.message) });
  }

  return (
    <div className='relative w-[500px] flex flex-col rounded-xl bg-white shadow-xl'>
      <div className={'p-2 space-y-4'}>
        {tags?.data?.map(tag => (
          <div className='flex flex-row items-center space-x-4' key={tag.slug}>
            <p>{tag.title}</p>

            <input
              id={`image${tag.id}`}
              accept='image/*'
              className='imageInput'
              type='file'
              hidden
              onChange={onChangeImage(tag.id)}
            />
            <img
              className='h-28 cursor-pointer'
              onClick={onClickImage(tag.id)}
              alt='tag'
              src={tag.image?.url || TheoryImg1}
            />
            <button onClick={onDeleteTag(tag.id)}>
              <DeleteForeverIcon />
            </button>
          </div>
        ))}
      </div>

      <div className='p-4'>
        <input
          className='p-1 w-full'
          placeholder='Tag'
          maxLength={ui.tagLength}
          value={newTag}
          onChange={e => setNewTag(e.target.value)}
        />
      </div>

      <div className='p-4'>
        <button type='submit' className='p-2 border' onClick={onSubmitTag}>
          Create Tag
        </button>
      </div>
    </div>
  );
}
