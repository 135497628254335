import classnames from 'classnames';
import markdown from '../../util/markdown';
import { useEffect, useRef, useState } from 'react';

const CommentBody = ({ subject, clamped, onClick, onClickReadMore }) => {
  const bodyDiv = useRef();
  const [hidingText, setHidingText] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries)
        setHidingText(clamped && entry.target.scrollHeight > entry.target.clientHeight);
    });

    const element = bodyDiv.current;
    if (element) observer.observe(element);

    return () => element && observer.unobserve(element);
  }, [clamped, bodyDiv, setHidingText]);

  if (!subject) return null;
  return (
    <div>
      <div
        ref={bodyDiv}
        className={classnames(
          'break-word markup-body',
          { 'line-clamp-4': clamped },
          { 'cursor-pointer': clamped && !!onClick },
        )}
        onClick={onClick}
        dangerouslySetInnerHTML={{
          __html: markdown(subject.body),
        }}
      />
      {hidingText && (
        <p className='my-1 text-sm text-unselected cursor-pointer' onClick={onClickReadMore}>
          Read more
        </p>
      )}
    </div>
  );
};

export default CommentBody;
