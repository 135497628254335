import Heading from '../components/Banners/Heading';
import BannerBackground2 from '../assets/images/background2.png';
import Container from '@mui/material/Container';
import { Typography, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useGetToken } from '../services/auth';
import UiContext from '../state/UiContext';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const Invite = () => {
  const theme = useTheme();
  const [email, setEmail] = useState('');
  const getToken = useGetToken();
  const { toastSuccess, toastError } = useContext(UiContext);

  function send() {
    getToken.mutate(
      { email, invite: true },
      {
        onSuccess: () => {
          setEmail('');
          toastSuccess('Invitation sent');
        },
        onError: res => toastError(res.message),
      },
    );
  }

  return (
    <Container>
      <Stack spacing={2}>
        <Heading text='Invite' backgroundImg={BannerBackground2} />
        <Typography style={theme.typography.h4}>
          Is a friend interested in Evincer? You can use this page to invite them.
        </Typography>
        <Typography style={theme.typography.h4}>
          They will receive an email with a link which creates their account and logs them in.
        </Typography>

        <TextField
          value={email}
          onChange={e => setEmail(e.target.value)}
          onKeyUp={e => e.key === 'Enter' && send()}
          placeholder='Email'
          inputProps={{
            'aria-label': 'weight',
          }}
          helperText='Please ensure you have permission from the person you are inviting'
        />

        <Button variant='contained' onClick={send}>
          Send
        </Button>
      </Stack>
    </Container>
  );
};

export default Invite;
