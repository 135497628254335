import * as React from 'react';
import Box from '@mui/material/Box';
import SectionTitle from './SectionTitle';
import { useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

const FormSection = () => {
  const theme = useTheme();
  const TypographyItem = ({ text, typoStyle }) => {
    return (
      <p
        style={{
          ...typoStyle,
          marginTop: 25,
          marginBottom: 25,
          color: theme.palette.titleText.main,
        }}
      >
        {text}
      </p>
    );
  };
  return (
    <Box style={{ marginTop: 20 }}>
      <SectionTitle title='Form Elements' desc='' />
      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' gap={4}>
        <Box gridColumn='span 4'>
          <TypographyItem text='Default' typoStyle={theme.typography.h1} />
          <TextField
            id='standard-adornment-weight'
            value=''
            aria-describedby='standard-weight-helper-text'
            placeholder='Theory Title'
            inputProps={{
              'aria-label': 'weight',
            }}
            helperText='Text instruction goes here'
          />
        </Box>
        <Box gridColumn='span 4'>
          <TypographyItem text='On Focus' typoStyle={theme.typography.h1} />
          <TextField
            id='standard-adornment-weight'
            aria-describedby='standard-weight-helper-text'
            placeholder='Theory Title'
            inputProps={{
              'aria-label': 'weight',
            }}
            focused
            helperText='Text instruction goes here'
          />
        </Box>
        <Box gridColumn='span 4'>
          <TypographyItem text='Error' typoStyle={theme.typography.h1} />
          <TextField
            error
            id='standard-error-helper-text'
            helperText='Incorrect entry.'
            placeholder='Theory Title'
            // variant="standard"
          />
        </Box>
      </Box>

      <Box display='grid' gridTemplateColumns='repeat(12, 1fr)' style={{ marginTop: 40 }} gap={4}>
        <Box gridColumn='span 4'>
          <TextField
            id='standard-adornment-weight'
            defaultValue='A theory about adventures'
            aria-describedby='standard-weight-helper-text'
            placeholder='Theory Title'
            inputProps={{
              'aria-label': 'weight',
            }}
            helperText='Text instruction goes here'
          />
        </Box>
        <Box gridColumn='span 4'>
          <TextField
            id='standard-adornment-weight'
            defaultValue='A theory about adventures'
            aria-describedby='standard-weight-helper-text'
            placeholder='Theory Title'
            inputProps={{
              'aria-label': 'weight',
            }}
            focused
            helperText='Text instruction goes here'
          />
        </Box>
        <Box gridColumn='span 4'>
          <TextField
            error
            defaultValue='A theory about adventures'
            id='standard-error-helper-text'
            helperText='Incorrect entry.'
            placeholder='Theory Title'
            // variant="standard"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FormSection;
