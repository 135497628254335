import validator from 'validator';

export const validateUsername = name => {
  if (!name.match(/^@?[A-Za-z0-9-]+$/))
    // same regex in back-end
    return 'Username may contain letters, numbers, and "-".';
  else return '';
};

export const validateDisplayName = name => {
  if (name.match(/[^A-Za-z0-9-& ]/))
    // same regex in back-end
    return 'Display Name may contain letters, numbers, spaces, "&" and "-".';
  else return '';
};

export const validateEmail = email => {
  if (!validator.isEmail(email)) return 'Invalid email';
  else return '';
};
