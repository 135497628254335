import React from 'react';
import Box from '@mui/material/Box';

import Conversations from 'components/Conversations';
import Heading from '../components/Banners/Heading';
import BannerBackground from 'assets/images/background2.png';
import HeadTags from '../components/HeadTags';

const Messages = () => (
  <Box>
    <HeadTags title='Messages' />
    <Heading text='Messages' backgroundImg={BannerBackground} />
    <Box marginTop={2} padding={2}>
      <Conversations />
    </Box>
  </Box>
);

export default Messages;
