import { staleTimes } from './query-settings';
import { fetchClient } from './api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useMe } from './users';
import { userQueryKeys } from './users';
import { useCallback, useContext, useEffect, useState } from 'react';
import hash from 'hash.js';
import AuthContext from '../state/AuthContext';
import { useJwt } from './auth';
import _ from 'lodash';

const apiClient = fetchClient();

const keys = {
  all: includeStopped => ['ab-tests', includeStopped],
  results: (slug, selectiveEnrollment) => ['ab-test-results', slug, selectiveEnrollment],
};

const getAbTests = async includeStopped =>
  await apiClient.get('/ab-test/?includeStopped=' + includeStopped).then(res => res.data);

export const useAbTests = (includeStopped = false) => {
  const [jwt] = useJwt();
  return useQuery(keys.all(includeStopped), () => getAbTests(includeStopped), {
    enabled: !!jwt,
    staleTime: staleTimes.long,
  });
};

const createAbTest = async ({ slug, description }) =>
  await apiClient.post('/ab-test', { slug, description });

export const useCreateAbTest = () => {
  const queryClient = useQueryClient();
  return useMutation(createAbTest, {
    onSuccess: () => queryClient.invalidateQueries(['ab-tests']),
  });
};

const startAbTest = async slug => await apiClient.patch('/ab-test/' + slug + '/start');

export const useStartAbTest = () => {
  const queryClient = useQueryClient();
  return useMutation(startAbTest, { onSuccess: () => queryClient.invalidateQueries(['ab-tests']) });
};

const stopAbTest = async slug => await apiClient.patch('/ab-test/' + slug + '/stop');

export const useStopAbTest = () => {
  const queryClient = useQueryClient();
  return useMutation(stopAbTest, { onSuccess: () => queryClient.invalidateQueries(['ab-tests']) });
};

const resetAbTest = async slug => await apiClient.patch('/ab-test/' + slug + '/reset');

export const useResetAbTest = () => {
  const queryClient = useQueryClient();
  return useMutation(resetAbTest, {
    onSuccess: () => {
      queryClient.invalidateQueries(['ab-tests']);
      queryClient.invalidateQueries(['ab-test-results']);
    },
  });
};

const getAbTestStats = async (slug, denominator, selectiveEnrollment) =>
  await apiClient
    .get(
      '/ab-test/' +
        slug +
        '?denominator=' +
        denominator +
        '&selectiveEnrollment=' +
        !!selectiveEnrollment,
    )
    .then(res => res.data);

export const useAbTestStats = (slug, denominator, selectiveEnrollment) =>
  useQuery(
    keys.results(slug, denominator, selectiveEnrollment),
    () => getAbTestStats(slug, denominator, selectiveEnrollment),
    {
      enabled: !!slug,
      staleTime: staleTimes.medium,
    },
  );

export const calculateSignificance = (result, population) => {
  const a = population.test - result.test.score;
  const b = result.test.score;
  const c = population.control - result.control.score;
  const d = result.control.score;

  const denom = (a + b) * (c + d) * (b + d) * (a + c);
  const chiSquared = denom ? (Math.pow(a * d - b * c, 2) * (a + b + c + d)) / denom : 0;

  const lookupTable = [
    [0, 0],
    [0.455, 0.5],
    [2.706, 0.9],
    [3.841, 0.95],
    [5.412, 0.98],
    [6.635, 0.99],
    [10.827, 0.999],
  ];
  return lookupTable.reduce((prob, row) => (chiSquared > row[0] ? row[1] : prob), 0);
};

const enroll = async slug => await apiClient.post('/ab-test/' + slug + '/enroll');

export const useEnroll = () => {
  const queryClient = useQueryClient();
  return useMutation(enroll, {
    onMutate: slug => {
      if (queryClient.getQueryData(userQueryKeys.me))
        queryClient.setQueryData(userQueryKeys.me, m => {
          // use deterministic hash(id + slug) to determine which test group we're in
          const idslug = hash
            .sha1()
            .update(m.data.id + slug)
            .digest('hex');
          const hashInteger = parseInt(idslug.substring(idslug.length - 1), 16);
          const field = hashInteger % 2 ? 'abTests' : 'abControls';

          return {
            ...m,
            data: {
              ...m.data,
              ...(m.data[field]?.includes(slug)
                ? undefined
                : { [field]: [...(m.data[field] || []), slug] }),
            },
          };
        });
    },
  });
};

export const useIsAbTest = (slug, enabled = true) => {
  const me = useMe().data?.data;
  const abTests = useAbTests().data?.data;
  const { abEnroll } = useContext(AuthContext);
  const [isInTestGroup, setIsInTestGroup] = useState(undefined);

  useEffect(() => {
    if (!me || !abTests || !abTests.length) return;

    if (me.abTests?.includes(slug)) {
      setIsInTestGroup(true);
      return;
    }

    if (me.abControls?.includes(slug)) {
      setIsInTestGroup(false);
      return;
    }

    if (
      !abTests.some(
        t => t.slug === slug && Date.parse(t.started) < Date.parse(me.createdAt) && !t.stopped,
      )
    ) {
      setIsInTestGroup(false);
      return;
    }

    if (enabled) abEnroll(slug);
  }, [me, abTests, abEnroll, slug, enabled]);

  return isInTestGroup;
};

export const useTryAbTest = () => {
  const queryClient = useQueryClient();
  return useCallback(
    slug =>
      queryClient.setQueryData(userQueryKeys.me, me => ({
        ...me,
        data: {
          ...me.data,
          abTests: me.data?.abTests?.includes(slug)
            ? _.without(me.data.abTests, slug)
            : [...(me?.data?.abTests || []), slug],
        },
      })),
    [queryClient],
  );
};
