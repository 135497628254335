/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 PRO React Base Styles
import typography from 'assets/theme/base/typography';
import borders from 'assets/theme/base/borders';

// Material Kit 2 PRO React Helper Functions
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';

const { size } = typography;
const { borderRadius } = borders;
const { transparent, brand } = colors;

const root = {
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: size.sm,
  borderRadius: borderRadius.lg,
  padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
  lineHeight: 1.4,
  textAlign: 'center',
  userSelect: 'none',
  backgroundSize: '150% !important',
  backgroundPositionX: '25% !important',
  transition: 'all 150ms ease-in',
  textTransform: 'inherit',
  fontWeight: 500,
  width: 106,
  height: 51,
  '&:not(:first-of-type)': {
    backgroundColor: transparent.main,
    borderColor: brand.default,
    color: brand.default,
  },
  '&:first-of-type': {
    backgroundColor: brand.default,
    color: 'white',
  },
  '&:disabled': {
    pointerEvent: 'none',
    opacity: 0.65,
  },
  '&:hover': {
    backgroundColor: `${brand.default} !important`,
    color: 'white',
  },

  '& .material-icons': {
    fontSize: pxToRem(15),
    marginTop: pxToRem(-2),
  },
};

export default root;
