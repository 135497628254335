import CreatedAt from '../elements/CreatedAt';
import { Link as RouterLink } from 'react-router-dom';
import { track } from '../../services/analytics';
import DefaultTheoryImage from '../../assets/images/Theory/theory.png';
import { Skeleton } from '@mui/material';
import React, { useContext } from 'react';
import classnames from 'classnames';
import SignInModal from '../SignIn/SignInModal';
import { useEvidenceSubscription } from '../../services/subscription';
import UiContext from '../../state/UiContext';
import Status from '../Evidence/Status';
import RankPanel from '../Rank/RankPanel';
import { NoIcon, YesIcon } from '../Evidence/YesNoIcon';
import EvidenceStats from './EvidenceStats';
import { FlameSquareIcon } from '../icons';
import { useIsAbTest } from '../../services/ab-tests';
import { evidencePath } from '../../util/id';

const EvidenceCard = ({ evidence, theory, date, rankable }) => {
  const { mdPlus } = useContext(UiContext);
  const testNoSubscribeOnCards = useIsAbTest('no-subscribe-on-cards');

  const { subscribed, toggleSubscribe, promptForCredentials, setPromptForCredentials } =
    useEvidenceSubscription({ evidence });

  function onClick() {
    track(evidence.parent ? 'click-rebuttal' : 'click-evidence', { evidence });
  }

  const href = evidencePath(evidence);

  return (
    <div className='flex flex-row items-start space-x-3'>
      {evidence ? (
        <div className='relative shrink-0'>
          <RouterLink to={href} onClick={onClick}>
            <img
              className='object-cover rounded-lg h-24 w-24 md:h-40 md:w-40'
              src={theory?.image?.thumbnail?.url || DefaultTheoryImage}
              alt='Inquiry'
            />
          </RouterLink>

          {rankable && (
            <div className='absolute rounded bg-white bg-opacity-90 bottom-2 left-3 right-3 md:left-6 md:right-6 h-6 md:h-9 text-xs md:text-base'>
              <RankPanel
                place={evidence.place}
                subject={evidence}
                subjectKey='evidence'
                wFull={true}
              />
            </div>
          )}
        </div>
      ) : (
        <Skeleton variant='rounded' height={96} width={96} />
      )}

      <div className='w-full flex flex-col' style={{ minHeight: mdPlus ? 160 : 96 }}>
        {evidence ? (
          <div className='flex flex-row items-center text-2xs md:text-sm justify-between'>
            <CreatedAt createdAt={date} />
            <Status evidence={evidence} />
            {!testNoSubscribeOnCards && (
              <button /* Subscribe */
                className={classnames(
                  'px-1 rounded border shadow',
                  subscribed
                    ? 'bg-white border-primary text-primary'
                    : 'bg-primary text-white border-white px-3',
                )}
                onClick={() => {
                  toggleSubscribe();
                }}
              >
                {subscribed ? 'Unsubscribe' : 'Subscribe'}
              </button>
            )}
            <SignInModal
              open={promptForCredentials}
              title='Enter your email to subscribe to this evidence'
              onClose={() => setPromptForCredentials(false)}
              context='evidence card'
            />
          </div>
        ) : (
          <Skeleton height={50} />
        )}
        {theory ? (
          <p className='mt-1 text-xs md:text-base 2xl:text-lg text-unselected text-pretty'>
            {theory.title}
          </p>
        ) : (
          <Skeleton height={50} />
        )}
        {evidence ? (
          <RouterLink to={href} onClick={onClick}>
            <p
              className={classnames(
                'my-1 text-sm md:text-lg 2xl:text-xl font-semibold text-pretty',
                evidence.read ? 'text-slate-600' : 'text-slate-900',
              )}
            >
              <span className={'align-middle text-2xs md:text-xs mt-[6px] md:mt-[7px]'}>
                {!!evidence.parent ? (
                  <FlameSquareIcon className='inline mb-1' />
                ) : evidence.for ? (
                  <YesIcon />
                ) : (
                  <NoIcon />
                )}
              </span>{' '}
              {evidence.title}
            </p>
          </RouterLink>
        ) : (
          <Skeleton height={50} />
        )}

        {evidence ? (
          <div className='mt-auto'>
            <EvidenceStats evidence={evidence} />
          </div>
        ) : (
          <Skeleton height={60} />
        )}
      </div>
    </div>
  );
};

export default EvidenceCard;
