import { fetchClient } from 'services/api';
import { useQuery } from 'react-query';
import { staleTimes } from './query-settings';

const apiClient = fetchClient();

export const search = ({ searchString, contentType, theoryId }) =>
  apiClient.post('/search/', { searchString, contentType, theoryId }).then(res => res.data);

export const useSearch = params =>
  useQuery(['search', params], () => search(params), {
    enabled: !!params.searchString,
    staleTime: staleTimes.short,
  });

export const userSearch = ({ searchString }) =>
  apiClient.post('/user-search/', { searchString }).then(res => res.data);

export const useUserSearch = params =>
  useQuery(['userSearch', params], () => userSearch(params), {
    enabled: !!params.searchString,
  });

const duplicateSearch = ({ citation, link, isFor, theory, parent }) =>
  apiClient
    .post('/search/evidence-duplicates', { citation, link, isFor, theory, parent })
    .then(res => res.data);

export const useDuplicateEvidenceSearch = ({ citation, link, isFor, theory, parent }, options) =>
  useQuery(
    ['duplicateSearch', citation, link, isFor, theory, parent],
    () => duplicateSearch({ citation, link, isFor, theory, parent }),
    { staleTime: staleTimes.medium, refetchOnWindowFocus: false, ...options },
  );
