import SlideDownModal from './wrappers/SlideDownModal';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { useContext, useState } from 'react';
import UiContext from '../state/UiContext';
import { useUpdateMe } from '../services/users';
import { validateUsername } from '../services/validators';
import ValidationError from './ValidationError';
import UsernameInput from './elements/UsernameInput';

const UsernameModal = ({ open, title, onClose, onSuccess }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const [username, setUsername] = useState('');
  const [validationError, setValidationError] = useState('');
  const updateMe = useUpdateMe();

  function onSubmit() {
    if (validationError) {
      toastError(validationError);
      return;
    }

    updateMe.mutate(
      { username: username },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          onSuccess && onSuccess(res.data);
        },
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }

  function onChange(e) {
    const name = e.target.value;
    setValidationError(validateUsername(name));

    setUsername(name);
  }

  return (
    <SlideDownModal open={!!open} title={title} onClose={onClose}>
      <Divider sx={{ my: 0 }} />

      <div className='p-2 mx-2'>
        <UsernameInput
          username={username}
          onChange={onChange}
          className='w-full p-2 text-lg border rounded'
          onEnter={onSubmit}
        />

        <ValidationError error={validationError} />
      </div>

      <Divider sx={{ my: 0 }} />

      <Stack direction='row' m={2} justifyContent='center' px={10}>
        <button
          className='px-5 py-2.5 font-medium rounded-lg text-sm text-white bg-gradient-to-r from-primary to-blue-700'
          onClick={onSubmit}
        >
          Submit
        </button>
      </Stack>
    </SlideDownModal>
  );
};

export default UsernameModal;
