import { Tooltip } from '@mui/material';
import React from 'react';

const Stat = ({ title, Icon, iconClassName, number }) => (
  <Tooltip title={title} enterDelay={500}>
    <div className='flex flex-row space-x-1 items-end'>
      <span className={'text-sm md:text-base text-primary ' + iconClassName}>
        {typeof Icon === 'string' ? Icon : <Icon fontSize='inherit' color='inherit' />}
      </span>
      <p className='ml-1 text-unselected mb-0.5'>{number}</p>
    </div>
  </Tooltip>
);

export default Stat;
