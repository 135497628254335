import UsernameCreatedAt from '../elements/UsernameCreatedAt';
import classnames from 'classnames';
import { sameId } from '../../util/id';
import AuthContext from '../../state/AuthContext';
import { useContext, useState } from 'react';
import { useDeleteResponse } from '../../services/comments';
import UiContext from '../../state/UiContext';
import UserAvatar from '../elements/UserAvatar';
import CommentBody from './CommentBody';

const CommentResponse = ({ subject, response, highlighted }) => {
  const [showToolbar, setShowToolbar] = useState(false);
  const { me, isAdmin } = useContext(AuthContext);
  const { toastError } = useContext(UiContext);
  const isCommenter = sameId(me, response.user);
  const deleteResponse = useDeleteResponse();
  const [clamped, setClamped] = useState(true);

  const onClick = () => {
    setShowToolbar(!showToolbar);
  };

  const onClickDelete = () => {
    window.confirm('Delete response?') &&
      deleteResponse.mutate(
        { subjectId: subject.id, responseId: response.id },
        {
          onError: data => toastError(data.message),
        },
      );
  };

  return (
    <div key={response.id} className={classnames({ 'bg-blue-100': highlighted })}>
      <div className={'flex space-x-3'}>
        <UserAvatar user={response.user} className='size-6 rounded-full' />
        <div className='flex flex-col space-y-3'>
          <UsernameCreatedAt
            username={response.user.username}
            createdAt={response.createdAt}
            compact
          />
          <div className='mt-2'>
            <CommentBody
              subject={response}
              clamped={clamped}
              onClick={onClick}
              onClickReadMore={() => setClamped(false)}
            />
          </div>

          {showToolbar && (isAdmin || isCommenter) && (
            <button onClick={onClickDelete}>Delete</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentResponse;
