import { fetchClient } from './api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { staleTimes } from './query-settings';
import _ from 'lodash';
import Sugar from 'sugar';
import { useMe } from './users';
const apiClient = fetchClient();

const successUrl = window.location.origin + '?thank-you=true';
const cancelUrl = window.location.origin;

export const createSession = ({ priceLookup, onSuccess, onError }) =>
  apiClient
    .post('/payment/', {
      priceLookup,
      successUrl,
      cancelUrl,
    })
    .catch(error => onError(error))
    .then(res => onSuccess(res.data));

const getProducts = () => apiClient.get('/payment/products').then(res => res.data);

export const useProducts = () =>
  useQuery('products', () => getProducts(), { staleTime: staleTimes.long });

const getSubscriptions = () => apiClient.get('/payment/subscription').then(res => res.data);

export const useSubscriptions = () =>
  useQuery('subscriptions', getSubscriptions, { staleTime: staleTimes.long });

const grantPrivilege = ({ username, privilege, duration, quantity, rate, capacity }) =>
  apiClient
    .post(
      '/privilege',
      _.pickBy(
        { username, privilege, duration, quantity, rate, capacity },
        k => k !== undefined && k !== '',
      ),
    )
    .then(res => res.data);

export const useGrantPrivilege = username => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ privilege, duration, quantity, rate, capacity }) =>
      grantPrivilege({ username, privilege, duration, quantity, rate, capacity }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user-admin', username]);
        queryClient.invalidateQueries(['throttle']); // applies if self-granting
      },
    },
  );
};

const listPrivileges = () => apiClient.get('/privileges').then(res => res.data);

export const usePrivileges = () =>
  useQuery('privileges', listPrivileges, { staleTime: staleTimes.long });

export const useHasPrivilege = privilege => {
  const me = useMe().data?.data;
  return (
    me?.privileges?.[privilege]?.expires &&
    Sugar.Date(me?.privileges?.[privilege]?.expires).isFuture().raw
  );
};
