import { useLikelihoodStats } from '../services/theories';
import { useEffect, useMemo, useRef } from 'react';
import BannerImg from '../assets/images/collaboration.png';
import Sugar from 'sugar';
import React from 'react';
import { animals, emojis, getTeam, getDissentThresholds } from '../util/concurrency';
import Username from '../components/elements/Username';
import ta from 'time-ago';
import Heading from '../components/Banners/Heading';
import Qr from 'assets/images/quiz/QR.png';

const interpolate = (color1, color2, p) => {
  const rgb = color => {
    const hex = color.replace(/#/g, '');
    return [0, 2, 4].map(i => parseInt(hex.slice(i, i + 2), 16));
  };
  const rgb1 = rgb(color1);
  const rgb2 = rgb(color2);
  const resultrgb = rgb1.map((v, i) => Math.round(v + (rgb2[i] - v) * p));
  return '#' + resultrgb.map(v => v.toString(16).padStart(2, '0')).join('');
};

const QuizGraph = () => {
  const statsQuery = useLikelihoodStats();
  const stats = statsQuery.data?.data;
  const canvasRef = useRef(null);
  const zeroX = 40;
  const width = 2000;
  const height = 900;
  const maxD = stats?.reduce((d, s) => Math.max(s.dissent, d), 0) || 5;
  const scale = (width - 80) / Math.ceil(maxD);
  const zeroY = height - 60;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!stats) return;

    // DRAW THE GRAPH
    ctx.strokeStyle = 'black';
    ctx.font = 'caption';
    ctx.beginPath();
    ctx.moveTo(zeroX, zeroY);
    ctx.lineTo(zeroX + Math.ceil(maxD) * scale, zeroY);
    ctx.stroke();
    for (let d = 0; d <= Math.ceil(maxD); d++) {
      ctx.beginPath();
      ctx.moveTo(zeroX + d * scale, zeroY);
      ctx.lineTo(zeroX + d * scale, zeroY + 10);
      ctx.stroke();
      ctx.font = '30px Arial';
      ctx.fillText(d, zeroX + d * scale - 8, zeroY + 45);
    }

    const thresholds = getDissentThresholds();
    ctx.strokeStyle = '#bbbbbb';
    thresholds.forEach(t => {
      ctx.beginPath();
      ctx.moveTo(zeroX + t * scale, height);
      ctx.lineTo(zeroX + t * scale, 0);
      ctx.stroke();
    });

    // DRAW THE ANIMALS
    ctx.font = '120px Arial';
    ctx.globalAlpha = 0.3;
    ctx.fillText(emojis.agree, (thresholds[0] / 2) * scale - 80, height / 4);
    ctx.fillText(
      emojis.neither,
      zeroX + ((thresholds[0] + thresholds[1]) / 2) * scale - 70,
      height / 4,
    );
    ctx.fillText(emojis.disagree, zeroX + ((thresholds[1] + maxD) / 2) * scale, height / 4);
    ctx.globalAlpha = 1;

    // DRAW THE DISSENT SCORES
    let bars = {};
    stats?.forEach(user => {
      const bar = Math.round(user.dissent * 10) / 10;
      bars[bar] = (bars[bar] || 0) + 1;
      const n = bars[bar];

      //const age = (stats.length - i - 1) * 360;
      const age = Sugar.Date(user.dissentedAt).secondsAgo();
      const p = Math.min(1, (3600 * 2) / age);
      ctx.fillStyle = interpolate('#dddddd', '#550055', p);

      ctx.beginPath();
      const barF = Math.min(30, 8000 / stats.length);
      ctx.moveTo(zeroX + user.dissent * scale, zeroY - n * barF);
      ctx.lineTo(zeroX + user.dissent * scale + 8, zeroY - n * barF - 12);
      ctx.lineTo(zeroX + user.dissent * scale - 8, zeroY - n * barF - 12);
      ctx.fill();
    });
  }, [stats, maxD, scale, zeroY]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

const QuizStats = () => {
  const statsQuery = useLikelihoodStats();
  const stats = statsQuery.data?.data;

  const populations = useMemo(() => {
    const counts = { agree: 0, neither: 0, disagree: 0 };
    stats?.forEach(s => counts[getTeam(s)]++);
    return counts;
  }, [stats]);

  const recents = stats?.filter(s => s.username).slice(-20);

  return (
    <div style={{ minHeight: '1200px' }}>
      <div className='absolute left-0 w-full min-h-max'>
        <Heading text='Sheep or Quack?' backgroundImg={BannerImg} />
        <div className='flex mx-7 mt-10 justify-around'>
          <div>
            <div className='grid grid-cols-3 items-baseline text-xs gap-2.5'>
              {recents?.reverse().map((s, i) => (
                <React.Fragment key={i}>
                  <p>
                    <Username username={s.username} />
                  </p>
                  <p className='text-center'>
                    {Math.round(s.dissent * 100) / 100} = {emojis[getTeam(s)]}
                  </p>
                  <p className='text-[8px] text-right'>{ta.ago(s.dissentedAt)}</p>
                </React.Fragment>
              ))}
            </div>
            <div className='mt-4 flex flex-row text-base justify-around'>
              {Object.keys(populations).map(k => (
                <p className='' key={k}>
                  {populations[k]} {animals[k]}
                </p>
              ))}
            </div>
          </div>

          <div
            style={{ maxWidth: 1200 }}
            className='max-w-5xl mt-0 border flex flex-col w-full justify-end p-5'
          >
            <QuizGraph />
          </div>

          <div className='flex flex-col space-y-7 items-center'>
            <p className='text-xl font-bold text-regular'>Want to see your score?</p>
            <p className=''>Take the Dissent Quiz:</p>
            <p className='border-2 rounded py-2 px-4'>evincer.org/quiz</p>
            <img src={Qr} alt='QR code' className='w-32 h-32' />
            <img src={'/logo196.png'} alt='Logo' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default QuizStats;
