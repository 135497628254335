import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CreatedAt from './elements/CreatedAt';
import Sugar from 'sugar';
import classnames from 'classnames';

const Editing = ({ subject, onClick }) => {
  if (!subject) return null;

  const editing =
    subject.editingUsername && Sugar.Date(subject.editingAt).isAfter('12 hours ago').raw;

  if (!editing) return null;

  return (
    <div
      className={classnames(
        'pr-6 flex flex-row !cursor-pointer justify-end date space-x-1 items-baseline',
        editing ? 'text-primary text-base font-bold' : 'text-unselected text-xs sm:text-sm',
      )}
      onClick={onClick ? onClick : undefined}
    >
      <p>@{subject.editingUsername} is editing</p>
      <AccessTimeOutlinedIcon />
      <CreatedAt createdAt={subject.editingAt} />
    </div>
  );
};

export default Editing;
