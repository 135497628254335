import classnames from 'classnames';
import EvidenceCard from './EvidenceCard';

const EvidenceGrid = ({ className, evidences, isFetching }) => (
  <div
    className={classnames(className, 'grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-10 2xl:gap-14')}
  >
    {evidences?.map((evidence, i) => (
      <EvidenceCard
        key={i}
        evidence={evidence}
        theory={evidence.theory}
        date={evidence.publishedAt || evidence.activatedAt || evidence.createdAt}
        rankable={evidence.userRank !== undefined}
      />
    ))}
    {isFetching &&
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => <EvidenceCard key={i} evidence={null} />)}
  </div>
);

export default EvidenceGrid;
