import BannerBackground2 from '../assets/images/background2.png';
import Heading from '../components/Banners/Heading';
import React from 'react';
import Container from '@mui/material/Container';
import { useGetComments } from '../services/comments';
import { flattenPages } from '../util/query';
import Comment from '../components/Comments/Comment';
import { Link } from 'react-router-dom';
import { useInfiniteScroll } from '../util/dom';

const Comments = () => {
  const commentsQuery = useGetComments({});
  const comments = flattenPages(commentsQuery.data?.pages).map(comment => ({
    ...comment,
    link:
      (comment.evidence
        ? (comment.evidence.parent ? '/rebuttal/' : '/evidence/') + comment.evidence.slug
        : comment.theory
        ? '/inquiry/' + comment.theory.slug
        : '') +
      '?showComment=' +
      comment.id,
    subject: comment.evidence || comment.theory,
    title: (comment.evidence || comment.theory)?.title,
    type: comment.evidence
      ? comment.evidence.parent
        ? 'Rebuttal'
        : 'Evidence'
      : comment.theory
      ? 'Inquiry'
      : '',
  }));

  useInfiniteScroll(commentsQuery);

  return (
    <Container>
      <div className='flex flex-col space-y-5 > * text-sm'>
        <Heading
          text='Unresolved Comments'
          tooltip='Comments you started and comments on your content'
          backgroundImg={BannerBackground2}
        />

        {!commentsQuery.isLoading && comments.length === 0 && (
          <p className='text-lg md:text-xl font-bold p-2 md:p-4 border rounded text-regular text-center'>
            You have no unresolved comments
          </p>
        )}

        {comments.map(comment => (
          <div key={comment.id} className='flex flex-col md:flex-row'>
            <div className='p-3 md:w-1/4'>
              <p>{comment.type}:</p>
              <Link className='font-bold' to={comment.link}>
                {comment.title}
              </Link>
            </div>
            <Comment subject={comment.subject} comment={comment} />
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Comments;
