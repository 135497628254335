import { useCallback } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import flatten from 'lodash/flatten';
import { staleTimes } from 'services/query-settings';
import { fetchClient } from 'services/api';
import { evidencePath, theoryPath } from '../util/id';

const apiClient = fetchClient();

const QUERY_KEY = 'notifications';

export const readNotification = async id => {
  const url = `push-notification${id ? '/' + id : ''}/read`;
  const res = await apiClient.patch(url);

  return res.data;
};

const getNotifications = async ({ pageParam }) => {
  const res = await apiClient.get('push-notification?page=' + (pageParam || 1));
  return res.data;
};

export const useNotifications = ({ enabled }) =>
  useInfiniteQuery(QUERY_KEY, ({ pageParam }) => getNotifications({ pageParam }), {
    getNextPageParam: lastPage => (lastPage.meta.nextPage ? lastPage.meta.page + 1 : undefined),
    staleTime: staleTimes.blip,
    enabled,
  });

export const useInvalidateNotifications = () => {
  const queryClient = useQueryClient();

  return () => {
    // clear all but 1st page (avoids dot flickering)
    queryClient.setQueryData(QUERY_KEY, oldNotifications => {
      return {
        ...oldNotifications,
        pages: oldNotifications?.pages
          ? [oldNotifications.pages[0]]
          : [{ meta: { page: 1, nextPage: false }, data: [] }],
      };
    });
    // invalidate to force refetch
    queryClient.invalidateQueries({ queryKey: QUERY_KEY });
  };
};

export const useNotificationsUpdate = () => {
  const queryClient = useQueryClient();

  const addNotification = useCallback(
    notification => {
      if (!queryClient.getQueryData(QUERY_KEY)?.pages) queryClient.invalidateQueries(QUERY_KEY);
      else
        queryClient.setQueryData(QUERY_KEY, oldData => {
          const allNotifications = [
            notification,
            ...flatten(oldData.pages.map(({ data }) => data)),
          ];
          const pageCount = Math.ceil(
            (oldData.pages[0].meta.total + 1) / oldData.pages[0].meta.perPage,
          );

          const pages = oldData.pages.map((page, i) => {
            return {
              data: allNotifications.slice(i * page.meta.perPage, (i + 1) * page.meta.perPage),
              meta: {
                ...page.meta,
                nextPage: page.meta.page === pageCount ? false : page.meta.page + 1,
                total: oldData.pages[0].meta.total + 1,
                pageCount,
              },
            };
          });

          return { ...oldData, pages };
        });
    },
    [queryClient],
  );

  const markNotificationAsRead = useCallback(
    id => {
      queryClient.setQueryData(QUERY_KEY, oldData => {
        return {
          ...oldData,
          pages: oldData?.pages
            ? oldData.pages.map(({ data, ...pageData }) => ({
                ...pageData,
                data: data.map(notification => ({
                  ...notification,
                  read: notification.id === id ? true : notification.read,
                })),
              }))
            : [{ meta: { page: 1, nextPage: false }, data: [] }],
        };
      });
    },
    [queryClient],
  );

  const deleteNotification = useCallback(
    id => {
      queryClient.setQueryData(QUERY_KEY, oldData => {
        return {
          ...oldData,
          pages: oldData?.pages
            ? oldData.pages.map(({ data, ...pageData }) => ({
                ...pageData,
                data: data.filter(n => n.id !== id),
              }))
            : [{ meta: { page: 1, nextPage: false }, data: [] }],
        };
      });
    },
    [queryClient],
  );

  return {
    addNotification,
    markNotificationAsRead,
    deleteNotification,
  };
};

export const parseNotification = notification => {
  const commentSubject = notification.evidence || notification.theory;

  const commentRoute =
    (notification.evidence
      ? evidencePath(notification.evidence)
      : theoryPath(notification.theory)) +
    '?showComment=' +
    notification.comment?.id;

  const commentBody =
    notification.comment?.body?.substring(0, 60) +
    (notification.comment?.body?.length > 60 ? '...' : '');

  switch (notification.type) {
    case 'add-author':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence),
          text: `You were added as an author to **${notification.evidence.title}**`,
          ...notification,
        }
      );

    case 'theory-publish':
      return (
        notification.theory && {
          route: theoryPath(notification.theory),
          text: `**${notification.theory.title.trim()}** was published`,
          ...notification,
        }
      );

    case 'evidence':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence),
          text: `New ${
            notification.evidence.parent ? 'rebuttal' : 'evidence'
          } **${notification.evidence.title.trim()}** submitted ${
            notification.evidence.for ? 'for' : 'against'
          } **${notification.theory.title.trim()}**`,
          ...notification,
        }
      );

    case 'comment':
      return (
        commentSubject && {
          route: commentRoute,
          text: `New comment *${commentBody}* on **${commentSubject?.title.trim()}** `,
          ...notification,
        }
      );

    case 'comment-respond':
      return (
        commentSubject && {
          route: commentRoute + '&showResponse=' + (notification.commentResponse || ''),
          text: `New response to *${commentBody}* on **${commentSubject?.title.trim()}**`,
          ...notification,
        }
      );

    case 'comment-mention':
      return (
        commentSubject && {
          route: commentRoute + '&showResponse=' + (notification.commentResponse || ''),
          text: `@${
            notification.actingUser.username
          } mentioned you in *${commentBody}* on **${commentSubject?.title.trim()}**`,
          ...notification,
        }
      );

    case 'comment-resolve':
      return (
        commentSubject && {
          route: commentRoute,
          text: `Comment resolved: *${commentBody}* on **${commentSubject?.title.trim()}**`,
          ...notification,
        }
      );

    case 'evidence-rank':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence),
          text:
            `**${notification.evidence.title.trim()}** was promoted ` +
            (notification.evidence.promotes > 2 ? `${notification.evidence.promotes} times` : ''),
          ...notification,
        }
      );

    case 'evidence-edit':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence) + '?showRevisionHistory=true',
          text: `**${notification.evidence.title.trim()}** was edited `,
          ...notification,
        }
      );

    case 'evidence-publish':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence),
          text: `**${notification.evidence.title.trim()}** was published `,
          ...notification,
        }
      );

    case 'evidence-short':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence),
          text: `**${notification.evidence.title.trim()}** needs a longer relevance section to be published`,
          ...notification,
        }
      );

    case 'cluster-add':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence),
          text: `**${notification.evidence.title.trim()}** was marked as a duplicate`,
          ...notification,
        }
      );

    case 'cluster-exclude':
      return (
        notification.evidence && {
          route: evidencePath(notification.evidence),
          text: `**${notification.evidence.title.trim()}** was marked as a not a duplicate`,
          ...notification,
        }
      );

    case 'theory-activate':
      return (
        notification.theory && {
          route: theoryPath(notification.theory),
          text: `**${notification.theory.title.trim()}** Inquiry created`,
          ...notification,
        }
      );

    case 'theory-rank':
      return (
        notification.theory && {
          route: theoryPath(notification.theory),
          text:
            `**${notification.theory.title.trim()}** was promoted ` +
            (notification.theory.promotes > 2 ? `${notification.theory.promotes} times` : ''),
          ...notification,
        }
      );

    case 'trust-level':
      return (
        notification.actingUser && {
          route: '/user',
          text: `You've earned enough 📜 to reach **Level ${
            notification.data?.trustLevel || notification.actingUser.trustLevel
          }!**`,
          ...notification,
        }
      );

    case 'trust-adjust':
      return (
        notification.data && {
          route: '/user',
          text:
            notification.data > 0
              ? 'An admin granted you **' + notification.data + ' 📜**'
              : 'An admin adjusted your 📜 by ' + notification.data,
          ...notification,
        }
      );

    case 'theory-edit':
      const theory = notification.theory;
      return (
        theory && {
          route: theoryPath(notification.theory) + '?showRevisionHistory=true',
          text: `**${theory?.title.trim()}** was edited`,
          ...notification,
        }
      );

    case 'sway':
      const evidence = notification.evidence;
      return (
        evidence && {
          route: evidencePath(notification.evidence),
          text: `**${evidence.title.trim()}** earned a sway!`,
          ...notification,
        }
      );

    case 'likelihood':
      return {
        route: '/compare/' + notification.actingUser.username,
        text: `**@${notification.actingUser.username}** shared their opinion on "${notification.theory.title}"`,
        ...notification,
      };

    default:
      break;
  }
  return null;
};
