import ui from '../../config/ui';
import { useContext } from 'react';
import UiContext from '../../state/UiContext';

const UsernameInput = ({ username, onChange, className, disabled, onEnter }) => {
  const { lgPlus } = useContext(UiContext);
  const max = ui.usernameLength + (username[0] === '@' ? 1 : 0);
  const charsLeft = max - username.length;

  return (
    <div className=' flex flex-row space-x-2'>
      <input
        maxLength={max}
        placeholder='Username'
        className={className}
        value={username}
        onChange={onChange}
        autoFocus={lgPlus}
        disabled={!!disabled}
        onKeyUp={e => e.key === 'Enter' && onEnter && onEnter()}
      />

      {charsLeft < 10 && !disabled && <p>-{charsLeft}</p>}
    </div>
  );
};
export default UsernameInput;
