import { Link as RouterLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import classnames from 'classnames';
import { track } from '../../services/analytics';

const Username = ({ username, tooltip, className }) =>
  username ? (
    <Tooltip title={tooltip || ''} placement='right'>
      <RouterLink
        className={classnames('self-center text-unselected font-semibold', className)}
        to={'/user/' + username}
        onClick={() => track('click-username', { username })}
      >
        @{username}
      </RouterLink>
    </Tooltip>
  ) : null;
export default Username;
