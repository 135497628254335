import { fetchClient } from 'services/api';
import { staleTimes } from './query-settings';
import { useQuery } from 'react-query';
import { useMe } from './users';
import { useRef } from 'react';

const apiClient = fetchClient();

const getTrustConfig = () => apiClient.get('/config/trust-level').then(res => res.data);

export const useTrustConfig = () =>
  useQuery('trust', getTrustConfig, { staleTime: staleTimes.long });

export const useIsTrusted = action => {
  const trustConfig = useTrustConfig().data?.data;
  const me = useMe().data?.data;

  const trustLevels = trustConfig?.trustLevels;
  if (!trustLevels) return [!!me, '...'];

  const levelRequired = Object.keys(trustConfig.trustLevels).find(
    level => trustLevels[level][action] === true,
  );

  if (!me) return [false, levelRequired];

  return [me.trustLevel >= levelRequired, levelRequired];
};

export const useTrustedActionsRef = () => {
  const trustConfig = useTrustConfig().data?.data;
  const me = useMe().data?.data;
  const trustLevels = trustConfig?.trustLevels;
  const actions = useRef([]);
  if (trustLevels && me)
    actions.current = Object.keys(trustLevels).reduce(
      (actions, level) =>
        level <= me.trustLevel
          ? [
              ...actions,
              ...Object.keys(trustLevels[level]).filter(action => action !== 'trustNeeded'),
            ]
          : actions,
      [],
    );
  return actions;
};

export const getDurations = () => apiClient.get('/config/durations').then(res => res.data);

export const useDurations = () =>
  useQuery('durations', getDurations, { staleTime: staleTimes.long });
