import { useGetUserAdmin, useGetUserStats } from '../../services/users';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { useState } from 'react';
import { useGrantPrivilege, usePrivileges } from '../../services/payments';
import _ from 'lodash';
import Sugar from 'sugar';

const Privilege = ({ username, privilege }) => {
  const [rate, setRate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [duration, setDuration] = useState('3 months');
  const [capacity, setCapacity] = useState('');
  const grantPrivilege = useGrantPrivilege(username);
  const [grant, setGrant] = useState();
  const confirmGrant = () => {
    grantPrivilege.mutate(grant);
    setGrant(undefined);
  };
  const now = Sugar.Date();
  const user = useGetUserAdmin({ username }).data?.data;

  const status = !user?.privileges?.[privilege.privilege]?.expires
    ? 'inactive'
    : now.isBefore(user?.privileges?.[privilege.privilege]?.expires).raw
    ? 'until'
    : 'expired';

  const colors = { inactive: 'text-unselected', until: 'text-green-800', expired: 'text-red-800' };

  return (
    <div className='mt-6 text-xs md:text-base'>
      <p className={colors[status]}>{privilege.privilege}</p>

      <div className='flex space-x-2'>
        {['rate', 'capacity', 'quantity']
          .filter(k => user?.privileges?.[privilege.privilege]?.[k])
          .map(
            key =>
              user?.privileges?.[privilege.privilege]?.[key] && (
                <p key={key}>
                  {key}: {user?.privileges?.[privilege.privilege]?.[key]}
                </p>
              ),
          )}
        <p>
          {status} {user?.privileges?.[privilege.privilege]?.expires?.substring(0, 10)}
        </p>
      </div>
      <div className='flex flex-row space-x-2'>
        {privilege.rate && (
          <input
            className='w-32 border rounded'
            value={rate}
            placeholder={'rate'}
            onChange={e => setRate(e.target.value)}
          />
        )}
        {privilege.capacity && (
          <input
            className='w-32 border rounded'
            value={capacity}
            placeholder={'capacity'}
            onChange={e => setCapacity(e.target.value)}
          />
        )}

        {privilege.quantity && (
          <input
            className='w-32 border rounded'
            value={quantity}
            placeholder={'quantity'}
            onChange={e => setQuantity(e.target.value)}
          />
        )}
        <input
          className='w-32 border rounded'
          value={duration}
          placeholder={'duration'}
          onChange={e => setDuration(e.target.value)}
        />
        <button
          className='px-3 border rounded'
          onClick={() =>
            setGrant({ privilege: privilege.privilege, quantity, duration, rate, capacity })
          }
        >
          Go
        </button>
      </div>
      {grant && (
        <div className='flex flex-row space-x-5'>
          <button onClick={confirmGrant}>confirm</button>
          <button onClick={() => setGrant(undefined)}>cancel</button>
        </div>
      )}
    </div>
  );
};

const AdminUser = () => {
  const params = useParams();
  const [days, setDays] = useState(30);
  const userQuery = useGetUserStats({ username: params.username, days });
  const userStats = userQuery.data?.data;

  const privilegesQuery = usePrivileges();
  const privileges = _.uniqBy(privilegesQuery.data?.data, p => p.privilege);

  return (
    <div className='flex flex-col md:flex-row justify-between mx-6'>
      <div>
        <p>Stats for @{params.username}</p>
        <div className='flex flex-row'>
          <input
            placeholder='days'
            className='ml-2 bg-gray-100 border w-16 p-1'
            value={days}
            onChange={e => setDays(e.target.value)}
          />
          <p>days</p>
        </div>
        <div className='flex flex-row text-xs mt-4'>
          <div className='flex flex-col mx-3'>
            <p className='h-10'>Days</p>
            {userStats &&
              Object.keys(userStats).map(key => (
                <p className='h-10' key={key}>
                  {key}
                </p>
              ))}
          </div>

          <div className={classnames('flex flex-col items-end', 'text-black')}>
            <p className='h-10 px-2'>{days}</p>
            {userStats &&
              Object.keys(userStats).map((k, i) => (
                <p className='h-10 px-2' key={i}>
                  {userStats[k]}
                </p>
              ))}
          </div>
        </div>
      </div>
      <div>
        <p className='text-lg'>Member Privileges</p>
        {privileges.map(privilege => (
          <Privilege username={params.username} privilege={privilege} key={privilege.privilege} />
        ))}
      </div>
    </div>
  );
};

export default AdminUser;
