import { useIsDuplicate } from '../../services/clusters';
import id from '../../util/id';

const Status = ({ evidence }) => {
  const isDuplicate = useIsDuplicate(id(evidence?.theory), id(evidence));

  const duplicate = isDuplicate && !evidence.place;
  const isPublic = ['active', 'published'].includes(evidence.status);
  if (!duplicate && isPublic) return null;

  return (
    <p className='text-primary text-base'>
      {duplicate && 'Duplicate '}
      {!isPublic && evidence.status}
      {evidence.status === 'archived' && evidence.archiveReason && `: ${evidence.archiveReason}`}
    </p>
  );
};

export default Status;
