import { fetchClient } from 'services/api';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { staleTimes } from './query-settings';
import { useJwt, useDelegateToken } from './auth';
import _ from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const apiClient = fetchClient();

export const userQueryKeys = {
  me: 'me',
  users: 'users',
};

const getMe = async delegateToken => {
  return await apiClient
    .get('/me' + (delegateToken ? '?delegateToken=' + delegateToken : ''))
    .then(res => res.data);
};

export const useMe = options => {
  const [jwt, setJwt] = useJwt();
  const [searchParams, setSearchParams] = useSearchParams();
  const [delegateToken, setDelegateToken] = useDelegateToken();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (searchParams.get('delegateToken')) {
      setDelegateToken(searchParams.get('delegateToken'));
      searchParams.delete('delegateToken');
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setDelegateToken, setSearchParams]);

  return useQuery(userQueryKeys.me, () => getMe(delegateToken), {
    enabled: (!!jwt || !delegateToken) && !searchParams.get('delegateToken'),
    retry: false,
    staleTime: staleTimes.medium,
    ...options,
    onSuccess: (data, variables) => {
      if (data?.jwt) setJwt(data.jwt);
      options?.onSuccess && options.onSuccess(data, variables);
    },
    onError: error => {
      if (error?.jwt === '') {
        console.log('useme clearing jwt');
        setJwt('');
        queryClient.invalidateQueries().then(() => {});
      }
      options?.onError && options.onError(error);
    },
  });
};

const getUser = async idOrUsername => {
  return await apiClient.get('/user/' + idOrUsername).then(res => res.data);
};

export const useGetUser = ({ idOrUsername }) =>
  useQuery(
    idOrUsername ? ['user', { idOrUsername }] : userQueryKeys.me,
    () => (idOrUsername ? getUser(idOrUsername) : getMe()),
    { staleTime: staleTimes.medium },
  );

const getUserAdmin = async username =>
  await apiClient.get('/user/' + username + '?forAdmin=true').then(res => res.data);

export const useGetUserAdmin = ({ username }) =>
  useQuery(['user-admin', username], () => getUserAdmin(username), {
    staleTime: staleTimes.medium,
  });

const getUserStats = async ({ username, days }) =>
  await apiClient
    .get('/admin/user-stats/' + username + (days ? '?days=' + days : ''))
    .then(res => res.data);

export const useGetUserStats = ({ username, days }) =>
  useQuery(['user-stats', { username, days }], () => getUserStats({ username, days }), {
    staleTime: staleTimes.medium,
  });

const updateMe = async user => {
  user.username = user.username?.replace(/^@/, '');
  const formData = new FormData();
  ['username', 'displayName', 'bio', 'profileImage', 'backgroundImage'].forEach(
    field => user[field] && formData.append(field, user[field]),
  );

  return await apiClient
    .patch('/me', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(res => res.data);
};

export const useUpdateMe = () => {
  const queryClient = useQueryClient();
  return useMutation(updateMe, {
    onSuccess: () => queryClient.invalidateQueries(userQueryKeys.me),
  });
};

export const useUpdateUserQueryData = (key = 'me') => {
  const queryClient = useQueryClient();
  return newUserData =>
    queryClient.getQueryData(key) &&
    queryClient.setQueryData(key, oldUser => ({
      ...oldUser,
      data: {
        ...oldUser.data,
        ...newUserData,
      },
    }));
};

const getUsers = async (page = 0, username) =>
  await apiClient
    .get('/user?page=' + page + (username ? '&username=' + username : ''))
    .then(res => res.data);

export const useGetUsers = username => {
  return useInfiniteQuery(
    [userQueryKeys.users, username],
    ({ pageParam }) => getUsers(pageParam, username),
    {
      getNextPageParam: lastPage =>
        lastPage.meta.pageCount <= lastPage.meta.page ? null : lastPage.meta.page + 1,
    },
  );
};

const adjustTrust = async ({ id, delta }) =>
  await apiClient.patch('/user/' + id + '/trust/?delta=' + delta).then(res => res.data);

export const useAdjustTrust = () => {
  const queryClient = useQueryClient();
  return useMutation(adjustTrust, {
    onSuccess: () => queryClient.invalidateQueries(userQueryKeys.users),
  });
};

const updateUser = async ({ id, isPaid }) =>
  await apiClient.patch('/user/' + id + '?isPaid=' + isPaid).then(res => res.data);

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUser, {
    onSuccess: () => queryClient.invalidateQueries(userQueryKeys.users),
  });
};

const advanceTutorial = async ({ name, step, status }) =>
  await apiClient
    .patch('/tutorial/' + name + '/' + step + (status ? '?status=' + status : ''))
    .then(res => res.data);

export const useAdvanceTutorial = () => {
  const queryClient = useQueryClient();
  return useMutation(advanceTutorial, {
    onSuccess: data =>
      queryClient.setQueryData(userQueryKeys.me, oldMe => ({
        ...oldMe,
        data: { ...oldMe.data, tutorials: data.data },
      })),
  });
};

const checkThrottle = async action =>
  await apiClient.get('/user/throttle/' + action).then(res => res.data);

export const useCheckThrottle = (action, enabled = true) => {
  const [jwt] = useJwt();
  return useQuery(['throttle', action], () => checkThrottle(action), {
    enabled: !!jwt && enabled,
  });
};

const getLikelihoods = async id =>
  await apiClient.get('/likelihoods/' + (id ? '?id=' + id : '')).then(res => res.data);

export const useGetLikelihoods = id =>
  useQuery(id ? ['likelihoods', id] : 'likelihoods', () => getLikelihoods(id));

export const reportShare = async params =>
  window.localStorage.getItem('jwt') > '""' &&
  (await apiClient
    .post(
      '/user/share',
      _.pickBy(
        params,
        (v, k) => v !== undefined && ['evidence', 'theory', 'compareLink', 'link'].includes(k),
      ),
    )
    .then(() => undefined));
