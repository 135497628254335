import classnames from 'classnames';

export const YesNoIcon = ({ char }) => {
  return (
    <span
      className={classnames(
        'px-1 py-1 font-bold rounded text-white shrink-0',
        char === 'Y' ? 'bg-orange-400' : char === 'N' ? 'bg-blue-400' : 'bg-gray-300',
      )}
    >
      {char}
    </span>
  );
};

export const YesIcon = () => <YesNoIcon char='Y' />;

export const NoIcon = () => <YesNoIcon char='N' />;
