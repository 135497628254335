import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { TikTokEmbed, XEmbed } from 'react-social-media-embed';

const VideoEmbed = ({ link }) => {
  if (!link) return null;

  const youTubeId = link.match(/youtube.com.*v=([A-Za-z0-9-]+)/)?.[1];
  const rumbleUrl = link.match(/rumble.com\/embed\//) ? link : undefined;
  const [, tiktokUrl, tiktokId] = link.match(/(.*tiktok.com\/[^/]+\/video\/(\d+))/) || [];
  const [, twitterUsername, twitterId] =
    link.match(/(?:twitter|x)\.com\/([^/]+)\/status\/([^/]+)/) || [];

  if (youTubeId) return <LiteYouTubeEmbed id={youTubeId} title={'Video Embed'} />;
  else if (rumbleUrl)
    return <iframe title={link} className='w-full aspect-video' src={rumbleUrl} />;
  else if (tiktokId) return <TikTokEmbed url={tiktokUrl} />;
  else if (twitterId)
    return <XEmbed url={`https://x.com/${twitterUsername}/status/${twitterId}`} />;
  else return null;
};

export default VideoEmbed;
