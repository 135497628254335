import SlideDownModal from '../wrappers/SlideDownModal';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import UiContext from '../../state/UiContext';
import {
  useClusteredEvidence,
  useClusterEvidence,
  useClusters,
  useUnclusterEvidence,
} from '../../services/clusters';
import classnames from 'classnames';
import { useTheoryEvidence } from '../../services/evidence';
import id from 'util/id';
import { flattenPages } from '../../util/query';
import { useInfiniteScroll } from '../../util/dom';

const ClusterModal = ({ open, onClose, evidence, theory }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const clusters = useClusters(id(evidence.theory));
  const scrollRef = useRef();

  const peerEvidenceQuery = useTheoryEvidence(
    {
      status: 'public',
      isFor: evidence.for,
      theoryId: theory.id,
      parentId: id(evidence.parent) || null,
    },
    { enabled: open },
  );
  useInfiniteScroll(peerEvidenceQuery, { el: scrollRef.current });

  const [selectedEvidence, setSelectedEvidence] = useState(false);
  const clusterEvidence = useClusterEvidence();
  const unclusterEvidence = useUnclusterEvidence();

  const ourCluster = useMemo(
    () => clusters.data?.data?.find(c => c.members.includes(evidence.id)),
    [clusters, evidence],
  );

  const duplicatesQuery = useClusteredEvidence(ourCluster?.id);
  const otherDuplicates = useMemo(
    () => duplicatesQuery.data?.data.filter(d => d.id !== evidence.id) || [],
    [duplicatesQuery, evidence.id],
  );

  const peerEvidence = useMemo(
    () =>
      flattenPages(peerEvidenceQuery.data?.pages)
        .filter(e => e.id !== evidence.id)
        .filter(e => !otherDuplicates.some(d => d.id === e.id)) || [],
    [peerEvidenceQuery, evidence, otherDuplicates],
  );

  useEffect(() => {
    // if we're already in a cluster, select one of the cluster's members
    if (
      ourCluster?.members.length > 1 &&
      selectedEvidence === false &&
      otherDuplicates?.length &&
      ourCluster
    )
      setSelectedEvidence(otherDuplicates.find(d => ourCluster.members.includes(d.id)));
  }, [otherDuplicates, ourCluster, selectedEvidence, setSelectedEvidence]);

  function onSubmit() {
    if (selectedEvidence) {
      clusterEvidence.mutate(
        { evidence1: evidence, evidence2: selectedEvidence },
        {
          onSuccess: () => {
            toastSuccess('Evidence marked as duplicate');
            onClose();
          },
          onError: data => toastError(data.message),
        },
      );
    } else if (ourCluster && ourCluster.members.length > 1)
      unclusterEvidence.mutate(
        { evidence },
        {
          onSuccess: () => {
            toastSuccess('Evidence no longer marked as duplicate');
            onClose();
          },
          onError: data => toastError(data.message),
        },
      );
  }

  return (
    <SlideDownModal
      open={open}
      title={`Which evidence does "${evidence.title}" duplicate?`}
      onClose={onClose}
    >
      <div>
        <div
          ref={scrollRef}
          className='flex flex-col space-y-2 max-h-64 sm:max-h-96 overflow-auto px-5'
        >
          <button
            onClick={() => setSelectedEvidence(null)}
            className={classnames(
              'text-xs md:text-base text-left font-regular p-2 md:p-4 m-1 border',
              !selectedEvidence ? 'text-primary bg-gray-200' : 'text-unselected',
            )}
          >
            None
          </button>

          {otherDuplicates.map(e => (
            <button
              key={e.id}
              onClick={() => setSelectedEvidence(e)}
              className={classnames(
                'text-xs md:text-base text-left font-semibold p-2 md:p-4 m-1 border',
                ourCluster.members.some(m => m === e.id) && selectedEvidence !== null
                  ? 'text-primary bg-gray-200'
                  : 'text-unselected',
              )}
            >
              {e.title}
            </button>
          ))}

          {peerEvidence.map(e => (
            <button
              key={e.id}
              onClick={() => setSelectedEvidence(e)}
              className={classnames(
                'text-xs md:text-base text-left font-semibold p-2 md:p-4 m-1 border',
                selectedEvidence?.id === e.id ? 'text-primary bg-gray-200' : 'text-unselected',
              )}
            >
              {e.title}
            </button>
          ))}
        </div>
        <div className='flex flex-row space-x-3 text-sm md:text-base'>
          <button onClick={onSubmit} className={classnames('p-3', 'text-primary')}>
            Submit
          </button>
          <button onClick={onClose} className='p-3 text-secondary'>
            Cancel
          </button>
        </div>
      </div>
    </SlideDownModal>
  );
};

export default ClusterModal;
