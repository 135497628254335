import { useEmailConfig, useEmailMetrics, useLogs, useSendTest } from '../../services/email';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import UiContext from '../../state/UiContext';
import { useMe } from '../../services/users';
import React from 'react';
import { flattenPages } from '../../util/query';
import ta from 'time-ago';
import { useInfiniteScroll } from '../../util/dom';
import { Tooltip } from '@mui/material';
import classnames from 'classnames';
import PaidIcon from '@mui/icons-material/Paid';
import { useNavigate } from 'react-router-dom';

const AdminEmail = () => {
  const [includePaid, setIncludePaid] = useState(false);
  const emailConfig = useEmailConfig();
  const sendTest = useSendTest();
  const me = useMe();
  const [username, setUsername] = useState('');
  const [days, setDays] = useState('');
  const [template, setTemplate] = useState('');
  const [userId, setUserId] = useState();
  const [subscriptionType, setSubscriptionType] = useState('');
  const metricsQuery = useEmailMetrics({ template, subscriptionType, days, includePaid });
  const metrics = metricsQuery.data?.data;
  const { toastError, toastSuccess } = useContext(UiContext);
  const logsQuery = useLogs({ template, subscriptionType, userId, includePaid });
  const [showClicks, setShowClicks] = useState();
  const navigate = useNavigate();

  useInfiniteScroll(logsQuery);

  useEffect(() => {
    if (!username && me.data?.data.username) setUsername(me.data.data.username);
  }, [username, me, setUsername]);

  const emailLogs = useMemo(() => flattenPages(logsQuery.data?.pages), [logsQuery]);

  const sendEmail = useCallback(
    () =>
      sendTest.mutate(
        { username, template },
        {
          onSuccess: () => toastSuccess('Email sent'),
          onError: data => toastError(data.message),
        },
      ),
    [username, template, sendTest, toastError, toastSuccess],
  );

  const Value = ({ className, name, onClick, children }) => (
    <p className={'p-2 ' + className} onClick={onClick}>
      {name && <span className='font-bold'>{name}: </span>}
      {children}
    </p>
  );
  const date = d => ta.ago(d);
  const percent = v => Math.round(v * 1000) / 10 + '%';
  const tenths = v => Math.round(v * 10) / 10;
  const hundredths = v => Math.round(v * 100) / 100;

  const onChangeDays = e => {
    setDays(e.target.value);
  };

  const onChangeTemplate = e => {
    setTemplate(e.target.value);
    setSubscriptionType('');
  };

  const onChangeSubscriptionType = e => {
    setSubscriptionType(e.target.value);
    setTemplate('');
  };

  return (
    <div className='m-4'>
      <div className='space-y-4 bg-slate-100 rounded p-2 items-baseline text-xs'>
        <input placeholder='days ago' className='p-1' value={days} onChange={onChangeDays} />

        <select className='ml-2 p-1' value={subscriptionType} onChange={onChangeSubscriptionType}>
          <option value=''>subscription:</option>
          {!!emailConfig?.data?.subscriptionTypes &&
            emailConfig.data.subscriptionTypes.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
        </select>

        <select className='ml-2 p-1' value={template} onChange={onChangeTemplate}>
          <option value=''>template:</option>
          {!!emailConfig?.data?.templates &&
            Object.keys(emailConfig.data.templates).map(template => (
              <option key={template} value={template}>
                {template}
              </option>
            ))}
        </select>

        {template && (
          <div className={'flex'}>
            <p>Send to</p>
            <input
              className='p-1 ml-2'
              placeholder='username'
              value={username}
              onChange={e => setUsername(e.target.value)}
            />

            <button className='border border-blue-300 rounded p-1' onClick={sendEmail}>
              Send
            </button>
          </div>
        )}

        {userId && <button onClick={() => setUserId(undefined)}>{userId} x</button>}

        <Tooltip title='include paid users'>
          <button
            onClick={() => setIncludePaid(!includePaid)}
            className={classnames('ml-2', includePaid ? 'text-green-400' : 'text-unselected')}
          >
            <PaidIcon />
          </button>
        </Tooltip>
      </div>

      {metrics && (
        <div className='rounded border my-5 text-2xs md:text-xs'>
          <p className='text-xl font-bold my-5'>Metrics:</p>
          <div className='grid grid-cols-5 '>
            <Value name='Sent'>{metrics.sends}</Value>
            <Value name='Delivered'>{metrics.deliveries}</Value>
            <Value name='Delivered/Sent'>{percent(metrics.deliveries / metrics.sends)}</Value>
            <Value name='Bounces'>{metrics.bounces}</Value>
            <Value name='Bounces/Sent'>{percent(metrics.bounces / metrics.sends)}</Value>

            <Value name='Opened' className='col-start-1'>
              {metrics.opened}
            </Value>
            <Value name='Opened/Dlvrd'>{percent(metrics.opened / metrics.deliveries)}</Value>
            <Value name='Opens/Dlvrd'>{hundredths(metrics.opens / metrics.deliveries)}</Value>

            <Value name='Clicked' className='col-start-1'>
              {metrics.clicked}
            </Value>
            <Value name='Clicked/Dlvrd'>{percent(metrics.clicked / metrics.deliveries)}</Value>
            <Value name='Clicked/Opened'>{percent(metrics.clicked / metrics.opened)}</Value>
            <Value name='Clicks/Dlvrd'>{hundredths(metrics.clicks / metrics.deliveries)}</Value>
            <Value name='Clicks/Opened'>{hundredths(metrics.clicks / metrics.opened)}</Value>

            <Value name='Unsubscribed' className='col-start-1'>
              {metrics.unsubscribed}
            </Value>
            <Value name='Unsub/Dlvrd'>{percent(metrics.unsubscribed / metrics.deliveries)}</Value>
            <Value name='Unsub/Opened'>{percent(metrics.unsubscribed / metrics.opened)}</Value>

            <Value name='Activities' className='col-start-1'>
              {metrics.activityCount}
            </Value>
            <Value name='Activities/Dlvrd'>
              {tenths(metrics.activityCount / metrics.deliveries)}
            </Value>
            <Value name='Actvties/Opened'>{tenths(metrics.activityCount / metrics.opened)}</Value>
            <Value name='📜/Dlvrd'>{Math.round(metrics.trustEarned / metrics.deliveries)}</Value>
            <Value name='📜/Opened'>{Math.round(metrics.trustEarned / metrics.opened)}</Value>

            {metrics.clickCounts.slice(0, 5).map((click, i) => (
              <Value key={click.id} className={i === 0 ? 'col-start-1' : ''} name={click.id}>
                {click.clicks}
              </Value>
            ))}
          </div>
        </div>
      )}

      <p className='text-xl font-bold my-5'>logs:</p>

      <div className='grid grid-cols-5 text-2xs md:text-xs'>
        {emailLogs.map((log, i) => (
          <React.Fragment key={i}>
            <Tooltip title={log.subject}>
              <div>
                <Value className='col-start-1'>{log.template}</Value>
              </div>
            </Tooltip>
            {log.user?.username ? (
              <Tooltip title={log.recipients[0]}>
                <button
                  onClick={() =>
                    userId ? navigate('/user/' + log.user.username) : setUserId(log.user.id)
                  }
                  className='p-2 font-bold'
                >
                  @{log.user.username}
                </button>
              </Tooltip>
            ) : (
              <Value className='break-words' onClick={() => setUserId(log.user.id)}>
                {log.recipients[0]}
              </Value>
            )}
            <Value className={log.delivered ? '' : 'text-red-500'}>
              {log.delivered
                ? `delivered ${date(log.delivered)}`
                : `${log.sent ? 'sent' : 'not sent'} ${date(log.createdAt)}`}
            </Value>
            <Value className={log.bounced || log.list_unsubscribed ? 'text-red-500' : ''}>
              {log.bounced
                ? `bounced ${date(log.bounced)}`
                : log.list_unsubscribed
                ? `list-unsub ${date(log.list_unsubscribed)}`
                : log.opened
                ? 'opened ' +
                  (log.opens?.length > 1 ? `(x${log.opens?.length}) ` : '') +
                  date(log.opened)
                : ''}
            </Value>
            <button
              className={
                'p-2 ' +
                (log.clicks?.some(c => c.url.toLowerCase().includes('subscribe=false'))
                  ? 'text-red-500'
                  : '')
              }
              onClick={() => setShowClicks(c => c !== log.id && log.id)}
            >
              {log.clicks?.length ? `${log.clicks.length} clicks` : ''}
            </button>

            {showClicks === log.id &&
              log.clicks?.map((click, j) => (
                <React.Fragment key={j}>
                  <Value className='col-start-2'>{click.id}</Value>
                  <a href={click.url} className='break-words p-2'>
                    {click.url}
                  </a>
                  <Value className='col-span-2'>{date(click.clickedAt)}</Value>
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AdminEmail;
