import { useCreateSource, useGetSources } from '../../services/sources';
import { useContext, useState } from 'react';
import _ from 'lodash';
import UiContext from '../../state/UiContext';

const AdminSources = () => {
  const sources = useGetSources().data?.data;
  const createSource = useCreateSource();
  const [linkCode, setLinkCode] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [paid, setPaid] = useState(true);
  const [domain, setDomain] = useState('');
  const { toastSuccess, toastError } = useContext(UiContext);

  const types = _.uniq(sources?.filter(s => s.type).map(({ type }) => type));
  const domains = _.uniq(sources?.filter(s => s.domain).map(({ domain }) => domain));

  function submit() {
    createSource.mutate(
      { linkCode, type, paid, domain, description },
      {
        onSuccess: () => {
          setLinkCode('');
          setType('');
          setDescription('');
          setPaid(true);
          setDomain('');
          toastSuccess('Source added');
        },
        onError: e => toastError(e.message),
      },
    );
  }

  return (
    <div className='flex flex-col space-y-3 px-8'>
      <input placeholder='link code' value={linkCode} onChange={e => setLinkCode(e.target.value)} />
      <div>
        Paid: <input type='checkbox' value='paid' checked={paid} onChange={() => setPaid(!paid)} />
      </div>

      <input placeholder='type' value={type} onChange={e => setType(e.target.value)} />
      <div className='flex flex-row space-x-2'>
        {!types.includes(type) &&
          types.map(t => (
            <button key={t} className='text-primary' onClick={() => setType(t)}>
              {t}
            </button>
          ))}
      </div>

      <input placeholder='domain' value={domain} onChange={e => setDomain(e.target.value)} />
      <div className='flex flex-row space-x-2'>
        {!domains.includes(domain) &&
          domains?.map(d => (
            <button key={d} className='text-primary' onClick={() => setDomain(d)}>
              {d}
            </button>
          ))}
      </div>

      <input
        placeholder='description'
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
      <button onClick={submit}>Submit</button>
      {sources
        ?.map(s => ({ ...s, ...{ paid: s.paid ? 'yes' : 'no' } }))
        .map(s => (
          <div key={s.id} className='p-3 border m-3 border rounded'>
            <button
              className='text-primary'
              onClick={() =>
                navigator.clipboard.writeText('link=' + s.linkCode) && toastSuccess('copied')
              }
            >
              copy link code
            </button>

            <div className='flex flex-row space-x-3'>
              <div>
                {['linkCode', 'type', 'paid', 'domain', 'description', 'createdAt'].map(p => (
                  <p key={p}>
                    <span className='font-bold'>{p}: </span>
                    {s[p]}
                  </p>
                ))}
              </div>
              <div>
                {s.stats &&
                  Object.keys(s.stats).map(k => (
                    <p key={k}>
                      <span className='font-bold'>{k}: </span>
                      {s.stats[k]}
                    </p>
                  ))}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AdminSources;
