import { fetchClient } from './api';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { staleTimes } from './query-settings';
import _ from 'lodash';

const apiClient = fetchClient();

const getEmailConfig = async () => apiClient.get('/email/config').then(res => res.data);

export const useEmailConfig = () =>
  useQuery('email-config', getEmailConfig, { staleTime: staleTimes.long });

const sendTest = params => apiClient.post('/email/send-test', params);

export const useSendTest = () => {
  const queryClient = useQueryClient();
  return useMutation(({ username, template }) => sendTest({ username, template }), {
    onSuccess: () => queryClient.invalidateQueries('email-logs'),
  });
};

const getLogs = ({ template, subscriptionType, userId, includePaid, pageParam }) => {
  const params = new URLSearchParams(
    _.pickBy(
      { template, subscriptionType, userId, includePaid, page: pageParam || 1 },
      p => p !== undefined && p !== '',
    ),
  );
  return apiClient.get('/email/logs?' + params).then(res => res.data);
};

export const useLogs = ({ template, subscriptionType, userId, includePaid }) =>
  useInfiniteQuery(
    ['email-logs', template, subscriptionType, userId, includePaid],
    ({ pageParam }) => getLogs({ template, subscriptionType, userId, includePaid, pageParam }),
    {
      getNextPageParam: lastPage => lastPage?.meta?.nextPage || undefined,
    },
  );

const getEmailMetrics = ({ template, subscriptionType, days, includePaid }) => {
  const params = new URLSearchParams(
    _.pickBy({ template, subscriptionType, days, includePaid }, p => p !== undefined && p !== ''),
  );
  return apiClient.get('/email/metrics?' + params).then(res => res.data);
};

export const useEmailMetrics = ({ template, subscriptionType, days, includePaid }) =>
  useQuery(
    ['email-metrics', template, subscriptionType, days, includePaid],
    () => getEmailMetrics({ template, subscriptionType, days, includePaid }),
    { staleTime: staleTimes.long },
  );
