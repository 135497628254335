import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchClient } from './api';

const apiClient = fetchClient();

const getSource = linkCode => apiClient.get('/analytics/source/' + linkCode).then(res => res.data);

export const useGetSource = linkCode =>
  useQuery(['source', linkCode], () => getSource(linkCode), { enabled: !!linkCode });

const getSources = () => apiClient.get('/analytics/sources').then(res => res.data);

export const useGetSources = () => useQuery('sources', getSources);

const postSource = source => apiClient.post('/analytics/source', source).then(res => res.data);

export const useCreateSource = () => {
  const queryClient = useQueryClient();
  return useMutation(postSource, {
    onSuccess: () => queryClient.invalidateQueries('sources'),
  });
};
