import { useAdjustTrust, useGetUsers, useUpdateUser } from '../../services/users';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ta from 'time-ago';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import PaidIcon from '@mui/icons-material/Paid';
import Username from 'components/elements/Username';
import { useState } from 'react';

const AdminUsers = () => {
  const [username, setUsername] = useState('');
  const [usernameSearch, setUsernameSearch] = useState('');
  const usersQuery = useGetUsers(usernameSearch);
  const adjustTrust = useAdjustTrust();
  const updateUser = useUpdateUser();

  const togglePaid = (id, isPaid) => () => {
    updateUser.mutate({ id, isPaid });
  };

  const users = usersQuery.data?.pages?.reduce(
    (list, page) => [
      ...list,
      ...page.data.map(user => ({
        ...user,
        username: <Username username={user.username || user.id} />,
        signedUp: user.completedSignup && <CheckIcon />,
        TL: user.trustLevel,
        isPaid: (
          <button
            className={user.isPaid ? 'text-green-400' : 'text-unselected'}
            onClick={togglePaid(user.id, !user.isPaid)}
          >
            {' '}
            <PaidIcon />
          </button>
        ),
        trust: <Button onClick={onClickTrustEarned(user)}>{user.trustEarned}</Button>,
        created: ta.ago(user.createdAt),
        updated: ta.ago(user.updatedAt),
      })),
    ],
    [],
  );

  function onClickTrustEarned(user) {
    return () => {
      const delta = window.prompt('How much to add?');
      delta && adjustTrust.mutate({ id: user.id, delta });
    };
  }

  const fields = ['username', 'email', 'signedUp', 'isPaid', 'TL', 'trust', 'created', 'updated'];

  return (
    <div>
      <input
        placeholder='username'
        value={username}
        onChange={e => setUsername(e.target.value)}
        onKeyDown={e => e.key === 'Enter' && setUsernameSearch(username)}
      />

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {fields.map((field, i) => (
                <TableCell key={field} align={i !== 0 ? 'right' : undefined}>
                  {field}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map(user => (
              <TableRow key={user.id}>
                {fields.map((field, i) => (
                  <TableCell
                    key={field}
                    component={i === 0 ? 'th' : undefined}
                    scope={i === 0 ? 'row' : undefined}
                    align={i !== 0 ? 'right' : undefined}
                  >
                    {user[field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {usersQuery.hasNextPage && (
        <Button onClick={() => usersQuery.fetchNextPage()} variant='contained' margin='auto'>
          {usersQuery.isFetchingNextPage ? 'Loading more...' : 'Load More'}
        </Button>
      )}
    </div>
  );
};

export default AdminUsers;
