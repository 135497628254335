import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from 'assets/images/logo.png';
import signInBackgroundImg from 'assets/images/signInBackground.png';
import { makeStyles } from '@mui/styles';
import colors from 'assets/theme/base/colors';
import SignInForm from 'components/SignIn/SignInForm';
import HeadTags from '../components/HeadTags';

const useStyles = makeStyles({
  signIn: {
    backgroundColor: colors.white.focus,
    '& .first-section': {
      display: 'flex',
      '@media (max-width:767px)': {
        display: 'none',
      },
      backgroundImage: `url(${signInBackgroundImg})`,
      width: '100%',
      height: '100vh',
      backgroundSize: 'cover',
      img: {
        width: '173px',
      },
      '& p': {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '64px',
        lineHeight: '24px',
        color: colors.white.main,
        marginTop: '20px !important',
      },
    },
    '& .second-section': {
      padding: '30px 5%',
    },
  },
});

export default function SignIn({ newVisitor }) {
  const classes = useStyles();
  return (
    <Box className={classes.signIn}>
      <HeadTags title={newVisitor ? 'Sign Up' : 'Sign In'} />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} md={6}>
          <Box
            className='first-section'
            display={'flex'}
            alignItems='center'
            justifyContent='center'
            textAlign='center'
          >
            <Box alignSelf={'center'}>
              <img src={Logo} alt='Logo' />
              <p>Evincer</p>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6} md={6} display='flex' className='second-section'>
          <SignInForm
            newVisitor={newVisitor}
            redirect={'/'}
            sx={{ padding: '28px', background: 'white' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
