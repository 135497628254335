import { useGetMetrics } from '../../services/activity';
import { useMemo } from 'react';
import classnames from 'classnames';

const AdminMetricColumn = ({
  days,
  includePaid,
  includeInvited,
  subtractDays,
  denominator,
  onClick,
}) => {
  if (!subtractDays) subtractDays = 0;
  const query = useGetMetrics(days, includePaid, includeInvited);
  const subtractQuery = useGetMetrics(days - subtractDays, includePaid, includeInvited);

  const subtracted = useMemo(() => {
    const metrics = query.data?.data;
    const subtractMetrics = subtractQuery.data?.data;
    if (subtractDays && metrics && subtractMetrics)
      return Object.fromEntries(
        Object.keys(metrics).map(key => [key, metrics[key] - subtractMetrics[key]]),
      );
    else return metrics;
  }, [query, subtractQuery, subtractDays]);

  const values = useMemo(
    () =>
      subtracted
        ? Object.values(subtracted).map(v =>
            denominator === '/days'
              ? (v / (subtractDays || days)).toPrecision(3)
              : denominator === '/1'
              ? v
              : (v / subtracted[denominator.substring(1)]).toPrecision(3),
          )
        : [],

    [subtracted, denominator, subtractDays, days],
  );

  return (
    <div
      onClick={onClick}
      className={classnames(
        'flex flex-col items-end',
        { 'cursor-pointer': !!onClick },
        !subtractDays ? 'text-black' : 'text-secondary',
      )}
    >
      <p className='h-10 px-2'>{subtractDays || days}</p>
      {values.map((v, i) => (
        <p className='h-10 px-2' key={i}>
          {v}
        </p>
      ))}
    </div>
  );
};

export default AdminMetricColumn;
