import { useCreateComment } from '../../services/comments';
import { useContext, useEffect, useState } from 'react';
import UiContext from '../../state/UiContext';
import Button from '@mui/material/Button';
import { usePrompt } from '../../hooks/react-router-dom-extension';
import CommentBodyTextarea from './CommentBodyTextarea';
import AuthContext from '../../state/AuthContext';
import UsernameModal from '../UsernameModal';
import SignInModal from '../SignIn/SignInModal';

const CommentEditor = ({ subjectType, subject, setUnsavedComment }) => {
  const [expanded, setExpanded] = useState(false);
  const [body, setBody] = useState('');
  const { toastSuccess, toastError } = useContext(UiContext);
  const create = useCreateComment();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { isNamed, isSignedIn } = useContext(AuthContext);
  const [usernameModalOpen, setUsernameModalOpen] = useState(false);
  const [signinModalOpen, setSigninModalOpen] = useState(false);

  const prompt = 'Discard unsaved comment?';
  usePrompt(prompt, unsavedChanges);

  function onClick() {
    setExpanded(true);
  }

  useEffect(() => setUnsavedComment(unsavedChanges), [setUnsavedComment, unsavedChanges]);

  function onChangeBody(newBody) {
    setBody(newBody);
    setUnsavedChanges(true);
  }

  function onSubmit() {
    if (!isNamed) return setUsernameModalOpen(true);
    if (!body) return toastError('Body required');
    submit();
  }

  function submit() {
    create.mutate(
      { subjectType, subjectId: subject.id, body },
      {
        onSuccess: data => {
          toastSuccess(data.message);
          setBody('');
          setExpanded(false);
          if (!isSignedIn) setSigninModalOpen(true);

          setUnsavedChanges(false);
        },
        onError: data => toastError(data.message),
      },
    );
  }

  function onCancel() {
    if (unsavedChanges && !window.confirm(prompt)) return;

    setExpanded(false);
    setBody('');
    setUnsavedChanges(false);
  }

  return (
    <div id='comment-editor' className='space-y-2 > *'>
      <div className='rounded drop-shadow'>
        <CommentBodyTextarea
          body={body}
          className='p-1 w-full'
          placeholder={'Add your comment (+300 📜)'}
          onChange={onChangeBody}
          onClick={onClick}
          expanded
        />
      </div>

      {expanded && (
        <div className='flex flex-row justify-around'>
          <Button variant='contained' size='small' onClick={onSubmit} disabled={create.isLoading}>
            Submit
          </Button>
          <Button
            variant='containedTertiary'
            size='small'
            onClick={onCancel}
            disabled={create.isLoading}
          >
            Cancel
          </Button>
        </div>
      )}

      <UsernameModal
        open={usernameModalOpen}
        title={'Please choose a username to post this comment'}
        onSuccess={() => {
          setUsernameModalOpen(false);
          submit();
        }}
        onClose={() => setUsernameModalOpen(false)}
      />

      <SignInModal
        open={signinModalOpen}
        title={'Stay updated with replies'}
        prompt={'Get updates on people who respond to your comment.  Enter your email below.'}
        onSuccess={() => setSigninModalOpen(false)}
        onClose={() => setSigninModalOpen(false)}
        context='after-response'
      />
    </div>
  );
};

export default CommentEditor;
