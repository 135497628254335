import { useEffect, useState } from 'react';

const SvgContainer = ({ src, viewBox }) => {
  const [svgString, setSvgString] = useState('');

  useEffect(() => {
    fetch(src)
      .then(r => r.text())
      .then(text => {
        setSvgString(text);
      });
  }, [src]);

  return (
    <svg
      dangerouslySetInnerHTML={{ __html: svgString }}
      width='100%'
      height='100%'
      viewBox={viewBox}
    />
  );
};

export default SvgContainer;
