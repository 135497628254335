import Box from '@mui/material/Box';
import SectionTitle from './SectionTitle';
import { useTheme } from '@mui/material';

const TypographySection = () => {
  const theme = useTheme();

  const TypographyItem = ({ text, typoStyle }) => {
    return <p style={{ ...typoStyle, marginTop: 25, marginBottom: 25 }}>{text}</p>;
  };
  return (
    <Box style={{ marginTop: 20 }}>
      <SectionTitle
        title='Text Styles'
        desc='Typography is the art and technique of arranging type to make written language legible, readable, and appealing when displayed.'
      />
      <Box>
        <TypographyItem text='H1 Headline' typoStyle={theme.typography.h1} />
        <TypographyItem text='H2 Headline' typoStyle={theme.typography.h2} />
        <TypographyItem text='H3 Headline' typoStyle={theme.typography.h3} />
        <TypographyItem text='Table Data' typoStyle={theme.typography.tableData} />
        <TypographyItem
          text='Table Column Headers (14px) / Table Column Headers (12px)'
          typoStyle={theme.typography.tableColumnHeader}
        />
        <TypographyItem text='Opp. Card Text' typoStyle={theme.typography.cardText} />
        <TypographyItem text='Opp. Card Subtitle' typoStyle={theme.typography.cardSubTitle} />
        <TypographyItem text='Form Filled Text' typoStyle={theme.typography.formFilledText} />
        <TypographyItem text='Form Title' typoStyle={theme.typography.formTitle} />
        <TypographyItem text='Nav Selected' typoStyle={theme.typography.navSelected} />
        <TypographyItem text='Nav Regular' typoStyle={theme.typography.navRegular} />
        <TypographyItem text='B1 Button' typoStyle={theme.typography.b1Button} />
        <TypographyItem text='B2 Button' typoStyle={theme.typography.b2Button} />
      </Box>
    </Box>
  );
};

export default TypographySection;
