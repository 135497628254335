import SubjectOutlinedIcon from '@mui/icons-material/SubjectOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import DefaultTheoryImage from '../../assets/images/Theory/theory.png';
import CreatedAt from '../elements/CreatedAt';
import { Link as RouterLink } from 'react-router-dom';
import React, { useContext, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { track } from '../../services/analytics';
import { useTags } from '../../services/tags';
import { useTheorySubscription } from '../../services/subscription';
import classnames from 'classnames';
import SignInModal from '../SignIn/SignInModal';
import Stat from './Stat';
import RankPanel from '../Rank/RankPanel';
import UiContext from '../../state/UiContext';
import { FlameSquareIcon } from '../icons';
import UserAvatar from '../elements/UserAvatar';
import { useIsAbTest } from '../../services/ab-tests';
import { theoryPath } from '../../util/id';

const TheoryCard = ({ theory, user, date, rankable, subscribeButton }) => {
  const canonicalTags = useTags().data?.data;
  const { isXs } = useContext(UiContext);

  const { subscribed, toggleSubscribe, promptForCredentials, setPromptForCredentials } =
    useTheorySubscription({ theory });

  const testNoSubscribeOnCards = useIsAbTest('no-subscribe-on-cards');

  const href = theoryPath(theory) || '#';

  function onClick() {
    track('click-inquiry', { inquiry: theory });
  }

  const primaryTag = useMemo(
    () =>
      canonicalTags &&
      theory &&
      theory.tags.find(t => canonicalTags.some(ct => ct.slug === t.slug))?.slug,
    [canonicalTags, theory],
  );

  return (
    <div className='flex flex-row space-x-3'>
      {theory ? (
        <div className='relative shrink-0'>
          <RouterLink to={href} onClick={onClick}>
            <img
              className='object-cover rounded-lg h-24 w-24 md:h-40 md:w-40'
              src={theory.image?.thumbnail?.url || DefaultTheoryImage}
              alt='Theory Img'
            />
          </RouterLink>

          {rankable && (
            <div className='absolute rounded bg-white bg-opacity-90 bottom-2 left-3 right-3 md:left-6 md:right-6 h-6 md:h-9 text-xs md:text-base'>
              <RankPanel subject={theory} subjectKey='theory' wFull={true} />
            </div>
          )}
        </div>
      ) : (
        <Skeleton variant='rounded' height={96} width={96} />
      )}
      <div className='w-full flex flex-col h-24 md:h-40'>
        {theory ? (
          <div className='flex flex-row text-2xs md:text-sm items-center'>
            <p className='text-unselected'>#{primaryTag}</p>
            <p className='ml-1'>•</p>
            <CreatedAt className='ml-1' createdAt={date} />
            {(!testNoSubscribeOnCards || subscribeButton) && (
              <button /* Subscribe */
                className={classnames(
                  'px-1 ml-auto rounded border shadow',
                  subscribed
                    ? 'bg-white border-primary text-primary'
                    : 'bg-primary text-white border-white px-3',
                )}
                onClick={() => {
                  toggleSubscribe();
                }}
              >
                {subscribed ? 'Unsubscribe' : 'Subscribe'}
              </button>
            )}
            <SignInModal
              open={promptForCredentials}
              title='Enter your email to subscribe to this inquiry'
              onClose={() => setPromptForCredentials(false)}
              context='theory card'
            />
          </div>
        ) : (
          <Skeleton height={30} />
        )}
        <RouterLink to={href} onClick={onClick}>
          <p className='text-sm md:text-lg 2xl:text-xl text-regular font-bold text-pretty'>
            {theory?.title}
          </p>
        </RouterLink>
        {theory ? (
          <div className='mt-auto text-2xs md:text-xs xl:text-sm mb-1 flex flex-row items-center'>
            <UserAvatar user={user} className='size-5 md:size-7 rounded-full' />
            <p className='ml-2 text-unselected'>{'@' + theory?.user?.username}</p>
            <div className='flex flex-row space-x-5 items-end ml-5 mt-auto mb-1'>
              {theory?.evidenceCount !== undefined && (
                <Stat
                  title='Evidence: cited arguments in either the Yes or No column that have reached top-10'
                  Icon={TextsmsOutlinedIcon}
                  number={theory?.evidenceCount}
                />
              )}
              {theory?.rebuttalCount !== undefined && (
                <Stat
                  title='Rebuttals: posts challenging the Evidence/Rebuttals in this Inquiry'
                  Icon={FlameSquareIcon}
                  iconClassName={'mb-1'}
                  number={theory?.rebuttalCount}
                />
              )}
              {theory?.swayCount !== undefined && !isXs && (
                <Stat
                  title="Sways: the number of times Evidence within has changed someone's opinion"
                  Icon={PsychologyOutlinedIcon}
                  number={theory?.swayCount}
                />
              )}
              {/*{theory?.promotes !== undefined && (*/}
              {/*  <Stat title='Promotions' Icon={ThumbUpOutlinedIcon} number={theory.promotes} />*/}
              {/*)}*/}
              {/*{theory?.demotes !== undefined && (*/}
              {/*  <Stat title='Demotions' Icon={ThumbDownOutlinedIcon} number={theory.demotes} />*/}
              {/*)}*/}
              {theory?.commentCount !== undefined && (
                <Stat
                  title='Comments: discussions below the Evidence colummns'
                  Icon={SubjectOutlinedIcon}
                  number={theory.commentCount}
                />
              )}
            </div>
          </div>
        ) : (
          <Skeleton height={60} />
        )}
      </div>
    </div>
  );
};

export default TheoryCard;
