import { useRef } from 'react';

const ImagePicker = ({ label, imageFile, setImageFile }) => {
  const imageInput = useRef();
  return (
    <>
      <input
        ref={imageInput}
        accept='image/*'
        type='file'
        hidden
        onChange={e => setImageFile(e.target.files[0])}
      />
      <button
        className={
          'font-bold p-2 ' +
          (imageFile ? 'text-unselected' : 'border rounded border-primary text-regular')
        }
        onClick={() => imageInput.current.click()}
      >
        {!imageFile ? label : imageFile.name + ' Attached'}
      </button>
    </>
  );
};

export default ImagePicker;
