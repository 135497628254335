import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';

import UiContext from 'state/UiContext';
import { disableSubscription } from 'services/subscription-settings';

import { useQueryClient } from 'react-query';
import SubscriptionSettings from 'components/SubscriptionSettings/SubscriptionSettings';
import { subscriptionSettingsQueryKeys } from 'services/subscription-settings';
import AuthContext from '../state/AuthContext';
import { useTheories } from '../services/theories';
import { useInfiniteScroll } from '../util/dom';
import { flattenPages } from '../util/query';
import TheoryGrid from '../components/Cards/TheoryGrid';

const SubscriptionSettingsPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { toastSuccess, toastError } = useContext(UiContext);
  const { isSignedIn } = useContext(AuthContext);
  const [subscriptionComplete, setSubscriptionComplete] = useState(false);
  const queryClient = useQueryClient();
  const theoriesQuery = useTheories({ status: 'published' });
  useInfiniteScroll(theoriesQuery);
  const theories = flattenPages(theoriesQuery.data?.pages);

  const { token, subscribe } = useMemo(() => {
    const params = new URLSearchParams(search);
    return {
      token: params.get('token'),
      subscribe: params.get('subscribe'),
    };
  }, [search]);

  useEffect(
    () => {
      if (!subscriptionComplete) {
        return;
      }

      if (isSignedIn) {
        queryClient.invalidateQueries(subscriptionSettingsQueryKeys.all);

        // Remove the query parameters from the URL
        navigate('', { replace: true });
      } else {
        navigate('/');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptionComplete, isSignedIn],
  );

  const disable = useCallback(async () => {
    try {
      const res = await disableSubscription({ idOrToken: token });
      toastSuccess(res.message);
    } catch (e) {
      toastError(e.message);
    } finally {
      setSubscriptionComplete(true);
    }
  }, [toastError, toastSuccess, token]);

  useEffect(() => {
    if (token && subscribe === 'false') {
      setTimeout(() => disable().then(() => {}), 1000);
    }
  }, [token, subscribe, disable]);

  const unsubbed = theories.filter(t => !t.subscription);

  return (
    <Stack spacing={3} py={2} px={4}>
      <p className='text-2xl font-bold p-4 text-unselected'>Subscription settings</p>
      <SubscriptionSettings />

      {!!unsubbed.length && (
        <p className='text-2xl font-bold p-4 text-unselected pt-14'>
          What else are you interested in?
        </p>
      )}
      <TheoryGrid theories={unsubbed} subscribeButton={true} />
    </Stack>
  );
};

export default SubscriptionSettingsPage;
