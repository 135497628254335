import Divider from '@mui/material/Divider';

import SlideDownModal from '../wrappers/SlideDownModal';
import SignInForm from './SignInForm';

export default function SignInModal({ open, title, prompt, onSuccess, onClose, context }) {
  return (
    <SlideDownModal open={open} title={title} onClose={onClose}>
      <Divider sx={{ my: 0 }} />

      {open && (
        <SignInForm
          compact
          prompt={prompt || ' '}
          sx={{ padding: '16px' }}
          newVisitor
          context={context}
          onSuccess={onSuccess}
        />
      )}
    </SlideDownModal>
  );
}
