import { createSession, usePrivileges, useProducts, useSubscriptions } from '../services/payments';
import UiContext from '../state/UiContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useActivity } from '../services/activity';
import { useMe } from '../services/users';
import SignInModal from '../components/SignIn/SignInModal';
import AuthContext from '../state/AuthContext';
import classnames from 'classnames';
import BannerBackground from '../assets/images/home.png';
import Heading from '../components/Banners/Heading';
import { useInfiniteScroll } from '../util/dom';
import { flattenPages } from '../util/query';

const Membership = () => {
  const products = useProducts().data?.data;
  const subscriptions = useSubscriptions().data?.data;
  const { toastError } = useContext(UiContext);
  const { isSignedIn } = useContext(AuthContext);
  const me = useMe().data?.data;
  const [chosenPrice, setChosenPrice] = useState();
  const paymentsQuery = useActivity({
    idOrUsername: me?.id,
    types: ['membership-pay'],
    enabled: !!me,
  });
  useInfiniteScroll(paymentsQuery);
  const payments = flattenPages(paymentsQuery.data?.pages);

  const privileges = usePrivileges().data?.data;

  const submit = useCallback(
    () =>
      isSignedIn &&
      chosenPrice &&
      createSession({
        priceLookup: chosenPrice,
        onSuccess: data => {
          window.location.href = data?.data;
        },
        onError: error => {
          toastError(error.message || error);
          setChosenPrice(undefined);
        },
      }),
    [isSignedIn, chosenPrice, toastError, setChosenPrice],
  );

  const privilegeNames = {
    'theory-create': 'Inquiries/month',
    'evidence-all': 'All Evidence & Rebuttals',
    comment: 'Comment and Respond',
    trust: 'Scrolls/month (📜)',
  };

  useEffect(() => chosenPrice && isSignedIn && submit(), [chosenPrice, isSignedIn, submit]);

  const activePriceIds = subscriptions?.data
    ?.filter(sub => sub.status === 'active')
    .map(sub => sub.items?.data[0].price.id);

  return (
    <div>
      <Heading text='Become a Member' backgroundImg={BannerBackground} />

      <div className='mx-4 md:mx-12'>
        <p className='mt-8 text-balance text-center'>
          Help establish an uncensored evidence platform where dissent and consensus meet as equals.
        </p>

        <div className='gap-8 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 mt-9'>
          {products
            ?.filter(p => p.prices?.length)
            .map(product => (
              <div key={product.id} className='p-7 border rounded shadow-lg'>
                <div className='flex flex-row justify-between space-x-4'>
                  <div>
                    <p className='text-3xl'>{product.name}</p>
                    <p
                      className={classnames(
                        activePriceIds?.includes(product.prices[0].id)
                          ? 'font-bold'
                          : 'font-normal',
                        'text-nowrap',
                      )}
                    >
                      ${product.prices[0].unit_amount / 100} USD/month
                    </p>
                  </div>
                  <button
                    disabled={chosenPrice}
                    className={classnames(
                      !activePriceIds?.includes(product.prices[0].id)
                        ? 'bg-primary text-white'
                        : 'border',
                      { 'blur-sm': chosenPrice === product.prices[0].lookup_key },
                      'rounded font-bold px-3',
                    )}
                    onClick={() => setChosenPrice(product.prices[0].lookup_key)}
                  >
                    {activePriceIds?.includes(product.prices[0].id)
                      ? 'Modify'
                      : activePriceIds?.length
                      ? 'Switch'
                      : 'Join'}
                  </button>
                </div>

                <div className='mt-8'>
                  {privileges
                    .filter(
                      priv => priv.tier === product.metadata.tier && privilegeNames[priv.privilege],
                    )
                    .map(priv => (
                      <p className='text-nowrap py-3 border-t' key={priv.id}>
                        {priv.quantity
                          ? `${priv.quantity > 1000 ? priv.quantity / 1000 + 'k' : priv.quantity} `
                          : ''}
                        {priv.rate && priv.rate < 1000 ? `${priv.rate} ` : ''}
                        {privilegeNames[priv.privilege]}
                      </p>
                    ))}
                </div>
              </div>
            ))}
        </div>

        {payments?.length > 0 && (
          <div className='mt-5'>
            <p className='font-bold'>Past membership rewards</p>
            {payments.map(payment => (
              <div className='grid grid-cols-2' key={payment.id}>
                <p>{payment.createdAt.substr(0, 10)}</p>
                <p className='text-right'>{payment.trustEarned} 📜</p>
              </div>
            ))}
          </div>
        )}

        <p className='mt-10 mb-10 text-balance text-sm text-center'>
          📜 At most 3000 scrolls can be applied to your level for each day of your account's age.
          Scrolls over this amount will be applied as time passes.
        </p>
      </div>

      <SignInModal
        open={chosenPrice && !isSignedIn}
        title={'Membership requires a valid email'}
        prompt={'Please enter your email address here'}
        onClose={() => setChosenPrice(undefined)}
        context='membership'
      />
    </div>
  );
};

export default Membership;
