import CreatedAt from 'components/elements/CreatedAt';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import markdown from 'util/markdown';
import { track } from '../../services/analytics';
import UserAvatar from '../elements/UserAvatar';

const NotificationItem = ({ notification }) => {
  return (
    <div
      className='p-[5px] w-[300px] cursor-pointer relative'
      onClick={() => {
        track('click-notification', { type: notification.type });
      }}
    >
      <div className='flex'>
        <UserAvatar user={notification.actingUser} className='size-11 min-w-11 rounded' />
        <div className='ml-2.5'>
          <div className='flex justify-between h-5'>
            {notification.actingUser?.username && (
              <span className='normal-case bg-background px-[7px] py-0.5 text-primary text-[8px] rounded-sm mr-2.5'>
                @{notification.actingUser?.username}
              </span>
            )}
            <div className='text-2xs self-center flex flex-row items-baseline text-unselected'>
              {!notification.read ? (
                <span className='size-[7px] bg-secondary rounded-full mr-0.5' />
              ) : (
                <AccessTimeOutlinedIcon sx={{ width: '8px', height: '8px' }} />
              )}
              <div className='ml-0.5 opacity-90 text-2xs'>
                <CreatedAt createdAt={notification.createdAt} />
              </div>
            </div>
          </div>
          <div
            className='normal-case mt-2 text-xs md:text-sm break-word'
            dangerouslySetInnerHTML={{
              __html: markdown(notification.text),
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default NotificationItem;
