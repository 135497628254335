import classnames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import EvidenceEditor from '../Evidence/EvidenceEditor';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import UiContext from '../../state/UiContext';
import { useIsTrusted } from '../../services/config';
import { useIsAbTest } from '../../services/ab-tests';
import AuthContext from '../../state/AuthContext';
import { NoIcon, YesIcon } from '../Evidence/YesNoIcon';

const AddEvidenceButton = ({ side, adding, onClick, setAdding, setPastedEvidence }) => {
  const { clipboardType, paste } = useContext(UiContext);
  const [isTrustedToPaste] = useIsTrusted('copyPaste');

  function onClickPaste() {
    setAdding(true);
    setPastedEvidence(paste());
  }
  return (
    <div className='border-t mx-4 border-neutral-200 mt-2 md:mt-3 pt-2 text-2xs md:text-sm font-bold text-primary'>
      {!adding && (
        <div className='flex flex-row space-x-1' onClick={onClick}>
          <p>+ Add "{side.toUpperCase()}" Evidence</p>
          {clipboardType === 'evidence' && isTrustedToPaste && (
            <Tooltip title='Paste'>
              <IconButton onClick={onClickPaste}>
                <ContentPasteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

const YesNoBar = ({
  theory,
  maximize,
  collapseEvents,
  onToggleNo,
  onToggleYes,
  onNoEditorExpand,
  onYesEditorExpand,
}) => {
  const [pastedEvidence, setPastedEvidence] = useState();
  const noRef = useRef();
  const yesRef = useRef();
  const [adding, setAdding] = useState('');
  const [scrollTop, setScrollTop] = useState(0);
  const [show, setShow] = useState(true);
  const { mdPlus } = useContext(UiContext);
  const { me } = useContext(AuthContext);
  const showAdd = !useIsAbTest('tl1-add-evidence') || me?.trustLevel >= 1;

  const onClickAddNo = () => {
    if (maximize !== 'no') onToggleNo();
    setAdding('no');
  };
  const onClickAddYes = () => {
    if (maximize !== 'yes') onToggleYes();
    setAdding('yes');
  };

  useEffect(() => {
    if (noRef.current) {
      const noPos = maximize === 'yes' ? noRef.current.clientWidth / 2 : 0;
      noRef.current.parentElement.scrollTo({ left: noPos, behavior: 'smooth' });
    }
    if (yesRef.current) {
      const yesPos = maximize === 'no' ? 0 : yesRef.current.clientWidth / 2;
      yesRef.current.parentElement.scrollTo({ left: yesPos, behavior: 'smooth' });
    }
  }, [maximize, noRef]);

  useEffect(() => {
    const onScroll = () => {
      const newTop = document.documentElement.scrollTop;
      setShow(newTop < scrollTop);
      setScrollTop(newTop);
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop, setScrollTop]);

  return (
    <div
      id='yes-no-bar'
      className={classnames(
        'mt-5 md:mt-8 z-20 bg-gradient-to-b from-neutral-200 to-white border-t-2 border-neutral-300 py-3 md:py-5 grid grid-cols-2 transition-[top]',
        show || adding ? 'top-0' : mdPlus ? '-top-28' : '-top-20',
        { sticky: !adding },
      )}
    >
      <div className='overflow-hidden cursor-pointer'>
        <div ref={noRef} style={{ width: '200%' }}>
          <div className='flex flex-row items-center' onClick={onToggleNo}>
            <div className='text-[10px] sticky ml-4 left-4'>
              <NoIcon />
            </div>

            <p className='text-sm md:text-lg font-bold text-regular ml-2 mr-auto'>"No" Evidence</p>

            <div className='w-1/2'>
              <p className='ml-10 text-xs md:text-sm '>show | →</p>
            </div>
          </div>

          {showAdd && (
            <div className='w-1/2'>
              <AddEvidenceButton
                side='no'
                setAdding={setAdding}
                adding={adding}
                onClick={onClickAddNo}
                setPastedEvidence={setPastedEvidence}
              />
            </div>
          )}
        </div>
      </div>

      <div className='overflow-hidden cursor-pointer'>
        <div ref={yesRef} style={{ width: '200%' }}>
          <div className='flex flex-row w-full items-center' onClick={onToggleYes}>
            <div className='w-1/2 flex flex-row-reverse'>
              <p className='mr-10 text-xs md:text-sm '>← | show</p>
            </div>

            <div className='text-[10px] sticky ml-4 right-4'>
              <YesIcon />
            </div>

            <p className='text-sm md:text-lg font-bold text-regular ml-2'>"Yes" Evidence</p>
          </div>

          {showAdd && (
            <div className='flex flex-row-reverse'>
              <div className='w-1/2'>
                <AddEvidenceButton
                  side='yes'
                  setAdding={setAdding}
                  adding={adding}
                  onClick={onClickAddYes}
                  setPastedEvidence={setPastedEvidence}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {adding && (
        <div className='col-span-2 mx-2'>
          <EvidenceEditor
            copyEvidence={pastedEvidence}
            theory={theory}
            level={1}
            isFor={adding === 'yes'}
            parent={null}
            onExpand={adding === 'yes' ? onYesEditorExpand : onNoEditorExpand}
            collapseEvents={collapseEvents}
            onClose={() => {
              setAdding('');
              setPastedEvidence(undefined);
            }}
          />
        </div>
      )}
    </div>
  );
};
export default YesNoBar;
