import Username from '../elements/Username';
import classnames from 'classnames';
import { useRef, useState } from 'react';

const Citation = ({ evidence }) => {
  const [clampCitation, setClampCitation] = useState(true);
  const ref = useRef();

  if (!evidence?.citation && !evidence?.link && !evidence?.authors?.some(a => a.username))
    return null;
  return (
    <div className='pl-2 mt-2 mr-2 text-2xs md:text-xs font-light border-l-2 border-teal-600 break-word'>
      <div className='flex flex-row space-x-2'>
        {evidence.authors
          ?.filter(a => a.username)
          .map(a => (
            <Username username={a.username} key={a.username} />
          ))}
      </div>
      {!!evidence.citation && (
        <p
          ref={ref}
          className={classnames(clampCitation ? 'cursor-pointer line-clamp-3' : 'select-text')}
          onClick={() => setClampCitation(false)}
        >
          {evidence.citation}
        </p>
      )}
      {!!evidence.link && (
        <p className='mt-1'>
          <a
            className='text-primary visited:text-gray-400 hover:text-gray-400 line-clamp-1'
            href={evidence.link}
            target='_blank'
            rel='noreferrer'
          >
            {evidence.link}
          </a>
        </p>
      )}
    </div>
  );
};

export default Citation;
