import classnames from 'classnames';

const borderColors = {
  10: 'border-blue-400',
  30: 'border-violet-400',
  50: 'border-fuchsia-400',
  70: 'border-rose-300',
  90: 'border-orange-400',
};

const opinions = { 10: 'No', 30: 'Unlikely', 50: 'Maybe', 70: 'Likely', 90: 'Yes' };

const Likelihood = ({ lh, selected, onClick, img, text }) => {
  return (
    <div
      className={classnames(
        'relative text-base h-9 rounded-full border flex items-center px-2 lg:px-3',
        borderColors[lh],
        { 'aspect-square': !!img && !text },
        { '!cursor-pointer': !!onClick },
        {
          'bg-gray-500': selected,
        },
      )}
      onClick={onClick}
    >
      {img && (
        <img
          className={classnames('absolute rounded-full top-0 left-0 w-full h-full object-cover')}
          src={img}
          alt='them'
        />
      )}
      {(!img || text) && (
        <p
          className={classnames(
            'font-bold text-sm md:text-base z-10',
            selected || img ? 'text-white' : 'text-unselected',
          )}
        >
          {text || opinions[lh]}
        </p>
      )}
    </div>
  );
};

export default Likelihood;
