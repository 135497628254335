import Snackbar from '@mui/material/Snackbar';
import { useContext, useEffect, useState } from 'react';
import UiContext from '../state/UiContext';
import { Alert, Tooltip } from '@mui/material';

export default function Toast() {
  const { toastQueue, setToastQueue } = useContext(UiContext);
  const [open, setOpen] = useState(false);

  const currentToast = !!toastQueue.length && toastQueue[0];

  useEffect(() => {
    if (currentToast) setOpen(true);
  }, [currentToast]);

  function onClose(event, reason) {
    if (reason === 'clickaway') return;

    setOpen(false);
  }

  function onExit() {
    if (toastQueue.length > 1) setOpen(true);

    setToastQueue(prev => prev.slice(1));
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={2500}
      onClose={onClose}
      TransitionProps={{ onExited: onExit }}
    >
      <Tooltip title={currentToast.tooltip || ''}>
        <Alert variant='filled' severity={currentToast?.type} sx={{ width: '100%' }}>
          {currentToast?.message}
        </Alert>
      </Tooltip>
    </Snackbar>
  );
}
