import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import UiContext from 'state/UiContext';
import { useVerifyToken } from 'services/auth';
import AuthContext from '../state/AuthContext';
import { useMe } from '../services/users';

const SignUpWithLink = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { toastError } = useContext(UiContext);
  const { setSigningIn } = useContext(AuthContext);
  const signIn = useVerifyToken();
  const me = useMe();
  const [tokenAttempted, setTokenAttempted] = useState(false);

  useEffect(() => {
    if (!token || me.isLoading || signIn.isLoading || tokenAttempted) {
      return;
    }

    setTokenAttempted(true);
    signIn.mutate(
      { token: token },
      {
        onSuccess: () => navigate('/'),
        onError: res => {
          toastError(res.message);
        },
      },
    );
  }, [token, me, signIn, navigate, toastError, setSigningIn, tokenAttempted, setTokenAttempted]);

  return (
    <div className={'flex flex-col space-y-20 items-center'}>
      {signIn.isLoading && <p className={'font-bold'}>Signing in...</p>}
      {signIn.isLoading && <img src='/animatedlogo.gif' alt='Logo' />}
    </div>
  );
};

export default SignUpWithLink;
