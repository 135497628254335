import { useEffect, useState } from 'react';
import _ from 'lodash';

export const reachedBottom = element =>
  element.scrollHeight - element.scrollTop - element.clientHeight < 100;

export const useInfiniteScroll = (query, options = {}) => {
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const defaults = { active: true, threshold: 500 };
    _.defaults(options, defaults);

    const onScroll = () => {
      const el = options.el || document.documentElement;
      if (options.active && el.scrollHeight - el.scrollTop - el.clientHeight < options.threshold)
        setRefetch(true);
    };
    (options.el || window).addEventListener('scroll', onScroll);
    if (query.isFetched) onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, [query, options]);

  useEffect(() => {
    if (refetch && query.hasNextPage && !query.isFetchingNextPage) {
      query.fetchNextPage();
      setRefetch(false);
    }
  }, [refetch, setRefetch, query]);
};

export const thisOrSomeAncestor = (element, fn) => {
  let currentElement = element;
  while (currentElement) {
    if (fn(currentElement)) return true;
    currentElement = currentElement.parentElement;
  }
  return false;
};

export const isElementInScrollView = element => {
  if (!element) return false;
  let rect = element.getBoundingClientRect();
  let scrollView = document.documentElement;

  const height = window.innerHeight || scrollView.clientHeight;
  const width = window.innerWidth || scrollView.clientWidth;
  return rect.top >= 0 && rect.left >= 0 && rect.bottom <= height && rect.right <= width;
};
