import Popper from '@mui/material/Popper';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAdvanceTutorial, useMe } from '../services/users';
import classnames from 'classnames';
import { thisOrSomeAncestor } from '../util/dom';

const TutorialStep = ({ placement, anchorId, nextId, nextClass, name, step, text, totalSteps }) => {
  const isLastStep = +step === totalSteps;
  const advanceTutorial = useAdvanceTutorial();
  const [dismissed, setDismissed] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [popperRef, setPopperRef] = useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [popperPlacement, setPopperPlacement] = useState(placement);

  const colClass = [
    'grid-cols-1',
    'grid-cols-2',
    'grid-cols-3',
    'grid-cols-4',
    'grid-cols-5',
    'grid-cols-6',
    'grid-cols-7',
    'grid-cols-8',
  ][totalSteps - 1];

  useEffect(() => setDismissed(false), [step]);

  useEffect(() => {
    const t = window.setInterval(() => {
      const el = document.getElementById(anchorId);
      setAnchorEl(el);
      if (el) window.clearInterval(t);
    }, 500);
    return () => window.clearInterval(t);
  }, [anchorId, setAnchorEl]);

  const onClickOk = useCallback(() => {
    setDismissed(true);
    setAnchorEl(null);
    advanceTutorial.mutate({
      name,
      step: step + 1,
      ...(isLastStep ? { status: 'finished' } : undefined),
    });
  }, [advanceTutorial, name, isLastStep, setDismissed, step]);

  useEffect(() => {
    const onClickAnywhere = e =>
      ((nextId && thisOrSomeAncestor(e.target, el => el.id === nextId)) ||
        (nextClass && thisOrSomeAncestor(e.target, el => el.classList.contains(nextClass)))) &&
      onClickOk();
    document.removeEventListener('click', onClickAnywhere, false);
    document.addEventListener('click', onClickAnywhere, false);
    return () => document.removeEventListener('click', onClickAnywhere, false);
  }, [nextId, nextClass, onClickOk]);

  const onClickClose = useCallback(() => {
    setDismissed(true);
    advanceTutorial.mutate({ name, status: 'skipped' });
  }, [setDismissed, advanceTutorial, name]);

  const onClickStep = useCallback(
    s => {
      if (s < step) {
        setDismissed(true);
        setAnchorEl(null);
        advanceTutorial.mutate({ name, step: s });
      }
    },
    [setDismissed, advanceTutorial, name, step],
  );

  useEffect(() => {
    if (!popperRef) return;

    const observer = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'data-popper-placement') {
          const v = mutation.target.getAttribute('data-popper-placement');
          if (v) setPopperPlacement(v);
        }
      });
    });

    observer.observe(popperRef, { attributes: true });

    const v = popperRef.current?.getAttribute('data-popper-placement');
    if (v) setPopperPlacement(v);

    // Cleanup function to disconnect the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, [popperRef]);

  const offset = useMemo(
    () => ({
      '-top-1.5': popperPlacement?.startsWith('bottom'),
      '-bottom-1.5': popperPlacement?.startsWith('top'),
      '-left-1.5': popperPlacement?.startsWith('right'),
      '-right-1.5': popperPlacement?.startsWith('left'),
    }),
    [popperPlacement],
  );

  if (!anchorEl) return null;

  return (
    <Popper
      open={!dismissed}
      anchorEl={anchorEl}
      placement={placement || 'bottom-end'}
      className={classnames('rounded-lg bg-slate-700 z-50')}
      ref={setPopperRef}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 6],
          },
        },
        {
          name: 'flip',
          enabled: true,
          options: {
            altBoundary: true,
            padding: 8,
          },
        },
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: false,
            padding: 8,
          },
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            padding: 8,
            element: arrowRef,
          },
        },
      ]}
    >
      <div>
        <div className='flex text-white text-sm md:text-base max-w-xs md:max-w-lg'>
          <p className='p-3 md:p-4 md:text-lg'>{text}</p>
          <div className='flex flex-col'>
            {!isLastStep && (
              <button onClick={onClickClose} className='p-1 mr-2 self-end'>
                X
              </button>
            )}
            <button
              onClick={onClickOk}
              className='border border-primary rounded py-2 px-3 mt-auto mx-3 mb-3 self-end'
            >
              {nextId || nextClass ? 'Skip' : !isLastStep ? 'Next' : 'Done'}
            </button>
          </div>
        </div>
        <div className={'grid gap-2 mx-2 mb-2 ' + colClass}>
          {Array(totalSteps)
            .fill(null)
            .map((_, i) => (
              <div
                key={i}
                onClick={() => onClickStep(i + 1)}
                className={classnames(
                  'h-1',
                  { 'bg-white': i + 1 <= step },
                  { 'cursor-pointer': i + 1 < step },
                )}
              />
            ))}
        </div>
      </div>
      <span
        className={classnames(
          'bg-inherit invisible size-3 absolute',
          "before:absolute before:size-3 before:content-[''] before:bg-inherit before:visible before:rotate-45",
          offset,
        )}
        ref={setArrowRef}
      />
    </Popper>
  );
};

const Tutorial = ({ name, steps }) => {
  const me = useMe().data?.data;
  const currentStep = me ? me.tutorials?.[name] || 1 : NaN;
  const step = !isNaN(currentStep) && steps[currentStep - 1];

  if (!step) return null;
  else
    return (
      <TutorialStep
        name={name}
        step={currentStep}
        anchorId={step.anchorId}
        nextId={step.nextId}
        nextClass={step.nextClass}
        text={step.text}
        totalSteps={steps.length}
        placement={step.placement}
      />
    );
};
export default Tutorial;
