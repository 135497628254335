import { useRespond } from '../../services/comments';
import { useContext, useEffect, useState } from 'react';
import UiContext from '../../state/UiContext';
import Button from '@mui/material/Button';
import { usePrompt } from '../../hooks/react-router-dom-extension';
import CommentBodyTextarea from './CommentBodyTextarea';
import AuthContext from '../../state/AuthContext';
import UsernameModal from '../UsernameModal';
import SignInModal from '../SignIn/SignInModal';

const CommentResponseEditor = ({ subject, comment, setUnsavedResponse, onSuccess }) => {
  const [expanded, setExpanded] = useState(false);
  const [body, setBody] = useState('');
  const { toastSuccess, toastError } = useContext(UiContext);
  const respond = useRespond();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { isNamed, isSignedIn } = useContext(AuthContext);
  const [usernameModalOpen, setUsernameModalOpen] = useState(false);
  const [signinModalOpen, setSigninModalOpen] = useState(false);

  const prompt = 'Discard unsaved response?';
  usePrompt(prompt, unsavedChanges);

  useEffect(
    () => subject && setUnsavedResponse && setUnsavedResponse(subject.id, unsavedChanges),
    [unsavedChanges, subject, setUnsavedResponse],
  );

  function onChangeBody(newBody) {
    setBody(newBody);
    setUnsavedChanges(true);
  }

  function onSubmit() {
    if (!isNamed) return setUsernameModalOpen(true);
    if (!body) return toastError('Body required');
    submit();
  }

  function submit() {
    respond.mutate(
      { subjectId: subject?.id, id: comment.id, body },
      {
        onSuccess: data => {
          toastSuccess(data.message);
          setBody('');
          setExpanded(false);
          setUnsavedChanges(false);
          if (!isSignedIn) setSigninModalOpen(true);
          if (onSuccess) onSuccess();
        },
        onError: data => toastError(data.message),
      },
    );
  }

  function onCancel() {
    if (unsavedChanges && !window.confirm(prompt)) return;
    setExpanded(false);
    setBody('');
    setUnsavedChanges(false);
  }

  return (
    <div className='w-full space-y-1 > *'>
      <CommentBodyTextarea
        placeholder={'Your Response (+300 📜)'}
        body={body}
        onChange={onChangeBody}
        onClick={() => setExpanded(true)}
        expanded={expanded}
        disabled={respond.isLoading}
      />

      {expanded && (
        <div className='flex flex-row justify-around'>
          <Button variant='contained' size='small' onClick={onSubmit} disabled={respond.isLoading}>
            Submit
          </Button>
          <Button variant='containedTertiary' size='small' onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}

      <UsernameModal
        open={usernameModalOpen}
        title={'Please choose a username to post this response'}
        onSuccess={() => {
          setUsernameModalOpen(false);
          submit();
        }}
        onClose={() => setUsernameModalOpen(false)}
      />

      <SignInModal
        open={signinModalOpen}
        title={'Stay updated with replies'}
        prompt={'Get updates on people who also respond to this comment.  Enter your email below.'}
        onSuccess={() => setSigninModalOpen(false)}
        onClose={() => setSigninModalOpen(false)}
        context='after-response'
      />
    </div>
  );
};

export default CommentResponseEditor;
