import { useRankEvidence } from '../services/evidence';
import { useRankTheory } from '../services/theories';
import { useCallback, useContext, useMemo, useState } from 'react';
import UiContext from './UiContext';

export const useRank = ({ subject, subjectKey }) => {
  const { toastError } = useContext(UiContext);
  const [timer, setTimer] = useState();
  const rankEvidence = useRankEvidence();
  const rankTheory = useRankTheory();
  const [currentRank, setCurrentRank] = useState(); // cache updated vote here (overriding subject.vote)

  const mutator = subjectKey === 'evidence' ? rankEvidence : rankTheory;

  const getRank = useCallback(() => {
    return currentRank !== undefined
      ? currentRank
      : subject?.userRank !== undefined
      ? +subject?.userRank
      : undefined;
  }, [currentRank, subject]);

  const rank = useMemo(() => getRank(), [getRank]);

  const onRank = useCallback(
    delta => {
      const oldRank = getRank() || 0;
      if ((oldRank === 2 && delta === 1) || (oldRank === -2 && delta === -1)) return;

      const newRank = oldRank + delta;
      setCurrentRank(newRank);

      window.clearTimeout(timer);
      setTimer(
        window.setTimeout(() => {
          setTimer(undefined);
          mutator.mutate(
            { [subjectKey]: subject, rank: newRank },
            {
              onSuccess: () => setCurrentRank(undefined),
              onError: res => {
                toastError(res.message);
                setCurrentRank(undefined);
              },
            },
          );
        }, 1000),
      );

      return () => window.clearTimeout(timer);
    },
    [getRank, mutator, subject, subjectKey, timer, toastError],
  );

  const onClickUp = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onRank(1);
    },
    [onRank],
  );

  const onClickDown = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      onRank(-1);
    },
    [onRank],
  );

  return [rank, onClickUp, onClickDown];
};
