import Item from './Item';
import classnames from 'classnames';
import { Tooltip } from '@mui/material';
import { useContext } from 'react';
import AuthContext from '../../state/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getRoleName, getTeamName } from '../../util/concurrency';
import { sameId } from '../../util/id';

const Occupation = ({ user, className, noTooltip }) => {
  const { me } = useContext(AuthContext);
  const navigate = useNavigate();
  const isMe = sameId(me, user);
  const destination = isMe ? '/quiz' : '/alignment/' + user?.username;

  const onClick = () => {
    if (destination) navigate(destination);
  };

  if (!user) return null;

  const team = getTeamName(user);

  const role = getRoleName(user);

  const titles = {
    0: 'Patron',
    2: 'Intern',
    4: 'Page',
    6: 'Clerk',
    8: 'Aide',
    10: 'Technician',
    12: 'Researcher',
    14: 'Specialist',
    18: 'Archivist',
    16: 'Coordinator',
    20: 'Librarian',
  };

  const roman = n =>
    ({
      0: '',
      1: 'I',
      2: 'II',
      3: 'III',
      4: 'IV',
      5: 'V',
      6: 'VI',
      7: 'VII',
      8: 'VIII',
      9: 'IX',
      10: 'X',
      11: 'XI',
      12: 'XII',
      13: 'XIII',
      14: 'XIV',
      15: 'XV',
      16: 'XVI',
      17: 'XVII',
      18: 'XVIII',
      19: 'XIX',
      20: 'XX',
    }[n]);

  const title = team ? `${team} ${role}` : titles[Math.floor(user.trustLevel / 2) * 2];

  return (
    <Tooltip title={noTooltip ? '' : 'Level ' + user.trustLevel} placement='right'>
      <div
        className={classnames(className, 'font-thin', { 'cursor-pointer': !!destination })}
        onClick={onClick}
      >
        <div className={'flex flex-row space-x-2 items-center'}>
          <p>
            {title} <span className='font-serif'>{roman(user.trustLevel)}</span>
          </p>
          <Item user={user} />
        </div>
      </div>
    </Tooltip>
  );
};

export default Occupation;
