import { useContext } from 'react';
import UiContext from '../../state/UiContext';
import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import classnames from 'classnames';

export default function Heading({ text, tooltip, backgroundImg }) {
  const { smPlus } = useContext(UiContext);

  return (
    <div
      className={classnames(
        smPlus ? 'h-[250px]' : 'h-32',
        'w-full flex bg-cover bg-center justify-center items-center space-x-4',
      )}
      style={{ backgroundImage: `url(${backgroundImg})` }}
    >
      <p className='font-semibold text-4xl text-white'>{text}</p>
      {tooltip && (
        <Tooltip title={tooltip}>
          <HelpOutlineIcon color={'grey'} />
        </Tooltip>
      )}
    </div>
  );
}
