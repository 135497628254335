import { useQuery } from 'react-query';
import { fetchClient } from 'services/api';
import { staleTimes } from './query-settings';

const apiClient = fetchClient();

export const tagSubscriptionQueryKeys = {
  all: ['tag-subscriptions'],
  one: slug => ['tag-subscriptions', { slug: slug }],
};

const getTagSubscriptions = async () => {
  const res = await apiClient.get('tag-subscription');
  return res.data;
};

export const useTagSubscriptions = ({ enabled }) =>
  useQuery(tagSubscriptionQueryKeys.all, () => getTagSubscriptions(), {
    staleTime: staleTimes.blip,
    enabled,
  });

export const subscribeTag = async id => {
  const res = await apiClient.post(`tag-subscription`, {
    tag: id,
  });

  return res.data;
};

export const unsubscribeTag = async id => {
  const res = await apiClient.delete(`tag-subscription/${id}`);

  return res.data;
};

const getTagSubscription = async tagIdOrSlug => {
  return apiClient.get(`/tag-subscription/${tagIdOrSlug}`).then(res => res.data);
};

export const useTagSubscription = ({ tagIdOrSlug, enabled }) =>
  useQuery(tagSubscriptionQueryKeys.one(tagIdOrSlug), () => getTagSubscription(tagIdOrSlug), {
    staleTime: staleTimes.blip,
    enabled,
  });
