import { useTheories } from '../services/theories';
import Heading from '../components/Banners/Heading';
import TheoryBackground from 'assets/images/background2.png';
import TheoryEditor from '../components/Theory/TheoryEditor';
import HeadTags from '../components/HeadTags';
import { useState } from 'react';
import TheoryGrid from '../components/Cards/TheoryGrid';
import NewInquiryButton from '../components/NewInquiryButton';
import { useMe } from '../services/users';
import { flattenPages } from '../util/query';
import { useInfiniteScroll } from '../util/dom';

function CreateTheory() {
  const meQuery = useMe();
  const me = meQuery.data?.data;
  const myTheoriesQuery = useTheories({ user: me?.id }, { enabled: !!me?.id });
  const myTheories = flattenPages(myTheoriesQuery.data?.pages);
  const [creating, setCreating] = useState(true);

  useInfiniteScroll(myTheoriesQuery);

  return (
    <div>
      <HeadTags title='Create Inquiry' />
      <Heading
        text={'Create Inquiry'}
        tooltip='Ask any empirical yes/no question.  It will become an Inquiry.'
        backgroundImg={TheoryBackground}
      />

      <div>
        {creating && <TheoryEditor onClose={() => setCreating(false)} />}

        {myTheories?.length > 0 && (
          <div className='mx-1 md:mx-2 lg:mx-4'>
            <div className='flex justify-between mt-5'>
              <p className='text-regular text-xl md:text-4xl font-semibold'>Your Inquiries</p>
              {!creating && <NewInquiryButton onClick={() => setCreating(true)} />}
            </div>
            <TheoryGrid
              className='mt-4 md:mt-10'
              theories={myTheories}
              isFetching={myTheoriesQuery.isFetching}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CreateTheory;
