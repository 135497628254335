import _ from 'lodash';
import SlideDownModal from '../wrappers/SlideDownModal';
import Stack from '@mui/material/Stack';
import { useContext, useEffect, useState } from 'react';
import ValidationError from '../ValidationError';
import { validateUsername } from '../../services/validators';
import { useAddAuthor } from '../../services/evidence';
import UiContext from '../../state/UiContext';
import Username from '../elements/Username';
import validator from 'validator';
import classnames from 'classnames';
import id from 'util/id';

const AddAuthorModal = ({ open, evidence, onClose }) => {
  const { toastSuccess, toastError } = useContext(UiContext);
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [displayName, setDisplayName] = useState('');

  const [validationError, setValidationError] = useState('');
  const [displayedValidationError, setDisplayedValidationError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const addAuthor = useAddAuthor();

  function onSubmit() {
    if (validationError) return toastError(validationError);

    if (validator.isEmail(usernameOrEmail) && !displayName) return toastError('Name required');

    if (addAuthor.isLoading) return;
    setDisabled(true);

    addAuthor.mutate(
      { evidence, author: usernameOrEmail, displayName },
      {
        onSuccess: res => {
          toastSuccess(res.message);
          setUsernameOrEmail('');
          setDisplayName('');
          setDisabled(false);
        },
        onError: res => {
          toastError(res.message);
          setDisabled(false);
        },
      },
    );
  }

  useEffect(() => {
    const usernameError = usernameOrEmail && validateUsername(_.trimStart(usernameOrEmail, '@'));
    const validationError =
      (usernameError && !validator.isEmail(usernameOrEmail) && 'Not a valid email or username') ||
      (validator.isEmail(usernameOrEmail) &&
        validator.isEmail(displayName) &&
        'Name must not be an email') ||
      '';
    setValidationError(validationError);

    const t = setTimeout(
      () => setDisplayedValidationError(validationError),
      validationError ? 2000 : 0,
    );
    return () => clearTimeout(t);
  }, [usernameOrEmail, displayName]);

  function onChange(e) {
    const name = e.target.value;

    setUsernameOrEmail(name);
  }

  return (
    <SlideDownModal open={open} title={'Add authors to this Evidence'} onClose={onClose}>
      <div className='p-2 mx-2 flex flex-col space-y-3'>
        <div className='grid grid-cols-2 gap-7'>
          <p className='text-base'>
            Please use this form to add the authors of the linked source material. They will get an
            email notifying them of this post and have the option to claim it as theirs.
          </p>

          <div className='text-sm'>
            <p className='font-bold'>Authors:</p>
            {evidence.authors?.map(user => (
              <div className={'my-1 ml-1'} key={id(user)}>
                <p>
                  {(user?.username && <Username username={user.username} />) || user?.displayName}
                </p>
              </div>
            ))}
          </div>
        </div>

        <input
          placeholder='Username or Email'
          className='w-full p-2 text-lg border rounded'
          value={usernameOrEmail}
          onKeyUp={e => e.key === 'Enter' && onSubmit()}
          onChange={onChange}
          autoFocus={true}
        />

        {validator.isEmail(usernameOrEmail) && (
          <input
            placeholder='Name'
            className='w-full p-2 text-lg border rounded'
            value={displayName}
            onKeyUp={e => e.key === 'Enter' && onSubmit()}
            onChange={e => setDisplayName(e.target.value)}
          />
        )}

        <ValidationError error={displayedValidationError} />
      </div>

      <Stack direction='row' m={2} justifyContent='center' px={10}>
        <button
          className={classnames('bg-primary rounded border p-2 text-white', {
            'opacity-30': disabled,
          })}
          onClick={onSubmit}
          disabled={disabled}
        >
          Submit
        </button>
      </Stack>
    </SlideDownModal>
  );
};

export default AddAuthorModal;
