import { Helmet } from 'react-helmet';
import React from 'react';

const HeadTags = ({ title, description, image, children }) => {
  const fullTitle =
    title && (title.toLowerCase().includes('evincer') ? title : title + ' | Evincer');
  return (
    <>
      {title && (
        <Helmet>
          <title>{fullTitle}</title>
          <meta property='og:title' content={fullTitle} />
        </Helmet>
      )}

      {description && (
        <Helmet>
          <meta name='description' property='og:description' content={description} />
        </Helmet>
      )}

      {image && (
        <Helmet>
          <meta name='image' property='og:image' content={image} />
        </Helmet>
      )}

      {children && <Helmet>{children}</Helmet>}
    </>
  );
};

export default HeadTags;
