import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

const SectionTitle = ({ title, desc }) => {
  const theme = useTheme();

  return (
    <Box>
      <p
        style={{
          fontSize: 32,
          fontWeight: 600,
          color: theme.palette.titleText.main,
          marginBottom: 10,
          fontFamily: theme.typography.fontFamily,
        }}
      >
        {title}
      </p>
      <Box
        style={{
          height: 1,
          width: 70,
          backgroundColor: theme.palette.text.unSelected,
          marginBottom: 10,
        }}
      ></Box>
      <p
        style={{
          fontSize: 15,
          fontWeight: 400,
          color: theme.palette.text.unSelected,
          marginBottom: 30,
          fontFamily: theme.typography.fontFamily,
        }}
      >
        {desc}
      </p>
    </Box>
  );
};

export default SectionTitle;
