import { Link as RouterLink } from 'react-router-dom';
import { track } from '../../services/analytics';

const Tag = ({ tag }) => (
  <RouterLink
    to={'/inquiries/' + tag.slug}
    onClick={() => track('click-tag', { slug: tag.slug })}
    className='text-2xs lg:text-xs font-semibold text-default'
    component={RouterLink}
  >
    <div style={{ background: '#F8FCFF', borderRadius: '20px', padding: '5px 8px' }}>
      #{tag.title}
    </div>
  </RouterLink>
);
export default Tag;
